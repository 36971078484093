import React from "react";
import { useState } from "react";
import {
  Flex,
  Box,
  Heading,
  Text,
  Center,
  Skeleton,
  Image,
} from "@chakra-ui/react";
import Header from "../../../components/Header";
import { useEffect } from "react";
import Axios from "../../../utils/axiosService";
import moment from "moment/moment";
import ContactUsCard from "../../../components/ContactUs/ContactUsCard";
import EmptySchedule from "../../../assets/img/empty-room-schedule.svg";

function ContactUsView() {
  moment.locale();

  const [contactUsData, setContactUsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [text, setText] = useState("");

  useEffect(() => {
    setIsLoading(true);
    Axios({
      method: "GET",
      url: "/contact-us",
    })
      .then((r) => {
        let tempContactData = r.data.data.data;
        setContactUsData(tempContactData);
        if (tempContactData.length === 0) {
          setText("No contacts data available");
        }
      })
      .catch((err) => {
        setText(err.response.data.errors[0].message);
      });
    setIsLoading(false);
  }, []);

  return (
    <>
      <Header title="Contact Us" backLink="/profile" />
      <Flex padding="24px" pb="90px" flexDirection="column" gap="10px">
        <Box width="100%">
          <Heading fontSize="20px" fontFamily="Lato" fontWeight="600">
            Butuh bantuan?
          </Heading>
          <Text fontSize="14px" pb="18px" fontFamily="Lato" fontWeight="400">
            Silakan hubungi kami dengan klik salah satu kontak berikut
          </Text>
          {isLoading ? (
            <>
              <Skeleton
                height="85px"
                padding="8px 8px"
                borderRadius="16px"
                color="rgba(0, 0, 0, 0.7)"
              />
              <Skeleton
                height="85px"
                mt="1em"
                padding="8px 8px"
                borderRadius="16px"
                color="rgba(0, 0, 0, 0.7)"
              />
              <Skeleton
                height="85px"
                mt="1em"
                padding="8px 8px"
                borderRadius="16px"
                color="rgba(0, 0, 0, 0.7)"
              />
            </>
          ) : contactUsData.length > 0 ? (
            contactUsData?.map((contactData) => {
              return <ContactUsCard data={contactData} />;
            })
          ) : (
            <>
              <Center mt="2em">
                <Image src={EmptySchedule} height={"250px"} />
              </Center>
              <Center>
                <Text>{text}</Text>
              </Center>
            </>
          )}
        </Box>
      </Flex>
    </>
  );
}

export default ContactUsView;
