import { Text, Flex } from "@chakra-ui/react";
import { MdPerson } from "react-icons/md";
import LeavingReturningTime from "../../../../Component/LeavingReturningTime";

const UserCard = ({ name, start_usage, finish_usage }) => {
  const convertDateTime = (isoString) => {
    const date = new Date(isoString);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    const wibDate = new Date(date.getTime() + 7 * 60 * 60 * 1000);
    const hours = String(wibDate.getUTCHours()).padStart(2, "0");
    const minutes = String(wibDate.getUTCMinutes()).padStart(2, "0");
    const formattedTime = `${hours}.${minutes}`;

    return { formattedDate, formattedTime };
  };

  const { formattedDate: startDate, formattedTime: startTime } =
    convertDateTime(start_usage);
  const { formattedDate: finishDate, formattedTime: finishTime } =
    convertDateTime(finish_usage);

  return (
    <Flex
      direction="column"
      padding="8px"
      spacing="0"
      bg="#FFFFFF"
      style={{
        boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
        borderRadius: "12px",
      }}
      rowGap="12px"
    >
      <Flex
        direction="row"
        borderBottom="2px dashed #EFEFEF"
        paddingBottom="8px"
        alignItems="center"
        columnGap="4px"
        color="#222222"
      >
        <MdPerson size="16px" color="#5E5E5E" />
        <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
          {name}
        </Text>
      </Flex>
      <LeavingReturningTime
        leavingDate={startDate}
        leavingTime={startTime}
        returningDate={finishDate}
        returningTime={finishTime}
      />
    </Flex>
  );
};

export default UserCard;
