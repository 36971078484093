import React from "react";
import { useState } from "react";
import { Flex, Text, Center, Skeleton, Accordion, Image } from "@chakra-ui/react";
import Header from "../../../../components/Header";
import { useEffect } from "react";
import Axios from "../../../../utils/axiosService";
import moment from "moment/moment";
import AppFilter from "../../../../components/history/AppFilter";
import TermsConditionsCard from "../../../../components/TermsAndConditions/TermsConditionsCard";
import EmptySchedule from '../../../../assets/img/empty-room-schedule.svg';

function TermsAndConditionsView() {
  moment.locale();

  const [selectedAppId, setSelectedAppId] = useState();
  const [termsConditionsData, setTermsConditionsData] = useState([1, 2, 3]);
  const [isLoading, setIsLoading] = useState(true);
  const [apps, setApps] = useState([]);
  const [text, setText] = useState('');

  useEffect(() => {
    Axios({
      method: "GET",
      url: "/app?status=true",
    }).then((r) => {
      setApps(r.data.data.data);
      setSelectedAppId(r.data.data.data[0].id);
    });
  }, []);

  useEffect(() => {
    setTermsConditionsData([])
    if (selectedAppId !== undefined) {
      Axios({
        method: "GET",
        url: `/terms-and-conditions?app_id=${selectedAppId}`,
      }).then((r) => {
        let tempTnCData = r.data.data.data;
        setTermsConditionsData(tempTnCData);
        if (!tempTnCData) {
          setText("No Terms and Conditions Data available")
        }
      }).catch((err) => {
        setText(err.response.data.errors[0].message)
      });
      setIsLoading(false)
    }
  }, [selectedAppId]);

  return (
    <>
      <Header title={"Terms and Conditions"} backLink="/profile" />
      <Flex p="24px" flexDirection="column" gap="10px">
        <AppFilter
          selectedAppId={selectedAppId}
          setSelectedAppId={setSelectedAppId}
          apps={apps}
        />
        {isLoading ? 
          (
            <>
              <Skeleton
                height='85px'
                mt="0.5em"
                padding="8px 8px"
                borderRadius="16px"
                color="rgba(0, 0, 0, 0.7)"
              />
              <Skeleton
                height='85px'
                mt="0.5em"
                padding="8px 8px"
                borderRadius="16px"
                color="rgba(0, 0, 0, 0.7)"
              />
              <Skeleton
                height='85px'
                mt="0.5em"
                padding="8px 8px"
                borderRadius="16px"
                color="rgba(0, 0, 0, 0.7)"
              />
            </>
          )
        :
          termsConditionsData.length === 0 || apps.length === 0 ? (
            <>
              <Center mt="2em">
                <Image src={EmptySchedule} height={"250px"} />
              </Center>
              <Center>
                <Text>{text}</Text>
              </Center>
            </>
          )
          :
          (
            <Accordion allowMultiple>
              {termsConditionsData?.map((r) => {
                return (
                  <TermsConditionsCard data={r} />
                );
              })}    
            </Accordion>
          )
        }
      </Flex>
    </>
  );
}

export default TermsAndConditionsView;
