import styles from "./style.module.css";

export const Line = () => {
  return (
    <svg
      width="80"
      height="6"
      viewBox="0 0 80 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles["animation-line"]}
        d="M1 4.48486C30.9593 -0.21515 47.4616 -0.107738 79 4.48486"
        stroke="#EB8624"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
