import React from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faGlobe,
  faHome,
  faClock,
  faMoon,
  faStickyNote,
  faHotel,
  faCalendar,
  faUsers,
  faFileAlt,
  faAngleRight,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import AxiosParastay from "../../../../../../utils/parastayAxiosService";
import { Flex, Box, Spacer, Text, Button } from "@chakra-ui/react";
import styles from "./styles.module.css";
import MainHeader from "../../../../../../components/Parastay/Header/MainHeader";

const Modal = ({ children, closeModal, modalState, title, HandleSubmit }) => {
  if (!modalState) {
    return null;
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-card" style={{ width: "90%" }}>
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">
            <strong>{title}</strong>
          </p>
          <button className="delete" onClick={closeModal} />
        </header>
        <section className="modal-card-body">
          <div className="content">{children}</div>
        </section>
        <footer className="modal-card-foot">
          <a href={() => false} className="button" onClick={HandleSubmit}>
            Submit
          </a>
          <a href={() => false} className="button" onClick={closeModal}>
            Cancel
          </a>
        </footer>
      </div>
    </div>
  );
};

class FormMovinOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      occupant_type: null,
      bring_family: null,
      request_date: null,
      checkout_date: null,
      checkout_type: null,
      existing_mess_data: JSON.parse(localStorage.getItem("mess_data")),
      reason: null,
      user_data: {
        data: [
          {
            user_id: null,
            user_gender: null,
            work_location_id_fk: null,
            user_phone: "",
          },
        ],
      },
      user_mess_data: {
        data: [
          {
            data_id: null,
            room_id_fk: null,
            user_id_fk: null,
            occupant_type: null,
            bring_family: null,
            request_date: null,
            start_date: null,
            end_date: null,
            notes: null,
            booking_status_id_fk: null,
            process_date: null,
            pic_id: null,
            is_read: null,
            updated_date: null,
            location_id_fk1: null,
            staying_type: null,
            work_location_id_fk2: null,
            id_work_area: null,
          },
        ],
      },
      ThreeMonthsLater: null,
      Tomorrow: null,
      Today: null,
      work_location_id: null,
    };
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const idUser = localStorage.getItem("id_user_parastay");

    AxiosParastay({
      method: "GET",
      url: `/UserDataforBooking?user_id=${idUser}`,
    })
    .then((result) => {
      this.setState({
        user_data: result.data,
      });
    });

    AxiosParastay({
      method: "GET",
      url: `/UserDataforCheckout?user_id=${idUser}`,
    })
    .then((result) => {
      this.setState({
        user_mess_data: result.data,
      });
    });

    const today = new Date().toISOString();
    var TempToday = new Date(today).toISOString().split("T")[0];

    const TempThreeMonthsLater = new Date(today);
    TempThreeMonthsLater.setDate(TempThreeMonthsLater.getDate() + 90);

    const TempTomorrow = new Date(today);
    TempTomorrow.setDate(TempTomorrow.getDate() + 1);

    const ThreeMonthsLater = new Date(TempThreeMonthsLater)
      .toISOString()
      .split("T")[0];
    const Tomorrow = new Date(TempTomorrow).toISOString().split("T")[0];

    this.setState({
      ThreeMonthsLater: ThreeMonthsLater,
      Tomorrow: Tomorrow,
      Today: TempToday,
    });
  }

  validateMoveout = (data) => {
    var isUserValid = data.user_id_fk !== null;
    var isOccTypeValid = data.occupant_type !== null;
    var isStayTypeValid = data.staying_type !== null;
    var isBringFamValid = data.bring_family !== null;
    var IsReqDateValid = data.request_date !== null;
    var isStartDateValid = data.start_date !== null;
    var isBookStatusValid = data.booking_status_id_fk !== null;
    var isNotesValid = data.notes !== null;
    var isSenderValid = data.sender_id !== null;
    var isSendDateValid = data.send_datetime !== null;
    var isBookLogValid = data.booking_log !== null;
    var isBookStatus2Valid = data.booking_status_id !== null;
    var isWorkLocValid = data.work_location_id_fk2 !== null;
    var isWorkLocIDValid = data.id_work_area !== null;

    return (
      isUserValid &&
      isOccTypeValid &&
      isStayTypeValid &&
      isBringFamValid &&
      IsReqDateValid &&
      isStartDateValid &&
      isBookStatusValid &&
      isNotesValid &&
      isSenderValid &&
      isSendDateValid &&
      isBookLogValid &&
      isBookStatus2Valid &&
      isWorkLocValid &&
      isWorkLocIDValid
    );
  };

  validateCheckOut = (data) => {
    var isUserValid = data.user_id_fk !== null;
    var isUpdatedDateValid = data.updated_date !== null;
    var isEndDateValid = data.end_date !== null;
    var isBookingIDValid = data.data_id !== null;
    var isBookStatusValid = data.booking_status_id_fk !== null;
    var isReasonValid = data.checkout_reason !== null;

    return (
      isUserValid &&
      isUpdatedDateValid &&
      isEndDateValid &&
      isBookingIDValid &&
      isReasonValid &&
      isBookStatusValid
    );
  };

  handleSubmit() {
    Swal.fire({
      title: "Creating Request...",
      html: "Please wait.",
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    Swal.showLoading();

    var currentDate = new Date();
    var endDate = null;
    const id_user = localStorage.getItem("id_user_parastay");

    const bookingData = this.state.user_mess_data.data
    const lastBookingData = bookingData.filter((booking) => booking.booking_status_id_fk === 4 || booking.booking_status_id_fk === 6);
    
    const booking_id = 
      Array.isArray(lastBookingData) 
        ? lastBookingData[0]?.data_id 
        : (
            lastBookingData
              ? lastBookingData.data_id 
              : this.state.existing_mess_data.data_id
          );

    var checkoutDate = new Date(this.state.checkout_date);
    var startDate = new Date(this.state.start_date);

    if (this.state.end_date === null) {
      endDate = null;
    } else {
      endDate = new Date(this.state.end_date);
    }

    var CheckoutData = {};
    var CheckoutLogData = {};
    var MoveoutData = {};

    let WorkLocation = "";

    if (this.state.work_location_id === 1) {
      WorkLocation = "DC";
    } else if (this.state.work_location_id === 2) {
      WorkLocation = "HEAD OFFICE";
    } else if (this.state.work_location_id === 3) {
      WorkLocation = "PLANT";
    } else {
      WorkLocation = "";
    }

    if (this.state.checkout_type === "8") {
      MoveoutData = {
        user_id_fk: parseInt(id_user),
        occupant_type: parseInt(this.state.occupant_type),
        staying_type: parseInt(this.state.staying_type),
        bring_family: this.state.bring_family,
        request_date: currentDate,
        start_date: startDate,
        end_date: endDate,
        booking_status_id_fk: 1,
        notes: this.state.notes,
        sender_id: parseInt(id_user),
        send_datetime: currentDate,
        booking_log: "Booking Moveout Requested",
        booking_status_id: 1,
        work_location_id_fk2: WorkLocation,
        id_work_area: this.state.work_location_id,
      };

      CheckoutData = {
        user_id_fk: parseInt(id_user),
        end_date: startDate,
        updated_date: currentDate,
        data_id: booking_id,
      };

      if (this.validateMoveout(MoveoutData)) {
        AxiosParastay({
          method: "PUT",
          url: `/CreateMoveout`,
          data: CheckoutData
        })
        .catch(function (error) {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Server Error: Failed to check out",
            text: error,
            confirmButtonColor: "blue",
            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        });

        AxiosParastay({
          method: "POST",
          url: `/CreateBooking`,
          data: MoveoutData
        })
        .then(() => {
          Swal.close();
          Swal.fire({
            icon: "success",
            text: "Pengajuan Pindah dan Check out berhasil dibuat!",
            confirmButtonColor: "blue",
            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.assign("../parastay");
            }
          });
        })
        .catch(function (error) {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Server Error: Failed to create move out",
            text: error,
            confirmButtonColor: "blue",
            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        });
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Pastikan semua data sudah terisi",
          confirmButtonColor: "blue",
          confirmButtonText: `OK`,
        });
      }
    } else if (this.state.checkout_type === "7") {
      CheckoutData = {
        user_id_fk: parseInt(id_user),
        booking_status_id_fk: 7,
        end_date: checkoutDate,
        updated_date: currentDate,
        checkout_reason: this.state.reason,
        data_id: booking_id,
      };

      CheckoutLogData = {
        sender_id: parseInt(id_user),
        booking_id_fk1: booking_id,
        booking_status_id: 7,
        send_datetime: currentDate,
        booking_log: "Check out Request Created",
      };

      if (this.validateMoveout(CheckoutData)) {
        AxiosParastay({
          method: "PUT",
          url: `/CreateCheckout`,
          data: CheckoutData
        })
        .then(() => {
          AxiosParastay({
            method: "POST",
            url: `/CreateCheckout`,
            data: CheckoutLogData
          })
          .then(() => {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Pengajuan Check Out berhasil dibuat!",
              confirmButtonColor: "blue",
              confirmButtonText: `OK`,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.assign("./");
              }
            });
          })
          .catch(function (error) {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Server Error: Failed to check out",
              text: error,
              confirmButtonColor: "blue",
              confirmButtonText: `OK`,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          });
        })
        .catch(function (error) {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Server Error: Failed to check out",
            text: error,
            confirmButtonColor: "blue",
            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        });
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Pastikan semua data sudah terisi",
          confirmButtonColor: "blue",
          confirmButtonText: `OK`,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    }
  }

  toggleModal() {
    this.setState((prev, props) => {
      const newState = !prev.modalState;
      return { modalState: newState };
    });
  }

  ChangeHandler(event) {
    this.setState({
      [event.target.name]:
        event.target.type === "number"
          ? parseInt(event.target.value)
          : event.target.value,
    });
  }

  render() {
    let TempMinStartDate = null;
    let TempMaxStartDate = null;
    let MinStartDate = null;
    let MaxStartDate = null;

    if (this.state.start_date) {
      TempMinStartDate = new Date(this.state.start_date);
      TempMinStartDate.setDate(TempMinStartDate.getDate() + 1);

      TempMaxStartDate = new Date(this.state.start_date);
      TempMaxStartDate.setDate(TempMaxStartDate.getDate() + 91);

      MinStartDate = new Date(TempMinStartDate).toISOString().split("T")[0];
      MaxStartDate = new Date(TempMaxStartDate).toISOString().split("T")[0];
    }

    return (
      <>
        <div className="App">
          <Flex className={styles["header-container"]}>
            <MainHeader title="Request Check Out" backLink={`/parastay?id=1`} />
          </Flex>
          <Flex className={styles["main-container"]}>
            <Flex className={styles["subheader-container"]}>
              <Text fontSize="24px" fontWeight="700" mb="0.5em">
                Permohonan Pindah Mess
              </Text>
              <a
                class="button is-gomess-babyblue is-fullwidth"
                href="./terms-conditions"
                onClick={this.handleClick}
              >
                <Box color="#30C1FF">
                  <FontAwesomeIcon icon={faFileAlt} size="md" />
                </Box>
                <Spacer />
                <Box>
                  <Text fontSize="14px" fontWeight="600">
                    Syarat dan Ketentuan Pindah
                  </Text>
                </Box>
                <Spacer />
                <Box color="#30C1FF">
                  <FontAwesomeIcon icon={faAngleRight} size="md" />
                </Box>
              </a>
              <Text fontSize="16px" m="1em 0">
                Isian yang ditandai dengan (<b>*</b>) wajib diisi
              </Text>
              <form onSubmit={this.handleSubmit}>
                <h2 class="title has-text-left is-size-4">Detail Checkout</h2>
                <div class="control mb-5">
                  <label for="" class="label">
                    Jenis Check Out*
                  </label>
                  <label class="radio">
                    <input
                      class="mr-2"
                      type="radio"
                      name="checkout_type"
                      value="8"
                      onChange={this.ChangeHandler}
                    />
                    Pindah Mess
                  </label>
                  <label class="radio">
                    <input
                      class="mr-2"
                      type="radio"
                      name="checkout_type"
                      value="7"
                      onChange={this.ChangeHandler}
                    />
                    Keluar Mess
                  </label>
                </div>
                <h2 class="title has-text-left is-size-4">Mess Asal</h2>
                <div class="field mb-5">
                  <label for="" class="label">
                    Nama Mess
                  </label>
                  <input
                    type="text"
                    id="previous_mess_name"
                    name="previous_mess_name"
                    value={this.state.existing_mess_data.location_name}
                    disabled
                  />
                </div>
                <div class="field mb-5">
                  <label for="" class="label">
                    Kamar
                  </label>
                  <input
                    type="text"
                    id="previous_mess_room"
                    name="previous_mess_room"
                    value={this.state.existing_mess_data.room_name}
                    disabled
                  />
                </div>
                {this.state.checkout_type === "7" ? (
                  <>
                    <div class="field mb-5">
                      <label for="" class="label">
                        Tanggal Check-out*
                      </label>
                      <div class="control has-icons-left">
                        <input
                          class="input date"
                          type="date"
                          id="checkout_date"
                          name="checkout_date"
                          min={this.state.Today}
                          value={this.state.checkout_date}
                          onChange={this.ChangeHandler}
                          required
                        />
                        <span class="icon is-left has-text-primary">
                          <FontAwesomeIcon icon={faCalendar} size="sm" />
                        </span>
                      </div>
                    </div>
                    <div class="field mb-5">
                      <label for="" class="label">
                        Alasan Check Out*
                      </label>
                      <textarea
                        id="reason"
                        name="reason"
                        value={this.state.reason}
                        class="textarea"
                        placeholder="Masukkan alasan Check Out"
                        rows="3"
                        onChange={this.ChangeHandler}
                      ></textarea>
                    </div>
                  </>
                ) : null}
                <p />
                {this.state.checkout_type === "8" ? (
                  <>
                    <h2 class="title has-text-left is-size-4">Mess Tujuan</h2>
                    <div class="field mb-5">
                      <label for="" class="label">
                        Lokasi Kerja*
                      </label>
                      <div class="control is-expanded has-icons-left">
                        <div class="select is-fullwidth">
                          <select
                            id="work_location_id"
                            name="work_location_id"
                            value={this.state.work_location_id}
                            onChange={this.ChangeHandler}
                          >
                            <option value={null} selected>
                              (Pilih Lokasi Kerja)
                            </option>
                            <option value={2}>Head Office</option>
                            <option value={3}>Plant</option>
                            <option value={1}>DC</option>
                          </select>
                        </div>
                        <div class="icon is-small is-left has-text-primary">
                          <FontAwesomeIcon icon={faGlobe} size="sm" />
                        </div>
                      </div>
                    </div>
                    <div class="field mb-5">
                      <label for="" class="label">
                        Bersama Keluarga/Pasangan?*
                      </label>
                      <div class="control is-expanded has-icons-left">
                        <div class="select is-fullwidth">
                          <select
                            id="bring_family"
                            name="bring_family"
                            value={this.state.bring_family}
                            onChange={this.ChangeHandler}
                          >
                            <option value={null} selected>
                              (Ya/Tidak?)
                            </option>
                            <option value={true}>Iya</option>
                            <option value={false}>Tidak</option>
                          </select>
                        </div>
                        <div class="icon is-small is-left has-text-primary">
                          <FontAwesomeIcon icon={faUsers} size="sm" />
                        </div>
                      </div>
                    </div>
                    <div class="field mb-5">
                      <label for="" class="label">
                        Kategori Mess*
                      </label>

                      {this.state.bring_family === "true" ? (
                        <div class="control is-expanded has-icons-left">
                          <div class="select is-fullwidth">
                            <select
                              id="occupant_type"
                              name="occupant_type"
                              value={this.state.occupant_type}
                              onChange={this.ChangeHandler}
                            >
                              <option value={null} selected>
                                (Pilih Kategori Mess)
                              </option>
                              <option value="2">Mess Couple</option>
                            </select>
                          </div>
                          <div class="icon is-small is-left has-text-primary">
                            <FontAwesomeIcon icon={faHotel} size="sm" />
                          </div>
                        </div>
                      ) : (
                        <div class="control is-expanded has-icons-left">
                          <div class="select is-fullwidth">
                            <select
                              id="occupant_type"
                              name="occupant_type"
                              value={this.state.occupant_type}
                              onChange={this.ChangeHandler}
                            >
                              <option value={null} selected>
                                (Pilih Kategori Mess)
                              </option>
                              <option value="1">Mess Single</option>
                            </select>
                          </div>
                          <div class="icon is-small is-left has-text-primary">
                            <FontAwesomeIcon icon={faHotel} size="sm" />
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="field mb-5">
                      <label for="" class="label">
                        Jangka Waktu*
                      </label>
                      {this.state.bring_family === "true" ? (
                        <div class="control is-expanded has-icons-left">
                          <div class="select is-fullwidth">
                            <select
                              id="staying_type"
                              name="staying_type"
                              value={this.state.staying_type}
                              onChange={this.ChangeHandler}
                            >
                              <option value={null} selected>
                                (Select)
                              </option>
                              <option value="2">Settle</option>
                            </select>
                          </div>
                          <div class="icon is-small is-left has-text-primary">
                            <FontAwesomeIcon icon={faUsers} size="sm" />
                          </div>
                        </div>
                      ) : (
                        <div class="control is-expanded has-icons-left">
                          <div class="select is-fullwidth">
                            <select
                              id="staying_type"
                              name="staying_type"
                              value={this.state.staying_type}
                              onChange={this.ChangeHandler}
                            >
                              <option value={null} selected>
                                (Select)
                              </option>
                              <option value="1">Visit</option>
                              <option value="2">Settle</option>
                            </select>
                          </div>
                          <div class="icon is-small is-left has-text-primary">
                            <FontAwesomeIcon icon={faUsers} size="sm" />
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="field mb-5">
                      <label for="" class="label">
                        Check-in*
                      </label>
                      <div class="control has-icons-left">
                        <input
                          class="input date"
                          type="date"
                          min={this.state.Today}
                          max={this.state.ThreeMonthsLater}
                          id="start_date"
                          name="start_date"
                          value={this.state.start_date}
                          onChange={this.ChangeHandler}
                          required
                        />
                        <span class="icon is-left has-text-primary">
                          <FontAwesomeIcon icon={faCalendar} size="sm" />
                        </span>
                      </div>
                    </div>
                    {this.state.staying_type === "1" ||
                    this.state.staying_type === null ? (
                      this.state.start_date ? (
                        <div class="field mb-5">
                          <label for="" class="label">
                            Check-out*
                          </label>
                          <div class="control has-icons-left">
                            <input
                              class="input date"
                              min={MinStartDate}
                              max={MaxStartDate}
                              type="date"
                              id="end_date"
                              name="end_date"
                              value={this.state.end_date}
                              onChange={this.ChangeHandler}
                              required
                            />
                            <span class="icon is-left has-text-primary">
                              <FontAwesomeIcon icon={faCalendar} size="sm" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <fieldset disabled>
                          <div class="field mb-5">
                            <label for="" class="label">
                              Check-out*
                            </label>
                            <div class="control has-icons-left">
                              <input
                                class="input date"
                                min={MinStartDate}
                                max={MaxStartDate}
                                type="date"
                                id="end_date"
                                name="end_date"
                                value={this.state.end_date}
                                onChange={this.ChangeHandler}
                                required
                              />
                              <span class="icon is-left has-text-primary">
                                <FontAwesomeIcon icon={faCalendar} size="sm" />
                              </span>
                            </div>
                          </div>
                        </fieldset>
                      )
                    ) : null}
                    <div class="field mb-5">
                      <label for="" class="label">
                        Catatan Tambahan*
                      </label>
                      <textarea
                        id="notes"
                        name="notes"
                        value={this.state.notes}
                        class="textarea"
                        placeholder="Catatan Tambahan"
                        rows="3"
                        onChange={this.ChangeHandler}
                      ></textarea>
                    </div>
                  </>
                ) : null}
              </form>
              {this.state.checkout_type && (
                <Button
                  href={() => false}
                  onClick={this.toggleModal}
                  width="100%"
                  bgColor="#30C1FF"
                >
                  <Text fontSize="20px" fontWeight="600" color="#fff">
                    Konfirmasi
                  </Text>
                </Button>
              )}
              <Modal
                modalState={this.state.modalState}
                closeModal={this.toggleModal}
                HandleSubmit={this.handleSubmit}
                title={
                  this.state.checkout_type === "8"
                    ? "Konfirmasi Pindah Mess"
                    : "Konfirmasi Keluar Mess"
                }
              >
                <p class="has-text-centered is-spaced">
                  <strong>
                    Apa anda yakin data yang dimasukkan sudah sesuai?
                  </strong>
                </p>
                <p />
                <div class="media">
                  <div class="media-left">
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faPhone} size="lg" />
                    </div>
                  </div>
                  <div class="media-content">
                    <h2 class="is-size-6">Nomor telfon</h2>
                    <p class="is-size-6">
                      {this.state.user_data.data[0].user_phone}
                    </p>
                  </div>
                </div>
                <p />
                <div class="media">
                  <div class="media-left">
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faLocationArrow} size="lg" />
                    </div>
                  </div>
                  <div class="media-content">
                    <h2 class="is-size-6">Lokasi kerja</h2>
                    <p class="is-size-6">
                      {this.state.user_data.data[0].work_location_id_fk}
                    </p>
                  </div>
                </div>
                <p />
                <div class="media">
                  <div class="media-left">
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faCalendar} size="lg" />
                    </div>
                  </div>
                  <div class="media-content">
                    <h2 class="is-size-6">Tanggal Check-out Mess</h2>
                    <p class="is-size-6">{this.state.checkout_date}</p>
                  </div>
                </div>
                <p />
                <div class="media">
                  <div class="media-left">
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faStickyNote} size="lg" />
                    </div>
                  </div>
                  <div class="media-content">
                    <h2 class="is-size-6">Alasan Check Out</h2>
                    <p class="is-size-6">{this.state.reason}</p>
                  </div>
                </div>
                {this.state.checkout_type === "8" ? (
                  <>
                    <p />
                    <div class="media">
                      <div class="media-left">
                        <div class="icon is-small is-left has-text-primary">
                          <FontAwesomeIcon icon={faLocationArrow} size="lg" />
                        </div>
                      </div>
                      <div class="media-content">
                        <h2 class="is-size-6">Lokasi kerja</h2>
                        {this.state.work_location_id === 1 ? (
                          <p class="is-size-6">Distribution Center</p>
                        ) : this.state.work_location_id === 2 ? (
                          <p class="is-size-6">Head Office</p>
                        ) : this.state.work_location_id === 3 ? (
                          <p class="is-size-6">Plant</p>
                        ) : null}
                      </div>
                    </div>
                    <p />
                    <div class="media">
                      <div class="media-left">
                        <div class="icon is-small is-left has-text-primary">
                          <FontAwesomeIcon icon={faHome} size="lg" />
                        </div>
                      </div>
                      <div class="media-content">
                        <h2 class="is-size-6">Kategori mess</h2>
                        {this.state.occupant_type === "1" ? (
                          <p class="is-size-6">Single</p>
                        ) : this.state.occupant_type === "2" ? (
                          <p class="is-size-6">Couple</p>
                        ) : null}
                      </div>
                    </div>
                    <p />
                    <div class="media">
                      <div class="media-left">
                        <div class="icon is-small is-left has-text-primary">
                          <FontAwesomeIcon icon={faClock} size="lg" />
                        </div>
                      </div>
                      <div class="media-content">
                        <h2 class="is-size-6">Jangka waktu</h2>
                        {this.state.staying_type === "1" ? (
                          <p class="is-size-6">Visit</p>
                        ) : this.state.staying_type === "2" ? (
                          <p class="is-size-6">Settle</p>
                        ) : null}
                      </div>
                    </div>
                    <p />
                    {this.state.end_date === "" ? (
                      <div class="media">
                        <div class="media-left">
                          <div class="icon is-small is-left has-text-primary">
                            <FontAwesomeIcon icon={faMoon} size="lg" />
                          </div>
                        </div>
                        <div class="media-content">
                          <h2 class="is-size-6">Durasi</h2>
                          <p class="is-size-6">Tetap (Permanen)</p>
                        </div>
                      </div>
                    ) : null}
                    <div class="media">
                      <div class="media-left">
                        <div class="icon is-small is-left has-text-primary">
                          <FontAwesomeIcon icon={faCalendar} size="lg" />
                        </div>
                      </div>
                      <div class="media-content">
                        <h2 class="is-size-6">Check-in</h2>
                        <p class="is-size-6">{this.state.start_date}</p>
                      </div>
                    </div>
                    <p />
                    {this.state.staying_type === "1" ? (
                      <div class="media">
                        <div class="media-left">
                          <div class="icon is-small is-left has-text-primary">
                            <FontAwesomeIcon icon={faCalendar} size="lg" />
                          </div>
                        </div>
                        <div class="media-content">
                          <h2 class="is-size-6">Check-out</h2>
                          <p class="is-size-6">{this.state.end_date}</p>
                        </div>
                      </div>
                    ) : this.state.staying_type === "2" ? null : null}
                    <p />
                    <div class="media">
                      <div class="media-left">
                        <div class="icon is-small is-left has-text-primary">
                          <FontAwesomeIcon icon={faStickyNote} size="lg" />
                        </div>
                      </div>
                      <div class="media-content">
                        <h2 class="is-size-6">Catatan Tambahan</h2>
                        <p class="is-size-6">{this.state.notes}</p>
                      </div>
                    </div>
                  </>
                ) : null}
              </Modal>
            </Flex>
          </Flex>
        </div>
      </>
    );
  }
}
export default FormMovinOut;
