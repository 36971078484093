import React from 'react';
import Header from '../../../components/Notification/Header';
import { VStack } from '@chakra-ui/react';
import NotificationList from '../../../components/Notification/NotificationList';

function NotificationView() {
  return ( 
    <>
      <Header />
      <VStack align='start' mt='20px' px='30px' gap='12px'>
        <NotificationList title={'Recent Notifications'} />
      </VStack>
    </>
  );
}

export default NotificationView;