import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import "moment-timezone";
import "moment/locale/id";
import {
  Flex,
  Box,
  Text,
  Button,
  Image,
  Input,
  RadioGroup,
  Stack,
  Radio,
  Center,
  Skeleton,
  Spacer,
} from "@chakra-ui/react";
import MainHeader from "../../../../components/BookingRoom/Header/MainHeader";
import styles from "./roomdetail.module.css";
import { Link, useParams } from "react-router-dom";
import EmptySchedule from "../../../../assets/img/empty-room-schedule.svg";
import Axios from "../../../../utils/axiosService";
import defaultMeetingRoom from "../../../../assets/img/defaultMeetingRoom.png";
import ImageCarousel from "./Carousel";

function RoomDetails() {
  moment.locale("en");

  const params = useParams();
  const dateNow = moment(new Date()).format("dddd, DD MMMM YYYY");
  const stdDateNow = moment(new Date()).format("YYYY-MM-DD");
  const dateTomorrow = moment(new Date())
    .add(1, "days")
    .format("dddd, DD MMMM YYYY");
  const stdDateTomorrow = moment(new Date())
    .add(1, "days")
    .format("YYYY-MM-DD");
  const dateMin = moment(new Date()).format("yyyy-MM-DD");
  const dateMax = moment(new Date()).add(31, "days").format("yyyy-MM-DD");

  const allowedRoles = ["superadmin", "superuser"];
  const [datePicker, setDatePicker] = useState(false);
  const [dateData, setDateData] = useState(dateNow || null);
  const [stdDateData, setStdDateData] = useState(stdDateNow || null);
  const [roomId] = useState(params.roomId);
  const [roomData, setRoomData] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const [img, setImg] = useState("");
  const [roomAndFacilitiesImg, setRoomAndFacilitiesImg] = useState("");
  const [bookingRoomData, setBookingRoomData] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const filterData = JSON.parse(localStorage.getItem("filterRoom")) || {};
  const [userDetail, setUserDetail] = useState();

  const fetchUser = () => {
    let userData = JSON.parse(localStorage.getItem("user_data"));
    Axios({
      method: "GET",
      url: `/admin?user_id=${userData?.user_id}${
        userData?.user_email && `&user_email=${userData?.user_email}`
      }`,
    }).then((result) => {
      if (result.status === 200) {
        setUserDetail({ ...result.data.data.data[0] });
      }
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const loadRoomData = () => {
    Axios({
      method: "GET",
      url: `/room/${roomId}`,
    }).then((res) => {
      let facilityData = res.data.data.data.booking_room_facilities;
      setFacilityData(res.data.data.data.booking_room_facilities);
      setRoomData(res.data.data.data);

      if (res.data.data.data.image) {
        let facilityImage = facilityData.map((data) => {
          let images = data.image;
          return images;
        });

        let roomImage = [res.data.data.data.image];

        setRoomAndFacilitiesImg(roomImage.concat(facilityImage));
        setImg(res.data.data.data.image);
      } else {
        setImg(defaultMeetingRoom);
      }
    });
  }

  const refreshDate = () => {
    setDateData(
      filterData?.date
        ? moment(filterData?.date).format("dddd, DD MMMM YYYY")
        : dateNow
    );
    setStdDateData(
      moment(new Date(filterData?.date || stdDateNow)).format("YYYY-MM-DD")
    );
    if (
      filterData?.date === stdDateTomorrow ||
      filterData?.date === stdDateNow
    ) {
      setDatePicker(false);
    } else {
      setDatePicker(true);
    }
  }

  const loadBookingData = async () => {
    setIsLoading(true);

    let tempEarlyDate = `${stdDateData} 00:00:00`;
    let tempLateDate = `${stdDateData} 23:59:59`;

    await Axios({
      method: "GET",
      url: `/room/booking?room_id=${roomId}&status=Booking Created`,
    }).then(async (res) => {
      let tempBookingData = res.data.data.data.rows;
      
      tempBookingData = tempBookingData.sort(function (a, b) {
        return b.id - a.id;
      });

      if (tempBookingData.length > 0) {
        const startDate = new Date(tempEarlyDate);
        const endDate = new Date(tempLateDate);
        let filteredOccurrences = [];
        let filteredBookingAndOccurrences = [];

        tempBookingData.forEach((booking) => {
          let bookingOccurrences = booking.booking_room_booking_occurances;
          if (bookingOccurrences.length > 0) {
            let occurrencesInRange = bookingOccurrences.filter((occurrence) => {
              const startUsage = new Date(occurrence.start_usage);
              const endUsage = new Date(occurrence.finish_usage);
              return ((startUsage >= startDate && endUsage <= endDate) && (!occurrence.is_cancel && occurrence.status.includes("Created")));
            });
            filteredOccurrences.push(...occurrencesInRange);
          }
        });

        filteredOccurrences.forEach((occurrence) => {
          let bookingDataInRange = tempBookingData.filter((bookings) => {
            return (bookings.id === occurrence.booking_id);
          });
          filteredBookingAndOccurrences.push(...bookingDataInRange);
        });

        const mergedArray = filteredOccurrences.map((occ) => {
          const matchedBooking = filteredBookingAndOccurrences.find(
            (booking) => (booking.id === occ.booking_id)
          );
          if (matchedBooking) {
            return { ...occ, booking_room_booking_list: matchedBooking };
          }
          return occ;
        });

        const sortedMergedArray = mergedArray.sort(function (a, b) {
          return new Date(a.start_usage) - new Date(b.start_usage);
        });

        setBookingRoomData(sortedMergedArray);
      }

      setIsLoading(false);
    });
  }

  useEffect(() => {
    loadRoomData();
    // eslint-disable-next-line
  }, [roomId]);

  useEffect(() => {
    refreshDate();
    // eslint-disable-next-line
  }, [dateNow, stdDateNow]);

  useEffect(() => {
    setBookingRoomData([]);
    loadBookingData();
    // eslint-disable-next-line
  }, [dateNow, stdDateData, stdDateNow]);

  const setValue = (event, name) => {
    let tempSetSelectedDate = [...selectedDate];
    tempSetSelectedDate[name] = event;

    if (event === "datepicker") {
      setDateData(
        moment(filterData?.date).format("dddd, DD MMMM YYYY") || null
      );
      setStdDateData(moment(filterData?.date).format("YYYY-MM-DD") || null);
      setDatePicker(true);
    } else if (event === "tomorrow") {
      setDateData(dateTomorrow);
      setStdDateData(stdDateTomorrow);
      setDatePicker(false);
    } else if (event === "today") {
      setDateData(dateNow);
      setStdDateData(stdDateNow);
      setDatePicker(false);
    }

    setSelectedDate(tempSetSelectedDate);
  };

  const handleInput = (event) => {
    setDateData(moment(event.target.value).format("dddd, DD MMMM YYYY"));
    setStdDateData(moment(event.target.value).format("YYYY-MM-DD"));
  };

  return (
    <>
      <Flex className={styles["header-container"]}>
        <MainHeader title="Room Information" backLink="/booking-room?id=2" />
      </Flex>
      <Flex className={styles["main-container"]}>
        <Box>
          <Text className={styles["text-title"]}>{roomData?.name}</Text>
        </Box>
        <Box className={styles["image-styling"]}>
          <Skeleton
            isLoaded={roomData && (roomAndFacilitiesImg || img)}
            height="100%"
            width="100%"
          >
            {roomAndFacilitiesImg ? (
              <ImageCarousel imageData={roomAndFacilitiesImg} />
            ) : (
              <Image marginBottom="1.25em" src={img} alt="meeting-room" />
            )}
          </Skeleton>
        </Box>
        <Box>
          <Flex className={styles["room-detail-info"]}>
            <Box width={"40%"}>
              <Flex>
                <Text className={styles["text-variable"]}>Location:</Text>
              </Flex>
            </Box>
            <Box width={"60%"}>
              <Flex>
                <Text className={styles["text-value"]}>
                  {roomData.office_area ? roomData.office_area.name : null}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex className={styles["room-detail-info"]}>
            <Box width={"40%"}>
              <Flex>
                <Text className={styles["text-variable"]}>Details:</Text>
              </Flex>
            </Box>
            <Box width={"60%"}>
              <Flex>
                <Text className={styles["text-value"]}>
                  {roomData?.location_detail}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex className={styles["room-detail-info"]}>
            <Box width={"40%"}>
              <Flex>
                <Text className={styles["text-variable"]}>Type:</Text>
              </Flex>
            </Box>
            <Box width={"60%"}>
              <Flex>
                <Text className={styles["text-value"]}>{roomData?.type}</Text>
              </Flex>
            </Box>
          </Flex>
          <Flex className={styles["room-detail-info"]}>
            <Box width={"40%"}>
              <Flex>
                <Text className={styles["text-variable"]}>Capacity:</Text>
              </Flex>
            </Box>
            <Box width={"60%"}>
              <Flex>
                <Text className={styles["text-value"]}>
                  {roomData?.capacity}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex marginBottom={"1.75em"}>
            <Box width={"40%"}>
              <Flex>
                <Text className={styles["text-variable"]}>
                  {facilityData.length > 1 ? "Facilities:" : "Facility:"}
                </Text>
              </Flex>
            </Box>
            <Box width={"60%"}>
              {facilityData?.map((facility, index) => (
                <Flex>
                  <Text className={styles["text-value"]} key={index}>
                    - {facility.item_name}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Flex>
          <Link to={`/booking-room/booking/${roomId}/${stdDateData}`}>
            <Button bg="#006F95" className={styles["book-room-button"]}>
              Book this room
            </Button>
          </Link>
        </Box>
        <Box>
          <Text className={styles["text-schedules"]}>Booked Schedules</Text>
          <RadioGroup
            name="dateSelector"
            onChange={(event) => setValue(event, "dateSelector")}
            defaultValue={
              filterData
                ? filterData?.date === stdDateTomorrow
                  ? "tomorrow"
                  : filterData?.date === stdDateNow
                  ? "today"
                  : "datepicker"
                : "today"
            }
          >
            <Stack spacing={{ base: 2, sm: 3, md: 5 }} direction="row">
              <Radio value="today" checked="checked">
                <Text fontSize="sm">Today</Text>
              </Radio>
              <Spacer />
              <Radio value="tomorrow">
                <Text fontSize="sm">Tomorrow</Text>
              </Radio>
              <Spacer />
              <Radio value="datepicker">
                <Text fontSize="sm">Choose Date</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Box>
          {datePicker && (
            <Box className={styles["input-date"]}>
              <Input
                type="date"
                onChange={handleInput}
                min={dateMin}
                max={allowedRoles.includes(userDetail?.role?.toLowerCase()) ? null : dateMax}
                defaultValue={filterData?.date || dateData}
              />
            </Box>
          )}
          {dateData ? (
            <>
              <Text className={styles["text-schedules-date"]}>{dateData}</Text>
            </>
          ) : null}
        </Box>
        <Box>
          {isLoading ? (
            <>
              <Skeleton
                height="150px"
                margin="0.5em 0"
                padding="0.5em 1em"
                borderRadius="10px"
                color="rgba(97, 170, 174, 0.15)"
              />
            </>
          ) : bookingRoomData.length > 0 ? (
            bookingRoomData.map((bookingData, index) => (
              <Flex key={`item no.${index}`}>
                <Box className={styles["schedule-card"]}>
                  <Flex>
                    <Box width={"25%"}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>Time:</Text>
                      </Flex>
                    </Box>
                    <Box width={"75%"}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          {moment
                            .utc(bookingData?.start_usage)
                            .tz("Asia/Jakarta")
                            .format("HH:mm")}{" "}
                          -{" "}
                          {moment
                            .utc(bookingData?.finish_usage)
                            .tz("Asia/Jakarta")
                            .format("HH:mm")}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={"25%"}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>
                          Meeting:
                        </Text>
                      </Flex>
                    </Box>
                    <Box width={"70%"}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          {
                            bookingData?.booking_room_booking_list
                              ?.booking_purpose
                          }
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={"25%"}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>
                          PIC:
                        </Text>
                      </Flex>
                    </Box>
                    <Box width={"75%"}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          {
                            bookingData?.booking_room_booking_list?.user
                              ?.user_name
                          }
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={"25%"}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>
                          Contact:
                        </Text>
                      </Flex>
                    </Box>
                    <Box width={"75%"}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          {bookingData?.booking_room_booking_list?.user_contact}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            ))
          ) : (
            <>
              <Center>
                <Image src={EmptySchedule} height={"250px"} />
              </Center>
              <Text className={styles["no-agenda"]}>
                No bookings for this day
              </Text>
            </>
          )}
        </Box>
      </Flex>
    </>
  );
}

export default RoomDetails;
