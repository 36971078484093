import React from "react";

import Header from "../../../components/SecondaryHeader";
import History from "../../../components/UpcomingHistory/AllList/History";
import BottomNavbar from "../../../components/Navbars/BottomNavbar";

function HistoryView() {
  return (
    <>
      <Header type="all" title="Riwayat" backLink="/" />
      <History />
      <BottomNavbar />
    </>
  );
}

export default HistoryView;
