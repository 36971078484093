import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import CarCard from "../../BookingCar/MainCar/Card/ListCarCard";

const ListCarWithLabel = ({
  cars = [],
  loading = false,
  label,
  emptyPlaceholder = null,
  pageType = "byDate",
}) => {
  return (
    <Box>
      <Flex mb="24px" flexDir="column">
        <Text fontSize="14px" mb="14px" fontWeight="500">
          {label}
        </Text>
        {cars?.length > 0 ? (
          <Flex width="100%" direction="column" rowGap="16px">
            {cars.map((car) => (
              <CarCard key={car.id} {...car} pageType={pageType} />
            ))}
          </Flex>
        ) : (
          emptyPlaceholder
        )}
      </Flex>
    </Box>
  );
};

export default ListCarWithLabel;
