import React from "react";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { Link as ChakraLink, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Notification({ nNotif }) {
  return (
    <Link to="/notification">
      <ChakraLink pos="relative">
        {nNotif > 0 && (
          <Text
            pos="absolute"
            top="3"
            right="-2"
            fontSize="7px"
            color="#FFF"
            fontWeight="900"
            bg="rgba(0, 111, 149, 1)"
            borderRadius="50%"
            w="10px"
            h="10px"
            textAlign="center"
          >
            {nNotif}
          </Text>
        )}
        <MdOutlineNotificationsNone size="20px" />
      </ChakraLink>
    </Link>
  );
}

export default Notification;
