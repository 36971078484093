import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FetchCarList } from "../../../api/fetchCarList";
import { Flex, Text, Button, CircularProgress } from "@chakra-ui/react";

import CarCard from "./Card/ListCarCard";

const CarList = () => {
  const navigate = useHistory();
  const [carList, setCarList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCarList = async () => {
      const { carList, loading } = await FetchCarList({ params: { limit: 3 } });
      setCarList(carList);
      setIsLoading(loading);
    };

    fetchCarList();
  }, []);

  return (
    <Flex width="100%" direction="column" rowGap="12px" marginTop="24px" padding="0 24px">
      <Text fontSize="14px" fontWeight="500">
        Daftar Mobil
      </Text>
      {isLoading ? (
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          <CircularProgress isIndeterminate color="#3182CE" />
        </Flex>
      ) : (
        <Flex width="100%" direction="column" rowGap="16px">
          {carList.map((car) => (
            <CarCard key={car.id} {...car} pageType="byCar" />
          ))}
        </Flex>
      )}
      <Button
        color="#3182CE"
        fontSize="14px"
        fontWeight="400"
        background="transparent"
        variant="link"
        width="fit-content"
        margin="0 auto"
        onClick={() => navigate.push("/booking-car/list-car")}
      >
        Lihat seluruh daftar mobil
      </Button>
    </Flex>
  );
};

export default CarList;
