import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import MascotQuestion from "../../../assets/img/mascot-03.png";

function FormModal({ isOpen, onClose }) {
  const contactMobil = "+6287709255800";
  const contactSecurity = "+6287780509562";

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%" padding="24px">
        <ModalBody padding="0">
          <Flex
            direction="column"
            gap="24px"
            width="100%"
            padding="16px"
            bg="#FFFFFF"
            borderRadius="12px"
          >
            <Flex direction="column" gap="10px" alignItems="center">
              <Image src={MascotQuestion} alt="" w="150px" h="150px" />
              <Flex
                direction="column"
                gap="4px"
                alignItems="center"
                textAlign="center"
              >
                <Text fontSize="14px" fontWeight="500">
                  Ada Pertanyaan Terkait Form Kelengkapan Kendaraan?
                </Text>
                <Text fontSize="12px" fontWeight="400">
                  Hubungi PIC berikut ini untuk informasi lebih lanjut
                </Text>
              </Flex>
              <Flex
                minWidth="max-content"
                flexDirection={["column", "row"]}
                alignItems="center"
                gap={[2, 10]}
              >
                <Button
                  padding="6px 16px 6px 12px"
                  colorScheme="teal"
                  variant="outline"
                  borderRadius="100px"
                  gap="8px"
                  direction="row"
                  width="100%"
                  height="fit-content"
                  alignItems="center"
                  onClick={() => window.open(`https://wa.me/${contactMobil}`)}
                >
                  <Text fontSize="12px" fontWeight="400">
                    Imam Fajri (Mobil)
                  </Text>
                </Button>
                <Button
                  padding="6px 16px 6px 12px"
                  colorScheme="teal"
                  variant="outline"
                  borderRadius="100px"
                  gap="8px"
                  direction="row"
                  width="100%"
                  height="fit-content"
                  alignItems="center"
                  onClick={() =>
                    window.open(`https://wa.me/${contactSecurity}`)
                  }
                >
                  <Text fontSize="12px" fontWeight="400">
                    Faroz (Security)
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default FormModal;
