import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { BiQuestionMark } from "react-icons/bi";
import { MdOutlineChevronLeft } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { CONTACT_LIST_DATA } from "../utils/constant.js";
import CarContactModal from "./BookingCar/Header/ContactModal.jsx";
import ContactListModal from "./CTA/ContactListModal.jsx";
import FormModal from "./BookingCar/Header/FormModal.jsx";

function Header({ title, backLink, isFormDeparture, type, isPreviousPage = false }) {
  const navigate = useHistory();

  const [isOpenQuestion, setIsOpenQuestion] = useState(false);

  return (
    <Flex
      width="100%"
      bg="#FFFFFF"
      alignItems="center"
      justifyContent="space-between"
      padding="16px"
      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
    >
      <Flex alignItems="center" justifyContent="left" gap="8px">
        <Box>
          {backLink && (
            <IconButton
              icon={<MdOutlineChevronLeft color="#222222" size="24px" />}
              onClick={() => {
                if (isPreviousPage) {
                  navigate.goBack();
                } else {
                  navigate.push(backLink);
                }
              }}
              colorScheme="white"
            />
          )}
        </Box>
        <Text fontSize="16px" fontWeight="400" color="#222222">
          {title}
        </Text>
      </Flex>
      <Button
        bg="#FFFFFF"
        height="24px"
        width="24px"
        minWidth="24px"
        borderRadius="100%"
        padding="4px"
        cursor="pointer"
        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
        onClick={() => setIsOpenQuestion(true)}
      >
        <BiQuestionMark color="#222222" size="16px" />
      </Button>

      {isOpenQuestion && (
        <>
          {type === "car" && !isFormDeparture ? (
            <CarContactModal isOpen={isOpenQuestion} onClose={() => setIsOpenQuestion(false)} />
          ) : isFormDeparture ? (
            <FormModal isOpen={isOpenQuestion} onClose={() => setIsOpenQuestion(false)} />
          ) : (
            <ContactListModal
              data={CONTACT_LIST_DATA}
              isOpen={isOpenQuestion}
              onClose={() => setIsOpenQuestion(false)}
            />
          )}
        </>
      )}
    </Flex>
  );
}

export default Header;
