import { useHistory, useLocation } from "react-router-dom";

import { Text, Flex, Box, Button } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  MdCircle,
  MdMeetingRoom,
  MdDirectionsCar,
  MdHouse,
} from "react-icons/md";

import {
  formatISOtoDayAndHHmm,
  hasDatePassed,
} from "../../../utils/formatDate";

import LeavingReturningTime from "../../BookingCar/Component/LeavingReturningTime";

const mapBookingDetails = (booking_type, id) => {
  let icon, title, detailLink;
  switch (booking_type) {
    case "car":
      icon = <MdDirectionsCar size="28px" color="#222222" />;
      title = "Booking Car";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    case "room":
      icon = <MdMeetingRoom size="28px" color="#222222" />;
      title = "Booking Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
    case "mess":
      icon = <MdHouse size="28px" color="#222222" />;
      title = "Booking Mess";
      detailLink = `/booking-mess/booking-detail/${id}`;
      break;
    default:
      icon = <MdCircle size="28px" color="#222222" />;
      title = "Booking Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
  }
  return { icon, title, detailLink };
};

export function Card({ type, data }) {
  const navigate = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  let status;
  if (type === "history") {
    status =
      data.booking_status === "Booking Cancelled" ? "Dibatalkan" : "Selesai";
  }

  const { icon, title, detailLink } = mapBookingDetails(
    data.booking_type,
      (data.booking_type === "room")?data.booking_id:data.id
  );

  const Divider = () => (
    <Flex px="8px" my="8px">
      <Box borderBottom="1px solid" borderColor="#EFEFEF" width="100%" />
    </Flex>
  );

  return (
    <Flex
      direction="column"
      padding="8px 0 0 0"
      mx="16px"
      my="1px"
      spacing="0"
      bg="#FFFFFF"
      style={{
        boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
        borderRadius: "12px",
      }}
    >
      <Flex
        direction="row"
        justifyContent="space-between"
        padding="0 8px"
        alignItems="center"
      >
        <Flex direction="row" alignItems="center" columnGap="4px">
          {icon}
          <Text fontSize="12px" fontWeight="400">
            {title}
          </Text>
          {status && (
            <Box
              bg={status === "Selesai" ? "#38A169" : "#E53E3E"}
              borderRadius="4px"
              padding="2px 5px"
            >
              <Text fontSize="9px" fontWeight="400" color="#FFFFFF">
                {status}
              </Text>
            </Box>
          )}
        </Flex>
        <Button
          variant="link"
          color="#3182CE"
          _hover={{ textDecoration: "none" }}
          onClick={() =>
            navigate.push({
              pathname: detailLink,
              state: { backLink: currentPath },
            })
          }
        >
          <Text fontSize="11px" fontWeight="400">
            Lihat detail booking
          </Text>
          <ChevronRightIcon size="16px" />
        </Button>
      </Flex>
      <Divider />
      {data.booking_type === "car" && (
        <>
          <Box padding="0 8px 8px 8px">
            <LeavingReturningTime
              leavingDate={formatISOtoDayAndHHmm(data.start_usage).date}
              leavingTime={formatISOtoDayAndHHmm(data.start_usage).time}
              returningDate={formatISOtoDayAndHHmm(data.finish_usage).date}
              returningTime={formatISOtoDayAndHHmm(data.finish_usage).time}
            />
          </Box>
          {data.booking_status === "In Progress" &&
            !hasDatePassed(data.start_usage) && (
              <Flex
                borderRadius="0px 0px 12px 12px"
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                bg="#73777F"
                color="white"
              >
                <Text
                  fontSize="12px"
                  fontWeight="400"
                  textAlign="center"
                  p="8px"
                  width="100%"
                >
                  Mobil sedang digunakan
                </Text>
              </Flex>
            )}
        </>
      )}
      {data.booking_type === "room" && (
        <>
          <Flex direction="row" justifyContent="space-between" padding="0 8px">
            <Flex w="50%" alignItems="flex-start" direction="column">
              <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
                Jadwal
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {formatISOtoDayAndHHmm(data.start_usage).date}
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {formatISOtoDayAndHHmm(data.start_usage).time} -{" "}
                {formatISOtoDayAndHHmm(data.finish_usage).time}
              </Text>
            </Flex>
            <Flex w="50%" alignItems="flex-start" direction="column">
              <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
                Ruangan
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {data.booking_details.room_name},
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {data.booking_details.area_name}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex
            direction="row"
            justifyContent="space-between"
            padding="0 8px 8px 8px"
          >
            <Flex w="100%" alignItems="flex-start" direction="column">
              <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
                Keperluan
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {data.booking_details.booking_purpose}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}
