import React from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHotel,
  faGlobe,
  faUsers,
  faFileAlt,
  faAngleRight,
  faPhone,
  faLocationArrow,
  faHome,
  faClock,
  faMoon,
  faCalendar,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import AxiosParastay from "../../../../../../utils/parastayAxiosService";
import { Flex, Box, Spacer, Text, Button } from "@chakra-ui/react";
import styles from "./styles.module.css";
import MainHeader from "../../../../../../components/Parastay/Header/MainHeader";

const Modal = ({ children, closeModal, modalState, title, HandleSubmit }) => {
  if (!modalState) {
    return null;
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-card" style={{ width: "90%" }}>
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">
            <strong>{title}</strong>
          </p>
          <button className="delete" onClick={closeModal} />
        </header>
        <section className="modal-card-body">
          <div className="content">{children}</div>
        </section>
        <footer className="modal-card-foot">
          <a href={() => false} className="button" onClick={HandleSubmit}>
            Submit
          </a>
          <a href={() => false} className="button" onClick={closeModal}>
            Cancel
          </a>
        </footer>
      </div>
    </div>
  );
};

class FormBookMess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      work_location_id: null,
      occupant_type: null,
      bring_family: false,
      request_date: null,
      start_date: null,
      end_date: null,
      staying_type: null,
      checked: false,
      booking_status_id_fk: null,
      status: null,
      notes: null,
      user_data: {
        data: [
          {
            user_id: null,
            user_gender: null,
            work_location_id_fk: null,
            user_phone: "",
          },
        ],
      },
      modalState: false,
      ThreeMonthsLater: null,
      Tomorrow: null,
      Today: null,
    };
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const idUser = localStorage.getItem("id_user_parastay");

    AxiosParastay({
      method: "GET",
      url: `/UserDataforBooking?user_id=${idUser}`,
    })
    .then((result) => {
      this.setState({
        user_data: result.data,
      });
    });

    const today = new Date().toISOString();
    var TempToday = new Date(today).toISOString().split("T")[0];

    const TempThreeMonthsLater = new Date(today);
    TempThreeMonthsLater.setDate(TempThreeMonthsLater.getDate() + 90);

    const TempTomorrow = new Date(today);
    TempTomorrow.setDate(TempTomorrow.getDate() + 1);

    const ThreeMonthsLater = new Date(TempThreeMonthsLater)
      .toISOString()
      .split("T")[0];
    const Tomorrow = new Date(TempTomorrow).toISOString().split("T")[0];

    this.setState({
      ThreeMonthsLater: ThreeMonthsLater,
      Tomorrow: Tomorrow,
      Today: TempToday,
    });
  }

  validateSubmit = (data) => {
    var isUserValid = data.user_id_fk !== null;
    var isOccTypeValid = data.occupant_type !== null;
    var isStayTypeValid = data.staying_type !== null;
    var isBringFamValid = data.bring_family !== null;
    var IsReqDateValid = data.request_date !== null;
    var isStartDateValid = data.start_date !== null;
    var isBookStatusValid = data.booking_status_id_fk !== null;
    var isNotesValid = data.notes !== null;
    var isSenderValid = data.sender_id !== null;
    var isSendDateValid = data.send_datetime !== null;
    var isBookLogValid = data.booking_log !== null;
    var isBookStatus2Valid = data.booking_status_id !== null;
    var isWorkLocValid = data.work_location_id_fk2 !== null;
    var isWorkLocIDValid = data.id_work_area !== null;

    return (
      isUserValid &&
      isOccTypeValid &&
      isStayTypeValid &&
      isBringFamValid &&
      IsReqDateValid &&
      isStartDateValid &&
      isBookStatusValid &&
      isNotesValid &&
      isSenderValid &&
      isSendDateValid &&
      isBookLogValid &&
      isBookStatus2Valid &&
      isWorkLocValid &&
      isWorkLocIDValid
    );
  };

  handleSubmit() {
    Swal.fire({
      title: "Creating Booking Request...",
      html: "Please wait.",
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    Swal.showLoading();

    var currentDate = new Date();
    var endDate = null;
    const id_user = localStorage.getItem("id_user_parastay");

    var startDate = new Date(this.state.start_date);

    if (this.state.end_date === null) {
      endDate = null;
    } else {
      endDate = new Date(this.state.end_date);
    }

    let WorkLocation = "";

    if (this.state.work_location_id === 1) {
      WorkLocation = "DC";
    } else if (this.state.work_location_id === 2) {
      WorkLocation = "HEAD OFFICE";
    } else if (this.state.work_location_id === 3) {
      WorkLocation = "PLANT";
    } else {
      WorkLocation = "";
    }

    const BookingData = {
      user_id_fk: parseInt(id_user),
      occupant_type: parseInt(this.state.occupant_type),
      staying_type: parseInt(this.state.staying_type),
      bring_family: this.state.bring_family,
      request_date: currentDate,
      start_date: startDate,
      end_date: endDate,
      booking_status_id_fk: 1,
      notes: this.state.notes,
      sender_id: parseInt(id_user),
      send_datetime: currentDate,
      booking_log: "Booking Created",
      booking_status_id: 1,
      work_location_id_fk2: WorkLocation,
      id_work_area: this.state.work_location_id,
    };

    if (this.validateSubmit(BookingData)) {
      AxiosParastay({
        method: "POST",
        url: `/CreateBooking`,
        data: BookingData
      })
      .then(() => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Booking Created",
          confirmButtonColor: "blue",
          confirmButtonText: `OK`,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.assign("./");
          }
        });
      })
      .catch(function (error) {
        console.error(error);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Error: Failed to create Booking",
          text: error,
          confirmButtonColor: "blue",
          confirmButtonText: `OK`,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      });
    } else {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Pastikan semua data sudah terisi",
        confirmButtonColor: "blue",
        confirmButtonText: `OK`,
      });
    }
  }

  toggleModal() {
    this.setState((prev, props) => {
      const newState = !prev.modalState;
      return { modalState: newState };
    });
  }

  ChangeHandler(event) {
    this.setState({
      [event.target.name]:
        event.target.type === "number"
          ? parseInt(event.target.value)
          : event.target.value,
    });
  }

  render() {
    let TempMinStartDate = null;
    let TempMaxStartDate = null;
    let MinStartDate = null;
    let MaxStartDate = null;

    if (this.state.start_date) {
      TempMinStartDate = new Date(this.state.start_date);
      TempMinStartDate.setDate(TempMinStartDate.getDate() + 1);

      TempMaxStartDate = new Date(this.state.start_date);
      TempMaxStartDate.setDate(TempMaxStartDate.getDate() + 91);

      MinStartDate = new Date(TempMinStartDate).toISOString().split("T")[0];
      MaxStartDate = new Date(TempMaxStartDate).toISOString().split("T")[0];
    }

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    let finalCheckOutDate = null;
    if (this.state.end_date !== null) {
      let checkOutDate = new Date(this.state.end_date);
      finalCheckOutDate = checkOutDate.toLocaleDateString("id", options);
    } else if (this.state.end_date === null) {
      finalCheckOutDate = null;
    }

    let finalCheckInDate = null;
    if (this.state.start_date !== null) {
      let checkInDate = new Date(this.state.start_date);
      finalCheckInDate = checkInDate.toLocaleDateString("id", options);
    } else if (this.state.start_date === null) {
      finalCheckInDate = null;
    }

    return (
      <>
        <div className="App">
          <Flex className={styles["header-container"]}>
            <MainHeader title="Request Mess" backLink={`/parastay?id=1`} />
          </Flex>
          <Flex className={styles["main-container"]}>
            <Flex className={styles["subheader-container"]}>
              <Text fontSize="24px" fontWeight="700" mb="0.5em">
                Request Mess
              </Text>
              <a
                class="button is-gomess-babyblue is-fullwidth"
                href="./terms-conditions"
                onClick={this.handleClick}
              >
                <Box color="#30C1FF">
                  <FontAwesomeIcon icon={faFileAlt} size="md" />
                </Box>
                <Spacer />
                <Box>
                  <Text fontSize="14px" fontWeight="600">
                    Syarat dan Ketentuan Request Mess
                  </Text>
                </Box>
                <Spacer />
                <Box color="#30C1FF">
                  <FontAwesomeIcon icon={faAngleRight} size="md" />
                </Box>
              </a>
            </Flex>
            <form action="">
              <p class="subtitle is-6 has-text-left">
                Isian yang ditandai dengan (<b>*</b>) wajib diisi
              </p>
              <div class="field mb-5">
                <label for="" class="label">
                  Lokasi Kerja*
                </label>
                <div class="control is-expanded has-icons-left">
                  <div class="select is-fullwidth">
                    <select
                      id="work_location_id"
                      name="work_location_id"
                      value={this.state.work_location_id}
                      onChange={this.ChangeHandler}
                    >
                      <option value={null} selected>
                        (Pilih Lokasi Kerja)
                      </option>
                      <option value={2}>Head Office</option>
                      <option value={3}>Plant</option>
                      <option value={1}>DC</option>
                    </select>
                  </div>
                  <div class="icon is-small is-left has-text-primary">
                    <FontAwesomeIcon icon={faGlobe} size="sm" />
                  </div>
                </div>
              </div>
              <div class="field mb-5">
                <label for="" class="label">
                  Bersama Keluarga/Pasangan?*
                </label>
                <div class="control is-expanded has-icons-left">
                  <div class="select is-fullwidth">
                    <select
                      id="bring_family"
                      name="bring_family"
                      value={this.state.bring_family}
                      onChange={this.ChangeHandler}
                    >
                      <option value={null} selected>
                        (Ya/Tidak?)
                      </option>
                      <option value={true}>Iya</option>
                      <option value={false}>Tidak</option>
                    </select>
                  </div>
                  <div class="icon is-small is-left has-text-primary">
                    <FontAwesomeIcon icon={faUsers} size="sm" />
                  </div>
                </div>
              </div>
              <div class="field mb-5">
                <label for="" class="label">
                  Kategori Mess*
                </label>

                {this.state.bring_family === "true" ? (
                  <div class="control is-expanded has-icons-left">
                    <div class="select is-fullwidth">
                      <select
                        id="occupant_type"
                        name="occupant_type"
                        value={this.state.occupant_type}
                        onChange={this.ChangeHandler}
                      >
                        <option value={null} selected>
                          (Pilih Kategori Mess)
                        </option>
                        <option value="2">Mess Couple</option>
                      </select>
                    </div>
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faHotel} size="sm" />
                    </div>
                  </div>
                ) : (
                  <div class="control is-expanded has-icons-left">
                    <div class="select is-fullwidth">
                      <select
                        id="occupant_type"
                        name="occupant_type"
                        value={this.state.occupant_type}
                        onChange={this.ChangeHandler}
                      >
                        <option value={null} selected>
                          (Pilih Kategori Mess)
                        </option>
                        <option value="1">Mess Single</option>
                      </select>
                    </div>
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faHotel} size="sm" />
                    </div>
                  </div>
                )}
              </div>
              <div class="field mb-5">
                <label for="" class="label">
                  Jangka Waktu*
                </label>
                {this.state.bring_family === "true" ? (
                  <div class="control is-expanded has-icons-left">
                    <div class="select is-fullwidth">
                      <select
                        id="staying_type"
                        name="staying_type"
                        value={this.state.staying_type}
                        onChange={this.ChangeHandler}
                      >
                        <option value={null} selected>
                          (Select)
                        </option>
                        <option value="2">Settle</option>
                      </select>
                    </div>
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faUsers} size="sm" />
                    </div>
                  </div>
                ) : (
                  <div class="control is-expanded has-icons-left">
                    <div class="select is-fullwidth">
                      <select
                        id="staying_type"
                        name="staying_type"
                        value={this.state.staying_type}
                        onChange={this.ChangeHandler}
                      >
                        <option value={null} selected>
                          (Select)
                        </option>
                        <option value="1">Visit</option>
                        <option value="2">Settle</option>
                      </select>
                    </div>
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faUsers} size="sm" />
                    </div>
                  </div>
                )}
              </div>
              <div class="field mb-5">
                <label for="" class="label">
                  Check-in*
                </label>
                <div class="control has-icons-left">
                  <input
                    class="input date"
                    type="date"
                    min={this.state.Today}
                    max={this.state.ThreeMonthsLater}
                    id="start_date"
                    name="start_date"
                    value={this.state.start_date}
                    onChange={this.ChangeHandler}
                    required
                  />
                  <span class="icon is-left has-text-primary">
                    <FontAwesomeIcon icon={faCalendar} size="sm" />
                  </span>
                </div>
              </div>
              {this.state.staying_type === "1" ||
              this.state.staying_type === null ? (
                this.state.start_date ? (
                  <div class="field mb-5">
                    <label for="" class="label">
                      Check-out*
                    </label>
                    <div class="control has-icons-left">
                      <input
                        class="input date"
                        min={MinStartDate}
                        max={MaxStartDate}
                        type="date"
                        id="end_date"
                        name="end_date"
                        value={
                          this.state.end_date === null
                            ? MinStartDate
                            : this.state.end_date
                        }
                        onChange={this.ChangeHandler}
                        required
                      />
                      <span class="icon is-left has-text-primary">
                        <FontAwesomeIcon icon={faCalendar} size="sm" />
                      </span>
                    </div>
                  </div>
                ) : (
                  <fieldset disabled>
                    <div class="field mb-5">
                      <label for="" class="label">
                        Check-out*
                      </label>
                      <div class="control has-icons-left">
                        <input
                          class="input date"
                          min={MinStartDate}
                          max={MaxStartDate}
                          type="date"
                          id="end_date"
                          name="end_date"
                          value={this.state.end_date}
                          onChange={this.ChangeHandler}
                          required
                        />
                        <span class="icon is-left has-text-primary">
                          <FontAwesomeIcon icon={faCalendar} size="sm" />
                        </span>
                      </div>
                    </div>
                  </fieldset>
                )
              ) : null}
              <div class="field mb-5">
                <label for="" class="label">
                  Catatan Tambahan*
                </label>
                <textarea
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  class="textarea"
                  placeholder="Catatan Tambahan"
                  rows="3"
                  onChange={this.ChangeHandler}
                ></textarea>
              </div>
            </form>
            <Button
              href={() => false}
              onClick={this.toggleModal}
              width="100%"
              bgColor="#30C1FF"
            >
              <Text fontSize="20px" fontWeight="600" color="#fff">
                Konfirmasi
              </Text>
            </Button>
          </Flex>
          <Modal
            modalState={this.state.modalState}
            closeModal={this.toggleModal}
            HandleSubmit={this.handleSubmit}
            title="Konfirmasi"
          >
            <p class="has-text-centered is-spaced">
              <strong>Apa anda yakin data yang dimasukkan sudah sesuai?</strong>
            </p>
            <p />
            <div class="media">
              <div class="media-left">
                <div class="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faPhone} size="lg" />
                </div>
              </div>
              <div class="media-content">
                <h2 class="is-size-6">Nomor telfon</h2>
                <p class="is-size-6">
                  {this.state.user_data.data[0].user_phone}
                </p>
              </div>
            </div>
            <p />
            <div class="media">
              <div class="media-left">
                <div class="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faLocationArrow} size="lg" />
                </div>
              </div>
              <div class="media-content">
                <h2 class="is-size-6">Lokasi kerja</h2>
                {this.state.work_location_id &&
                this.state.work_location_id.includes(1) ? (
                  <p class="is-size-6">Distribution Center</p>
                ) : this.state.work_location_id &&
                  this.state.work_location_id.includes(2) ? (
                  <p class="is-size-6">Head Office</p>
                ) : this.state.work_location_id &&
                  this.state.work_location_id.includes(3) ? (
                  <p class="is-size-6">Plant</p>
                ) : null}
              </div>
            </div>
            <p />
            <div class="media">
              <div class="media-left">
                <div class="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faHome} size="lg" />
                </div>
              </div>
              <div class="media-content">
                <h2 class="is-size-6">Kategori mess</h2>
                {this.state.occupant_type === "1" ? (
                  <p class="is-size-6">Single</p>
                ) : this.state.occupant_type === "2" ? (
                  <p class="is-size-6">Couple</p>
                ) : null}
              </div>
            </div>
            <p />
            <div class="media">
              <div class="media-left">
                <div class="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faClock} size="lg" />
                </div>
              </div>
              <div class="media-content">
                <h2 class="is-size-6">Jangka waktu</h2>
                {this.state.staying_type === "1" ? (
                  <p class="is-size-6">Visit</p>
                ) : this.state.staying_type === "2" ? (
                  <p class="is-size-6">Settle</p>
                ) : null}
              </div>
            </div>
            <p />
            {this.state.end_date === "" ? (
              <div class="media">
                <div class="media-left">
                  <div class="icon is-small is-left has-text-primary">
                    <FontAwesomeIcon icon={faMoon} size="lg" />
                  </div>
                </div>
                <div class="media-content">
                  <h2 class="is-size-6">Durasi</h2>
                  <p class="is-size-6">Tetap (Permanen)</p>
                </div>
              </div>
            ) : null}
            <div class="media">
              <div class="media-left">
                <div class="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faCalendar} size="lg" />
                </div>
              </div>
              <div class="media-content">
                <h2 class="is-size-6">Check-in</h2>
                <p class="is-size-6">{finalCheckInDate}</p>
              </div>
            </div>
            <p />
            {this.state.staying_type === 1 ? (
              <div class="media">
                <div class="media-left">
                  <div class="icon is-small is-left has-text-primary">
                    <FontAwesomeIcon icon={faCalendar} size="lg" />
                  </div>
                </div>
                <div class="media-content">
                  <h2 class="is-size-6">Check-out</h2>
                  <p class="is-size-6">{finalCheckOutDate}</p>
                </div>
              </div>
            ) : this.state.staying_type === 2 ? null : null}
            <p />
            <div class="media">
              <div class="media-left">
                <div class="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faStickyNote} size="lg" />
                </div>
              </div>
              <div class="media-content">
                <h2 class="is-size-6">Catatan Tambahan</h2>
                <p class="is-size-6">{this.state.notes}</p>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default FormBookMess;
