import React from "react";
import { useState } from "react";
import {
  Center,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import AppFilter from "../../../components/history/AppFilter";
import AddReportForm from "../../../components/Report/AddReportForm";
import ReportItem from "../../../components/Report/ReportItem";
import Header from "../../../components/Header";
import BottomNavbar from "../../../components/Navbars/BottomNavbar";
import Axios from "../../../utils/axiosService";
import { useEffect } from "react";

function ReportView() {
  const [selectedAppId, setSelectedAppId] = useState();
  const [reportData, setReportData] = useState([1, 2, 3]);

  const [apps, setApps] = useState([]);

  useEffect(() => {
    Axios({
      method: "GET",
      url: "/app?status=true",
    }).then((r) => {
      setApps(r.data.data.data);
      setSelectedAppId(r.data.data.data[0].id);
    });
  }, []);

  useEffect(() => {
    setReportData([])
    if (selectedAppId !== undefined) {
      Axios({
        method: "GET",
        url: `/issue-report?app_id=${selectedAppId}&user_id=${parseInt(localStorage.getItem("id_user"))}`,
      }).then((r) => {
        setReportData(r.data.data.data);
      });
    }
  }, [selectedAppId]);

  return (
    <>
      <Header title={"Report"} />
      <Flex p="16px" flexDirection="column" gap="10px">
        <AppFilter
          selectedAppId={selectedAppId}
          setSelectedAppId={setSelectedAppId}
          apps={apps}
        />
        <Tabs isFitted>
          <TabList>
            <Tab
              borderBottomWidth="5px"
              _selected={{
                color: "#006F95",
                borderBottomColor: "#006F95",
                fontWeight: "700",
              }}
            >
              My Report
            </Tab>
            <Tab
              borderBottomWidth="5px"
              _selected={{
                color: "#006F95",
                borderBottomColor: "#006F95",
                fontWeight: "700",
              }}
            >
              Add Report
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {apps.length > 0 &&
                reportData.map((r) => {
                  const app = apps.filter((a) => a?.id === selectedAppId)[0];
                  return (
                    <ReportItem icon={app?.icon} color={app?.color} data={r} />
                  );
                })}
              {apps.length > 0 && reportData.length === 0 && (
                <Center mt="3em">
                  <Text>No report found.</Text>
                </Center>
              )}
            </TabPanel>
            <TabPanel>
              <AddReportForm apps={apps} selectedAppId={selectedAppId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <BottomNavbar />
    </>
  );
}

export default ReportView;
