import React from "react";

// react library for routing
import { Route, Switch } from "react-router-dom";

// core components
import IndexFooter from "../components/Footers/IndexFooter.jsx";
import routes from "../routes.js";

class Parastay extends React.Component {
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/parastay") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div className="main-content" ref="mainContent" style={{ flex: 1 }}>
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
        <IndexFooter />
      </div>
    );
  }
}

export default Parastay;
