import { Flex, Box, Image, Text } from "@chakra-ui/react";
import SadMascot from "../../../assets/img/bookingCar/mascot-14-sad.png";

const Inactive = () => {
  return (
    <Box width="100%" borderBottom="1px solid #EFEFEF">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="16px"
        padding="48px 32px"
      >
        <Image src={SadMascot} alt="Thanks" width="120px" height="120px" />
        <Flex direction="column" gap="4px">
          <Text
            fontSize="14px"
            fontWeight="500"
            color="#222222"
            textAlign="center"
          >
            Akun Anda sudah tidak aktif
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#5E5E5E"
            textAlign="center"
          >
            Mohon maaf Anda tidak dapat menggunakan aplikasi Paraspace
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Inactive;
