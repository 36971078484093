import Axios from "../utils/axiosService";
import moment from "moment/moment";

export async function FetchUpcoming({ type, limit }) {
  moment.locale("en");

  let dateNow = moment.utc().format("YYYY-MM-DD");

  const baseUrl = type === "car" ? "/car" : "/all";

  try {
    const res = await Axios.get(
      `${baseUrl}/booking?user_id=${parseInt(
        localStorage.getItem("id_user")
      )}&${
        limit ? `limit=${limit}&` : ""
      }status=Booking Created&start_usage_gte=${dateNow}&order_column=start_usage&order_sequence=ASC`
    );

    let logUpcomingData = res.data.data.data;

    if (type === "car") {
      logUpcomingData = logUpcomingData.map((data) => {
        return {
          ...data,
          booking_type: type,
        };
      });
    }

    return {
      upcomingData: logUpcomingData,
      loading: false,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching upcoming data:", err);
    return {
      upcomingData: [],
      loading: false,
      error: err,
    };
  }
}
