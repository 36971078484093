import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Text, Flex, Box, Button } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { FetchHistory } from "../../../api/fetchHistory";

import { Card, CardSkeleton } from "./Card";

function History({ type = "all" }) {
  const navigate = useHistory();

  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const slideSettings = {
    infinite: false,
    arrows: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1.8,
  };

  useEffect(() => {
    const fetchHistoryData = async ({ type }) => {
      const { historyData, loading, error } = await FetchHistory({
        type,
        limit: 10,
      });
      setHistoryData(historyData);
      setLoading(loading);
      setError(error);
    };

    if (type === "car") fetchHistoryData({ type: "car" });
    else fetchHistoryData({ type: "all" });
  }, [type]);

  if (error) {
    <Box padding="0 16px">
      <Box
        w="100%"
        padding="16px 8px 16px 8px"
        borderRadius="12px"
        bg="#EFEFEF"
      >
        <Text fontSize="14px" fontWeight="400" color="#5E5E5E">
          Error fetching bookings: {error.message}
        </Text>
      </Box>
    </Box>;
  }

  return (
    <Flex w="100%" color="#222222" spacing="0" direction="column" rowGap="8px">
      <Flex w="100%" padding="0 16px" justifyContent="space-between">
        <Text fontSize="14px" fontWeight="500">
          Riwayat
        </Text>
        {!loading && historyData.length > 0 && (
          <Button
            variant="link"
            color="#3182CE"
            fontSize="14px"
            fontWeight="400"
            _hover={{ textDecoration: "none" }}
            onClick={() => navigate.push("/history")}
          >
            Lihat Semua
            <ChevronRightIcon size="16px" />
          </Button>
        )}
      </Flex>

      {loading && (
        <Flex w="100%" padding="8px 0" direction="row" columnGap="16px">
          <CardSkeleton isFirstChild={true} />
          <CardSkeleton isLastChild={true} />
        </Flex>
      )}

      {!loading && historyData.length === 0 ? (
        <Box padding="0 16px">
          <Box
            w="100%"
            padding="16px 8px 16px 8px"
            borderRadius="12px"
            bg="#EFEFEF"
          >
            <Text fontSize="14px" fontWeight="400" color="#5E5E5E">
              Kamu belum memiliki riwayat booking{" "}
              {type === "car" ? "mobil" : ""}
            </Text>
          </Box>
        </Box>
      ) : historyData.length === 1 ? (
        <Box w="100%" margin="8px 24px">
          <Card type="history" data={historyData[0]} isSingle={true} />
        </Box>
      ) : (
        <Box
          w="100%"
          sx={{
            ".slick-track": {
              display: "flex",
              flexDirection: "row",
              columnGap: "24px",
              padding: "8px 0",
              marginLeft: "0",
            },
          }}
        >
          <div className="slider-container">
            <Slider {...slideSettings}>
              {historyData.map((history, i) => (
                <Card key={i} type="history" data={history} />
              ))}
            </Slider>
          </div>
        </Box>
      )}
    </Flex>
  );
}

export default History;
