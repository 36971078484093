import React from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Flex, Box, Text, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import MainHeader from "../../../../../components/Parastay/Header/MainHeader";
import styles from "./styles.module.css";
import AxiosParastay from "../../../../../utils/parastayAxiosService";

class RequestTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complaint_list: {
        data: [
          {
            complain_id: null,
            location_id_fk2: null,
            requestor_id: null,
            ga_admin_pic_id: null,
            crem_admin_pic_id: null,
            category_id_fk: null,
            sub_location: "",
            complain_desc: "",
            solution: "",
            complain_request_date: null,
            complain_start_date: null,
            complain_end_date: null,
            complain_status_id: null,
            priority: null,
            complain_respond_date: null,
            complain_updated_date: null,
            is_read: false,
          },
        ],
      },
      booking_list: {
        data: [
          {
            data_id: null,
            room_id_fk: null,
            user_id_fk: null,
            occupant_type: null,
            bring_family: false,
            state: null,
            request_date: null,
            start_date: null,
            end_date: null,
            notes: null,
          },
        ],
      },
      complaint_status: {
        data: [
          {
            comp_status_id: null,
            comp_status_name: null,
            comp_status_desc: null,
          },
        ],
      },
      isLoading: true,
      isEmpty: false,
      count1: null,
      count2: null,
    };
  }

  async componentDidMount() {
    Swal.fire({
      title: "Loading your Requests...",
      html: "Please wait.",
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    Swal.showLoading();

    const idUser = localStorage.getItem("id_user_parastay");

    AxiosParastay({
      method: "GET",
      url: `/UserComplainList?requestor_id=${idUser}`,
    })
      .then((result) => {
        this.setState({
          complaint_list: result.data,
          isLoading: false,
          count1: 1,
        });
        Swal.close();
      })
      .catch((error) => {
        this.setState({
          count1: 0,
        });
        Swal.close();
      });

    AxiosParastay({
      method: "GET",
      url: `/UserBookingList?user_id_fk=${idUser}`,
    })
      .then((result) => {
        this.setState({
          booking_list: result.data,
          count2: 1,
        });
        Swal.close();
      })
      .catch((error) => {
        this.setState({
          count2: 0,
        });
        Swal.close();
      });

    if (this.state.count1 + this.state.count2 < 1) {
      // do nothing
    } else {
      Swal.fire({
        icon: "error",
        text: "Anda belum melakukan Booking atau Komplain",
        confirmButtonColor: "blue",
        confirmButtonText: `OK`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.assign("../");
        }
      });
    }
  }

  ComplaintTextStatus(key) {
    let printStatus;
    switch (key) {
      case 1:
        printStatus = "Complaint submitted";
        break;
      case 2:
        printStatus = "Assignment Process";
        break;
      case 3:
        printStatus = "Complaint Picked";
        break;
      case 4:
        printStatus = "Complaint On Progress";
        break;
      case 5:
        printStatus = "Complaint Done";
        break;
      case 6:
        printStatus = "Complaint Declined";
        break;
      case 7:
        printStatus = "Complaint Postponed";
        break;
      default:
        break;
    }

    return printStatus;
  }

  ComplaintCard() {
    var complaint_list = this.state.complaint_list.data;
    return complaint_list[0].complain_id === null
      ? null
      : Object.keys(complaint_list).map((ComplaintIndex) => {
          if (
            this.state.complaint_list.data[ComplaintIndex]
              .complain_request_date !== undefined
          ) {
            var options = {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            };
            let ComplaintRequestDate = new Date(
              this.state.complaint_list.data[
                ComplaintIndex
              ].complain_request_date
            );

            return (
              <Flex
                bgColor="#EAF9FF"
                padding="1em"
                marginBottom="1em"
                borderRadius="10px"
              >
                <Box width="50%">
                  <div class="content">
                    <Text fontSize="18px" fontWeight="600" textAlign="left">
                      Komplain Mess #
                      {
                        this.state.complaint_list.data[ComplaintIndex]
                          .complain_id
                      }
                    </Text>
                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      textAlign="left"
                      textColor="#1AAA0D"
                    >
                      {this.ComplaintTextStatus(
                        this.state.complaint_list.data[ComplaintIndex]
                          .complain_status_id
                      )}
                    </Text>
                  </div>
                </Box>
                <Box width="50%">
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    textAlign="right"
                    mt="6px"
                    height="70%"
                  >
                    {ComplaintRequestDate.toLocaleDateString("id", options)}
                  </Text>
                  <a
                    class="is-pulled-right is-fullwidth"
                    href={
                      "/parastay/complaint-track-view?complain_id=" +
                      this.state.complaint_list.data[ComplaintIndex].complain_id
                    }
                    onClick={this.handleClick}
                  >
                    <div class="icon is-small has-text-right has-text-primary">
                      <FontAwesomeIcon icon={faAngleRight} size="2x" />
                    </div>
                  </a>
                </Box>
              </Flex>
            );
          } else {
            return null;
          }
        });
  }

  BookingTextStatus(key) {
    let printStatus;
    switch (key) {
      case 1:
        printStatus = "Booking Submitted";
        break;
      case 2:
        printStatus = "Booking On Process";
        break;
      case 3:
        printStatus = "Booking On Waiting List";
        break;
      case 4:
        printStatus = "Accepted and Done";
        break;
      case 5:
        printStatus = "Booking Declined";
        break;
      case 6:
        printStatus = "Settled";
        break;
      case 7:
        printStatus = "Check Out Request Submitted";
        break;
      case 8:
        printStatus = "Move Out Request Submitted";
        break;
      case 9:
        printStatus = "Move Out Approved";
        break;
      case 10:
        printStatus = "Check Out Approved";
        break;
      default:
        break;
    }

    return printStatus;
  }

  BookingCard() {
    var booking_list = this.state.booking_list.data;
    return booking_list[0].data_id === null
      ? null
      : Object.keys(booking_list).map((BookingIndex) => {
          if (
            this.state.booking_list.data[BookingIndex].request_date !==
            undefined
          ) {
            var options = {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            };
            let BookingRequestDate = new Date(
              this.state.booking_list.data[BookingIndex].request_date
            );
            return (
              <Flex
                bgColor="#EAF9FF"
                padding="1em"
                marginBottom="1em"
                borderRadius="10px"
              >
                <Box width="50%">
                  <div class="content">
                    <Text fontSize="18px" fontWeight="600">
                      Request Mess #
                      {this.state.booking_list.data[BookingIndex].data_id}
                    </Text>
                    <Text fontSize="14px" fontWeight="500" textColor="#1AAA0D">
                      {this.BookingTextStatus(
                        this.state.booking_list.data[BookingIndex]
                          .booking_status_id_fk
                      )}
                    </Text>
                  </div>
                </Box>
                <Box width="50%">
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    textAlign="right"
                    mt="6px"
                    height="70%"
                  >
                    {BookingRequestDate.toLocaleDateString("id", options)}
                  </Text>
                  <a
                    class="is-pulled-right is-fullwidth"
                    href={
                      "/parastay/booking-track-view?booking_id=" +
                      this.state.booking_list.data[BookingIndex].data_id
                    }
                    onClick={this.handleClick}
                  >
                    <div class="icon is-small has-text-right has-text-primary">
                      <FontAwesomeIcon icon={faAngleRight} size="2x" />
                    </div>
                  </a>
                </Box>
              </Flex>
            );
          } else {
            return null;
          }
        });
  }

  render() {
    return (
      <>
        <Flex className={styles["header-container"]}>
          <MainHeader title="Request & Komplain" backLink={`/parastay?id=1`} />
        </Flex>
        <Flex className={styles["main-container"]}>
          <Tabs variant="soft-rounded" colorScheme="pink">
            <TabList ml="15px">
              <Tab>Request Mess</Tab>
              <Tab>Komplain Mess</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {this.BookingCard()}
              </TabPanel>
              <TabPanel>
                {this.ComplaintCard()}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </>
    );
  }
}

export default RequestTracker;
