import React from "react";
import { Swiper, SwiperSlide  } from "swiper/react";
import { Pagination, Autoplay } from 'swiper';
import { Center, Image } from "@chakra-ui/react";

import "swiper/css";
import 'swiper/css/pagination';

function ImageCarousel({ imageData }) {
  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination ]}
      >
        {imageData.length > 0 && imageData?.map((image) => {
          return (
            <SwiperSlide
              style={{
                width: "100%",
                height: "250px",
                color: "rgba(0, 0, 0, 0.7)",
              }}
            >
              <Center>
                <Image
                  src={image}
                />
              </Center>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default ImageCarousel;
