import Axios from "../utils/axiosService";
import moment from "moment/moment";

export async function FetchHistory({ type, limit }) {
  moment.locale("en");

  let dateNow = moment.utc().format("YYYY-MM-DD");

  const baseUrl = type === "car" ? "/car" : "/all";

  try {
    const res1 = await Axios.get(
      `${baseUrl}/booking?user_id=${parseInt(
        localStorage.getItem("id_user")
      )}&${
        limit ? `limit=${limit}&` : ""
      }status=Booking Created&start_usage_lt=${dateNow}&order_column=updated_at&order_sequence=DESC`
    );

    const res2 = await Axios.get(
      `${baseUrl}/booking?user_id=${parseInt(
        localStorage.getItem("id_user")
      )}&${
        limit ? `limit=${limit}&` : ""
      }status=Booking Cancelled, In Progress, Done&order_column=updated_at&order_sequence=DESC`
    );

    let logHistoryData = [...res1.data.data.data, ...res2.data.data.data];

    if (type === "car") {
      logHistoryData = logHistoryData.map((data) => {
        return {
          ...data,
          booking_type: type,
        };
      });
    }

    // Sort the occurrences by start_usage time
    logHistoryData = logHistoryData.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    );

    return {
      historyData: logHistoryData,
      loading: false,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching history data:", err);
    return {
      historyData: [],
      loading: false,
      error: err,
    };
  }
}
