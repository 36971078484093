import React from "react";

import { IconButton } from "@chakra-ui/react";
import ArrowLeftIcon from "./Icons/ArrowLeftIcon";
import { Link } from "react-router-dom";

function ButtonBack() {
  return (
    <Link to="/">
      <IconButton variant="none" icon={<ArrowLeftIcon />} />
    </Link>
  );
}

export default ButtonBack;
