import React from "react";
import { useIsDesktop } from "../../../../../../hooks/useIsDesktop";

import {
  Text,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";

const UserDescModal = ({ isOpen, onClose }) => {
  const isDesktop = useIsDesktop();
  const btnRef = React.useRef();

  const ActionModal = () => {
    return (
      <Flex alignItems="center">
        <Button
          fontSize={"14px"}
          bg="none"
          color="#184973"
          h="fit-content"
          padding="6px 16px"
          borderRadius="100px"
          fontWeight={400}
          onClick={() => {
            onClose();
          }}
          letterSpacing="0.1px"
        >
          Tutup
        </Button>
      </Flex>
    );
  };

  return (
    <>
      {isDesktop ? (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent color="black" zIndex={99}>
            <ModalHeader>Daftar Pengguna Mobil</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize="12px" fontWeight="400">
                Daftar Paragonian yang menggunakan mobil ini hingga 1 bulan ke
                depan.
              </Text>
            </ModalBody>

            <ModalFooter>
              <ActionModal />
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          isOpen={isOpen}
          placement="bottom"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent
            bg="white"
            borderTopRadius="28px"
            padding="32px 16px 24px 16px"
          >
            <DrawerCloseButton />
            <DrawerHeader
              py="12px"
              fontSize="14px"
              fontWeight="500"
              lineHeight="20px"
              px="0px"
              borderBottom="1px"
              borderColor="#5E5E5E"
            >
              Daftar Pengguna Mobil
            </DrawerHeader>

            <DrawerBody padding="0px" mt="16px">
              <Text fontSize="12px" fontWeight="400">
                Daftar Paragonian yang menggunakan mobil ini hingga 1 bulan ke
                depan.
              </Text>
            </DrawerBody>

            <DrawerFooter px="0px" mt="38px">
              <ActionModal />
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default UserDescModal;
