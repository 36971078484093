import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FetchCarList } from "../../../api/fetchCarList";
import ListCarWithLabel from "../../Shared/Booking-Car/ListCarWithLabel";
import NoCarsAvailablePlaceholder from "../../Shared/Booking-Car/NoCarsAvailablePlaceholder";

const getListCar = ({ listCar = [] }) => {
  const available = [];
  const noAvailable = [];
  listCar.forEach((car) => {
    if (car.car_status === "available") {
      available.push(car);
    } else {
      noAvailable.push(car);
    }
  });
  return {
    available,
    noAvailable,
  };
};

const ListCar = ({ params, search }) => {
  const [car, setCar] = useState({ available: [], noAvailable: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const setListAvailableCar = async () => {
      if (!search) {
        return;
      }
      setLoading(true);
      try {
        const response = await FetchCarList({ params });
        if (response?.error === null) {
          const { available, noAvailable } = getListCar({
            listCar: response.carList,
          });
          // Sort available cars by car_status
          noAvailable.sort((a, b) => a.car_status.localeCompare(b.car_status))
          setCar({ available, noAvailable });
        }
        setLoading(response.loading);
      } catch (error) {
        setLoading(false);
      }
    };

    setListAvailableCar();
  }, [params, search]);

  return (
    <Box paddingX="16px" pb="24px">
      {!car.available.length && !car.noAvailable.length && !loading && (
        <Text textAlign="center" mb={24}>
          Car Not Available
        </Text>
      )}

      {loading ? (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <Box>
          {car.available.length >= 0 && (
            <ListCarWithLabel
              label="Tersedia"
              loading={loading}
              cars={car.available}
              emptyPlaceholder={NoCarsAvailablePlaceholder}
              pageType="byDate"
            />
          )}
          {car.noAvailable.length > 0 && (
            <ListCarWithLabel
              label="Tidak Tersedia"
              loading={loading}
              cars={car.noAvailable}
              pageType="byDate"
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ListCar;
