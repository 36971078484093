import React, {useEffect, useState} from "react";
import { LinkBox, LinkOverlay, Box, Flex, Text, Center} from "@chakra-ui/react";
import moment from "moment/moment";
import 'moment-timezone';
import "moment/locale/id";
import styles from "./contactus.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function ContactUsCard({data}) {
  const [contactUsData, setContactUsData] = useState(data);
  moment.locale("en");

  useEffect(() => {
    let tempData = contactUsData;
    if (tempData.contact_type === "Phone") {
      tempData.contact_number = `tel:+62${tempData.contact_number}`
    } else if (tempData.contact_type === "WhatsApp") {
      tempData.contact_number = `+62${tempData.contact_number}`
    }
    setContactUsData(tempData)
  }, [data.contact_type, contactUsData])

  return (
    <LinkBox>
      <Flex 
        className={styles['contact-us-card']}
        style={contactUsData.contact_link ? {cursor: "pointer"} : {cursor: "none"}}
      >
        <Center width="8%">
          {data?.contact_type === "WhatsApp" ?
            <FontAwesomeIcon icon={faWhatsapp} size="xl"/>
          :
            <FontAwesomeIcon icon={faPhone} size="xl"/>
          }
        </Center>
        <Box textAlign="left" width="70%">
          <Text className={styles['contact-us-contact-name']}>
            {contactUsData?.contact_name}
          </Text>
          <Text className={styles['contact-us-contact-number']}>
            {data?.contact_number}
          </Text>
        </Box>
        <Box
          fontSize="12px"
          width="25%"
          color="rgba(0, 0, 0, 0.7)"
        >
          { contactUsData.contact_link && 
            <LinkOverlay
              href={contactUsData?.contact_link}
              target="_blank"
            />
          }
        </Box>
      </Flex>
    </LinkBox>
  );
}

export default ContactUsCard;
