import React from "react";
import { Box, IconButton, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import Axios from "../../../utils/axiosService";
import ButtonBack from "../../ButtonBack";

function MainHeader({title, backLink}) {
  const navigate = useHistory();
  const { search } = useLocation();
  const [appData, setAppData] = useState({});

  const logoutHandler = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("region_user");
    localStorage.removeItem("role_user");
    localStorage.removeItem("username");
    localStorage.removeItem("id_user");
    localStorage.removeItem("id_user_parastay")
    localStorage.removeItem("user_data")
    localStorage.removeItem("paraspaceToken");
    window.location.assign("/auth/login");
  };

  useEffect(() => {
    const id = localStorage.getItem('appId')

    Axios({
      method: "GET",
      url: `/app/${id}`,
    })
      .then((r) => setAppData(r.data.data.data))
      .catch((errorMsg) => {
        if (errorMsg.response) {
          if (errorMsg.response.status === 401) {
            logoutHandler();
          }
        }
      });
  }, [search]);

  return (
    <Box
      className="is-flex is-full-widescreen"
      style={{
        background: '#FFF',
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        color: "rgba(0, 0, 0, 0.7)",
        fontSize: "20px",
        boxShadow: "0px 1px 21px -1px rgba(0, 0, 0, 0.25)",
        padding: "24px 0",
      }}
    >
      <Box position="absolute" left="5">
        { backLink &&
          <IconButton
            icon={<ButtonBack />}
            onClick={() => navigate.push(backLink)}
            colorScheme='white'
          />
        }
      </Box>
      <Box
        style={{
          color: appData.color
        }}
      >
        <i className={appData.icon}></i>
      </Box>
      <Text marginLeft="0.25em" fontSize="18px" fontWeight="600">{title}</Text>
    </Box>
  );
}

export default MainHeader;
