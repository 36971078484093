import React from "react";

function NotificationSolidIcon() {
  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33333 11.5342C4.33254 9.73353 4.89267 7.97729 5.93588 6.50955C6.97908 5.04181 8.45357 3.93546 10.1544 3.34423C10.0886 2.93157 10.113 2.50953 10.2261 2.10723C10.3392 1.70493 10.5382 1.33195 10.8094 1.01402C11.0806 0.696097 11.4175 0.440793 11.797 0.265727C12.1764 0.0906607 12.5893 0 13.0072 0C13.4251 0 13.838 0.0906607 14.2175 0.265727C14.5969 0.440793 14.9339 0.696097 15.2051 1.01402C15.4763 1.33195 15.6753 1.70493 15.7884 2.10723C15.9014 2.50953 15.9259 2.93157 15.86 3.34423C17.5582 3.93789 19.0295 5.04527 20.07 6.51281C21.1105 7.98034 21.6685 9.73528 21.6667 11.5342V20.2009L26 23.0898V24.5342H0V23.0898L4.33333 20.2009V11.5342ZM15.8889 25.9787C15.8889 26.7449 15.5845 27.4797 15.0428 28.0214C14.501 28.5632 13.7662 28.8676 13 28.8676C12.2338 28.8676 11.499 28.5632 10.9572 28.0214C10.4155 27.4797 10.1111 26.7449 10.1111 25.9787H15.8889Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default NotificationSolidIcon;
