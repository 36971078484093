import React from "react";
import { Flex, Select, Text } from "@chakra-ui/react";


function AppFilter({ selectedAppId, setSelectedAppId, apps }) {

  return (
    <Flex gap="8px" alignItems="center">
      <Text fontSize="12px" fontWeight="bold" color="rgba(0, 0, 0, 0.7)">
        Select App
      </Text>
      <Select
        name="selected-app"
        value={selectedAppId}
        onChange={(e) => {
          e.preventDefault();
          setSelectedAppId(e.target.value);
        }}
        width="max-content"
        p='3px'
        fontSize='12px'
        borderRadius='5px'
        size='sm'
      >
        {apps && apps.length>0 && apps.map((app, index) => {
          return (
            <option key={index} value={app.id} defaultValue={selectedAppId}>
              <i className={app.icon}></i>
              {app.name}
            </option>
          );
        })}
      </Select>
    </Flex>
  );
}

export default AppFilter;
