import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Text, Image } from "@chakra-ui/react";
import defaultMeetingRoom from "../../assets/img/defaultMeetingRoom.png"

function RoomItem({ room }) {
  return (
    <Link to={`/booking-room/room/${room?.id}`}>
      <Flex
        gap="20px"
        alignItems="center"
        color="rgba(0, 0, 0, 0.8)"
        bgColor="#EAF9FF"
        padding="0.5em"
        borderRadius="10px"
      >
        <Image
          width="60px"
          height="60px"
          backgroundPosition="center center"
          backgroundRepeat= "no-repeat"
          borderRadius="50%"
          alt={room?.name}
          src={
            room?.image || defaultMeetingRoom
          }
        />
        <Box>
          <Text as="h3" fontWeight="600" fontSize="14px">
            {room?.name}
          </Text>
          <Text fontSize="12px">
            Location: {room?.office_area?.name} | Capacity: {room?.capacity}
          </Text>
          <Text fontSize="12px">
            {room?.type}
          </Text>
          <Text fontSize="12px">{room?.location_detail}</Text>
        </Box>
      </Flex>
    </Link>
  );
}

export default RoomItem;
