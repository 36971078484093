export const Session_Availalbe_Car = "bookingFormFilterData";
export const Booking_Car_URL = "/booking-car/available-car";
export const ACTIVE_BUTTON_COLOR = "#184973";
export const CONTACT_LIST_DATA = {
    car: {
      contact_name: "Booking Car",
      contact_number: "+6287709255800",
    },
    room: {
      contact_name: "Booking Room",
      contact_number: "+6287709290892",
    },
    mess: {
      contact_name: "Booking Mess",
      contact_number: "+6287814046085",
    },
  };