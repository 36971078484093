import { Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
const HeaderBookingForm = ({ date, time }) => {
  return (
    <Flex
      padding="16px"
      alignItems="center"
      gap={4}
      bg="white"
      borderBottom="0.5px"
      borderColor="#dbdbdb"
      sha
    >
      <Link style={{ cursor: "pointer" }} to="/booking-car/list-car">
        <BsChevronLeft />
      </Link>
      <Heading fontSize="14px" fontWeight="400">
        Booking Mobil
      </Heading>
    </Flex>
  );
};

export default HeaderBookingForm;
