import { Button } from "@chakra-ui/react";
import React from "react";
import { useGetQueryParams } from "../../../hooks/useGetQueryParams";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useHistory } from "react-router-dom";
import {
  Booking_Car_URL,
  Session_Availalbe_Car,
} from "../../../utils/constant";
import { checkPlatNomor } from "../../../utils/checkPlatNomor";
const ACTIVE_BUTTON_COLOR = "#EBF3FF";

const FilterGanjilGenap = () => {
  const [, setAvailableCarFilter] = useLocalStorage(Session_Availalbe_Car, {});

  const { params } = useGetQueryParams();
  const btnRef = React.useRef();
  const { replace } = useHistory();
  const gage = checkPlatNomor(params.booking_date);

  const isActiveGage =
    params?.license_plate_type === "ganjil" ||
    params?.license_plate_type === "genap";
  return (
    <Button
      bg={ACTIVE_BUTTON_COLOR}
      color="#184973"
      padding="6px 10px"
      borderRadius="100px"
      h="fit-content"
      letterSpacing="0.1px"
      fontSize={12}
      display="flex"
      alignItems="center"
      fontWeight={400}
      ref={btnRef}
      onClick={() => {
        const filter = {
          ...params,
          license_plate_type: isActiveGage ? "" : gage,
        };
        setAvailableCarFilter(filter);
        replace({
          pathname: Booking_Car_URL,
          search: new URLSearchParams(filter).toString(),
        });
      }}
      variant={"ghost"}
    >
      Ganjil Genap: {isActiveGage ? "Aktif" : "Non Aktif"}
    </Button>
  );
};

export default FilterGanjilGenap;
