import { useState, useEffect } from "react";
import { Text, Flex, Box, CircularProgress } from "@chakra-ui/react";

import { FetchHistory } from "../../../api/fetchHistory";

import { Card } from "./Card";

import { hasDatePassed } from "../../../utils/formatDate";

function History() {
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHistoryData = async ({ type }) => {
      const { historyData, loading } = await FetchHistory({ type });
      let inProgressBookings = [];
      let otherBookings = [];

      historyData.forEach((item) => {
        if (
          item.booking_status === "In Progress" &&
          !hasDatePassed(item.start_usage)
        ) {
          inProgressBookings.push(item);
        } else {
          otherBookings.push(item);
        }
      });

      const combinedBookings = [...inProgressBookings, ...otherBookings];
      setHistoryData(combinedBookings);
      setIsLoading(loading);
    };
    fetchHistoryData({ type: "all" });
  }, []);

  return (
    <Flex
      bg="#F8FBFF"
      padding="24px 0 80px 0"
      flexDirection="column"
      minHeight="calc(100vh - 72px)"
    >
      {isLoading ? (
        <Flex
          width="100%"
          height="calc(100vh - 72px)"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress isIndeterminate color="#3182CE" />
        </Flex>
      ) : historyData.length === 0 ? (
        <Box padding="0 16px">
          <Box
            w="100%"
            padding="16px 8px 16px 8px"
            borderRadius="12px"
            bg="#EFEFEF"
          >
            <Text fontSize="14px" fontWeight="400" color="#5E5E5E">
              Kamu belum memiliki riwayat booking
            </Text>
          </Box>
        </Box>
      ) : (
        <Flex
          w="100%"
          direction="column"
          rowGap="16px"
          overflowX="hidden"
          overflowY="scroll"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          {historyData.map((history, i) => (
            <Card key={i} type="history" data={history} />
          ))}
        </Flex>
      )}
    </Flex>
  );
}

export default History;
