import React, { useEffect, useState } from "react";
import { FetchBookedCarUsers } from "../../../../../api/fetchBookedCarUsers";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Text,
  Flex,
  Box,
  CircularProgress,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";

import UserCard from "./UsersSection/UserCard";
import UserDescModal from "./UsersSection/UserDescModal";

const UsersSection = ({ id }) => {
  const [bookedCarUsersData, setBookedCarUsersData] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const {
    isOpen: isOpenDesc,
    onOpen: onOpenDesc,
    onClose: onCloseDesc,
  } = useDisclosure();

  const slideSettings = {
    infinite: false,
    arrows: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1.8,
  };

  useEffect(() => {
    const fetchBookedCarUsers = async () => {
      const { bookedCarUsersData, loading } = await FetchBookedCarUsers({
        id: id,
      });
      setBookedCarUsersData(bookedCarUsersData);
      setIsLoadingUsers(loading);
    };
    fetchBookedCarUsers();
  }, [id]);

  return (
    <Flex gap="12px" direction="column">
      <Flex gap="8px" direction="row" padding="0 16px">
        <Text fontSize="14px" fontWeight="500">
          Daftar Pengguna Mobil
        </Text>
        <Button
          bg="#F8FBFF"
          height="20px"
          width="20px"
          minWidth="20px"
          borderRadius="100%"
          padding="2px"
          cursor="pointer"
          onClick={onOpenDesc}
        >
          <MdInfoOutline color="#222222" size="16px" />
        </Button>
      </Flex>
      {isLoadingUsers ? (
        <Flex
          width="100%"
          height="300px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress isIndeterminate color="#3182CE" />
        </Flex>
      ) : bookedCarUsersData.length === 0 ? (
        <Box padding="0 16px">
          <Box
            w="100%"
            padding="16px 8px 16px 8px"
            borderRadius="12px"
            bg="#EFEFEF"
          >
            <Text fontSize="14px" fontWeight="400" color="#5E5E5E">
              Belum ada antrian penggunaan mobil ini
            </Text>
          </Box>
        </Box>
      ) : (
        <Box
          w="100%"
          sx={{
            ".slick-track": {
              display: "flex",
              flexDirection: "row",
              columnGap: "16px",
              padding: "8px 0",
              marginLeft: "0",
            },
          }}
        >
          <div className="slider-container">
            <Slider {...slideSettings}>
              {bookedCarUsersData.map((data, i) => (
                <UserCard
                  key={i}
                  name={data.user.user_name}
                  start_usage={data.start_usage}
                  finish_usage={data.finish_usage}
                />
              ))}
            </Slider>
          </div>
        </Box>
      )}

      <UserDescModal isOpen={isOpenDesc} onClose={onCloseDesc} />
    </Flex>
  );
};

export default UsersSection;
