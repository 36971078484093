import React from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from "@chakra-ui/react";
import moment from "moment/moment";
import DOMPurify from "dompurify";
import "moment-timezone";
import "moment/locale/id";

function TermsConditionsCard({ data }) {
  moment.locale("en");

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <AccordionItem
      key={`data no ${data.id}`}
      fontSize="12px"
      w="100%"
      color="rgba(0, 0, 0, 0.7)"
      fontFamily="Lato"
      style={{
        background: "rgba(97, 170, 174, 0.25)",
        borderRadius: "8px",
        marginTop: "12px",
        marginBottom: "18px",
        borderBottom: "0",
      }}
    >
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            <Text
              fontFamily="Lato"
              style={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                lineheight: "18px",
                alignItems: "left",
              }}
            >
              {data.content_title}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel
        pb={4}
        style={{
          background: "#fafafa",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <Text
          dangerouslySetInnerHTML={createMarkup(data.content_text)}
          fontFamily="Lato"
          fontSize="14px"
          fontWeight="500"
          padding="8px 12px"
        />
      </AccordionPanel>
    </AccordionItem>
  );
}

export default TermsConditionsCard;
