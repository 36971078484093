import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment/moment";
import 'moment-timezone';
import "moment/locale/id";

function ReportItem({ icon, color, data }) {
  moment.locale("en");

  let tempCreatedAt = moment.utc(data.created_at).tz('Asia/Jakarta');
  const [createdAt] = React.useState(tempCreatedAt.format("DD MMMM YYYY, HH:mm"));

  return (
    <Flex
      gap="16px"
      borderBottomWidth="2px"
      borderBottomColor="rgba(217, 217, 217, 1)"
      py="4px"
    >
      <Box color={color} fontSize="30px" h="30px">
        <i className={icon}></i>
      </Box>
      <Box fontSize="12px" w="100%" color="rgba(0, 0, 0, 0.7)">
        <Text as="h3" fontWeight="600">
          {data.subject_report}
        </Text>
        <Text>{data.report_details}</Text>
        <Text textAlign="right" fontSize="10px">
          {createdAt}
        </Text>
      </Box>
    </Flex>
  );
}

export default ReportItem;
