import React, { useEffect, useState } from "react";
import Axios from "../../utils/axiosService";
import { 
  Box,
  Heading,
  Flex,
  VStack,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Center,
  Text,
  Image
} from "@chakra-ui/react";
import EmptySchedule from '../../assets/img/empty-room-schedule.svg';
import NotificationItem from "./NotificationItem";

function NotificationList({ title }) {
  const [logData, setLogData] = useState([]);
  const userId = localStorage.getItem('id_user');
  // const userName = localStorage.getItem('username');
  const [loading, setLoading] = useState(true);
  const [cardColor, setCardColor] = useState("#FC9748");

  const skeletonAmt = [1,2,3,4,5,6,7]

  useEffect(() => {
    Axios({
      url: "/app?status=true",
      method: "GET",
    })
    .then((r) => {
      if (r.status === 200) {
        let tempAppData = r.data.data.data
        for (let index = 0; index < tempAppData.length; index++) {
          if (tempAppData[index].slug === 'booking-room') {
            Axios({
              method: "GET",
              url: `/room/log?user_id=${userId}&order_column=timestamp&order_sequence=DESC&limit=7`,
            }).then((res) => {
              setLoading(false);
              setLogData(res.data.data.data);
              setCardColor(tempAppData[index].color);
            });
          } else if (tempAppData[index]?.slug === 'booking-car') {
            setCardColor(tempAppData[index]?.color);
          } else if (tempAppData[index]?.slug === 'parastay') {
            setCardColor(tempAppData[index]?.color);
          }
        }
      }
    })
    .finally(() => {
      setLoading(false);
    });
  }, [userId])

  return (
    <Box w='100%'>
      <Heading fontSize={"16px"} fontWeight={700}>
        {title}
      </Heading>
      { loading ? skeletonAmt.map((skeleton, index) => {
        return (
          <Flex key={`${index}-${skeleton}`}w={"100%"} mb="3" mt={"1em"}>
            <Box w={"20%"}>
              <SkeletonCircle size='12' />
            </Box>
            <Spacer/>
            <Box w={"80%"}>
              <Skeleton borderRadius="10px" height='65px' />
            </Box>
          </Flex>
        )
      })
      :
        <VStack align="center" mt="1em" mb="1em" w="100%" gap='6px'>
          {logData.length > 0 ? logData.map((data,index) => {
            return (
              <Box key={`No. ${index}`} w={"100%"}>
                <NotificationItem
                  locationData={data}
                  bookingStatus={data.status}
                  bookingLogTimestamp={data.timestamp}
                  cardColor={cardColor}
                />
              </Box>
            )
          }) :
          <>
            <Center mt="2em">
              <Image src={EmptySchedule} height={"250px"} />
            </Center> 
            <Center pb={"2em"}>
              <Text>You have no Notifications</Text>
            </Center>
          </>
          }
        </VStack>
      }
    </Box>
  );
}

export default NotificationList;
