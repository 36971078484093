import React from "react";
import IndexHeader from "../../../components/Headers/IndexHeader.jsx";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faHotel,
  faSignOutAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Center, IconButton, Text } from "@chakra-ui/react";
import Header from "../../../components/Headers/Header.jsx";
import AxiosParastay from "../../../utils/parastayAxiosService.js";

class ParastayView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_data: [
        {
          user_id: null,
          user_name: "",
          user_gender: "",
          room_name: null,
          location_name: null,
          work_location_id_fk: null,
        },
      ],
      isLoading: true
    };
    this.toggleHOModal = this.toggleHOModal.bind(this);
    this.toggleFactoryModal = this.toggleFactoryModal.bind(this);
  }

  componentDidMount() {
    const id_user = localStorage.getItem("id_user_parastay");

    AxiosParastay({
      method: "GET",
      url: `/UserHomePage?id_user=${id_user}`,
    }).then((response) => {
      this.setState({
        ...this.state,
        user_data: response.data.data[0],
        isLoading: false,
      });
      localStorage.setItem("mess_data", JSON.stringify(response.data.data[0]))
    });
  }

  toggleHOModal() {
    Swal.fire({
      icon: "warning",
      title: "Gunakan fitur ini hanya dalam keadaan darurat!",
      html: "<h2><b>Silahkan Pilih Contact Person:</b></h2>",
      confirmButtonColor: "blue",
      showCancelButton: true,
      confirmButtonText: "WPM Head Office",
      cancelButtonText: "CREM Head Office",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Membawa anda menuju WhatsApp...", "", "success");
        window.location.href = "https://wa.me/6285946870938";
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Membawa anda menuju WhatsApp...", "", "success");
        window.location.href = "https://wa.me/6282262524700";
      }
    });
  }

  toggleFactoryModal() {
    Swal.fire({
      icon: "warning",
      title: "Gunakan fitur ini hanya dalam keadaan darurat!",
      html: "<h2><b>Silahkan Pilih Contact Person:</b></h2>",
      confirmButtonColor: "blue",
      showCancelButton: true,
      confirmButtonText: "WPM Pabrik",
      cancelButtonText: "CREM Pabrik",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Membawa anda menuju WhatsApp...", "", "success");
        window.location.href = "https://wa.me/6287880000382";
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Membawa anda menuju WhatsApp...", "", "success");
        window.location.href = "https://wa.me/6285966183818";
      }
    });
  }

  render() {
    const username = this.state.user_data.user_name;
    const userID = this.state.user_data.user_id;
    const userRoom = this.state.user_data.room_name;
    const userGender = this.state.user_data.user_gender;
    return (
      <>
        <div className="App">
          <Header />
          <IndexHeader
            UserID={userID}
            UserRoom={userRoom}
            UserGender={userGender}
            UserName={username}
          />
          <Center background="#fff" margin="0 1.5em" display="block">
            <div class="container pb-3">
              <Text fontSize="18px" fontWeight="600">
                Perlu bantuan apa?
              </Text>
            </div>
            {!userRoom ? (
              <div class="container">
                <a
                  class="button is-large is-gomess-babyblue mb-5 mr-5 is-fullwidth"
                  href="/parastay/form-booking-mess"
                  onClick={this.handleClick}
                >
                  <span>
                    <FontAwesomeIcon icon={faHotel} size="md" />
                  </span>
                  <span>
                    <Text fontSize="20px" fontWeight="600">
                      &nbsp; Booking Mess
                    </Text>
                  </span>
                </a>
              </div>
            ) : (
              <div class="container">
                <a
                  class="button is-large is-gomess-lightpink mb-5 mr-5 is-fullwidth"
                  href="/parastay/form-moveout"
                  onClick={this.handleClick}
                >
                  <span>
                    <FontAwesomeIcon icon={faSignOutAlt} size="md" />
                  </span>
                  <span>
                    <Text fontSize="20px" fontWeight="600">
                      &nbsp; Pindah Mess
                    </Text>
                  </span>
                </a>
                <a
                  class="button is-large is-gomess-lightpink is-fullwidth"
                  href="/parastay/form-complaint"
                  onClick={this.handleClick}
                >
                  <span>
                    <FontAwesomeIcon icon={faExclamationCircle} size="md" />
                  </span>
                  <span>
                    <Text fontSize="20px" fontWeight="600">
                      &nbsp; Komplain Mess
                    </Text>
                  </span>
                </a>
              </div>
            )}
            {this.state.user_data.work_location_id_fk !== null ? (
              this.state.user_data.work_location_id_fk === "HEAD OFFICE" ? (
                <IconButton
                  onClick={this.toggleHOModal}
                  position="fixed"
                  bottom="20px"
                  right={["18px", "36px"]}
                  width="60px"
                  height="60px"
                  fontSize="22px"
                  zIndex={1}
                  background="#F5365C"
                  color="#FFF"
                  isRound={true}
                  icon={<FontAwesomeIcon icon={faPhone} />}
                />
              ) : this.state.user_data.work_location_id_fk === "FACTORY" ? (
                <IconButton
                  onClick={this.toggleFactoryModal}
                  position="fixed"
                  bottom="20px"
                  right={["18px", "36px"]}
                  width="60px"
                  height="60px"
                  fontSize="22px"
                  zIndex={1}
                  background="#F5365C"
                  color="#FFF"
                  isRound={true}
                  icon={<FontAwesomeIcon icon={faPhone} />}
                />
              ) : null
            ) : null}
          </Center>
        </div>
      </>
    );
  }
}

export default ParastayView;
