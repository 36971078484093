import { Divider, Flex } from "@chakra-ui/react";
import React from "react";
import HeaderCarList from "../../../../components/Car/HeaderCarList";
import ListCar from "../../../../components/Car/ListCar";
import { useGetQueryParams } from "../../../../hooks/useGetQueryParams";
import FilterSelectCapacity from "../../../../components/BookingCar/Component/FilterSelectCapacity";
import SearchCar from "../../../../components/Car/SearchCar";

const ListCarView = () => {
  const { params } = useGetQueryParams();
  return (
    <Flex gap="24px" flexDir="column" bg="#F8FBFF" minH="100vh">
      <HeaderCarList />
      <Flex flexDirection="column" padding="8px 16px 8px 16px" gap={"12px"}>
        <SearchCar params={params} />
        <FilterSelectCapacity params={params} />
      </Flex>

      <Divider backgroundColor="black" variant="dashed" />
      <ListCar params={params} />
    </Flex>
  );
};

export default ListCarView;
