import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { 
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import Axios from '../../../../../utils/axiosService';
import { useParams } from 'react-router-dom';
import MeetingRoomForm from './MeetingRoom';
import HallRoomForm from './HallRoom';

function FormBookingRoom() {

  moment.locale();

  const filterData = useState(JSON.parse(localStorage.getItem("filterRoom")));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isWeeklyMeeting, setIsWeeklyMeeting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [minTime, setMinTime] = useState(moment().format("HH:mm"));
  const [startDate, setStartDate] = useState((filterData && filterData[0]) && `${filterData[0]?.start}`)
  const [endDate, setEndDate] = useState((filterData && filterData[0]) && `${filterData[0]?.end}`)
  const dateNow = moment(new Date()).format("yyyy-MM-DD");
  const dateMax = moment(new Date()).add(31, "days").format('yyyy-MM-DD');
  const timeNow = moment().format('HH:mm');
  const toast = useToast();
   
  const [userDetail, setUserDetail] = useState();
  const allowedRoles = ["superadmin", "superuser"];

  const params = useParams();
  const [roomId] = useState(params.roomId);
  const [roomData, setRoomData] = useState([]);

  const [roomFormData, setRoomFormData] = useState({
    booking_purpose: '',
    user_name: localStorage.getItem("username"),
    user_id: localStorage.getItem("id_user"),
    user_contact: '',
    attendees: (filterData && filterData[0]) && filterData[0]?.capacity,
    booking_at: params.bookingDate ? params.bookingDate : ((filterData && filterData[0]) ? filterData[0]?.date : null),
    start_usage: (filterData && filterData[0]) && `${filterData[0]?.start}`,
    finish_usage: (filterData && filterData[0]) && `${filterData[0]?.end}`,
    isWeeklyMeeting: false,
    weeklyMeetingDeadline: null,
  });

  const [aulaFormData, setAulaFormData] = useState({
    booking_purpose: '',
    user_name: localStorage.getItem("username"),
    user_id: localStorage.getItem("id_user"),
    user_contact: '',
    attendees: (filterData && filterData[0]) && filterData[0]?.capacity,
    booking_at: params.bookingDate ? params.bookingDate : ((filterData && filterData[0]) ? filterData[0]?.date : null) ,
    start_usage: (filterData && filterData[0]) && `${filterData[0]?.start}`,
    finish_usage: (filterData && filterData[0]) && `${filterData[0]?.end}`,
    isWeeklyMeeting: false,
    weeklyMeetingDeadline: 0,
    aulaChosenLayout: '',
    selectedSeatOption: '',
    kursiKampusAmount: 0,
    kursiStandardAmount: 0,
    tableAmount: 0,
    chairPerTableAmount: 0,
    lesehanArea: '',
    aulaLayoutDetail: '',
    flipchartAmount: 0,
    whiteboardAmount: 0,
    squareTableAmount: 0,
    cableRollAmount: 0,
    additionalInformationAula: '',
  });

  const fetchUser = () => {
    let userData = JSON.parse(localStorage.getItem("user_data"));
    Axios({
      method: "GET",
      url: `/admin?user_id=${userData?.user_id}${
        userData?.user_email && `&user_email=${userData?.user_email}`
      }`,
    }).then((result) => {
      if (result.status === 200) {
        setUserDetail({ ...result.data.data.data[0] });
      }
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);
  
  useEffect(() => {
    Axios({
      method: "GET",
      url: `/room/${roomId}`,
    }).then((res) => {
      setRoomData(res.data.data.data);
    });
    setIsLoading(false);
  }, [roomId]);

  const checkAttendee = async(attendee) => {
    let data = {
      isMoreThanCapacity: false,
      isLowerThanZero: false
    }

    await Axios({
      method: "GET",
      url: `/room/${roomId}`,
    }).then(async (res) => {
      let resultData = res.data.data.data;
      if (resultData.capacity < attendee) {
        data.isMoreThanCapacity = !data.isMoreThanCapacity;
      }
    })

    if (attendee < 1) {
      data.isLowerThanZero = !data.isLowerThanZero;
    }

    return data;
  }

  const errorToast = (errResponse) => {
    setIsLoading(false);
    toast({
      title: 'Booking Failed.',
      description: errResponse,
      status: 'error',
      duration: 3500,
      isClosable: true,
      position: 'top',
      onCloseComplete: () => {
        onClose();
      }
    })
  }

  const handleSubmissionData = async (formData) => {
    setIsLoading(true);

    let tempFormData = formData;
    let Difference_In_Days;
    let count;

    if (formData.weeklyMeetingDeadline) {
      let date1 = new Date(formData.weeklyMeetingDeadline);
      let date2 = new Date(formData.booking_at);
    
      let Difference_In_Time = date1.getTime() - date2.getTime();
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      let tempCount = Math.round(Difference_In_Days / 7);
      count = tempCount + 1;
    } else {
      count = 1;
    }

    await Axios({
      method: "GET",
      url: `/user?user_name=${tempFormData.user_name}`,
    }).then(async (res) => {
      tempFormData["user_id"] = res.data.data.data[0].user_id;

      let mainBookingData = {
        "room_id": parseInt(roomData.id),
        "status": "Booking Created",
        "user_id": parseInt(tempFormData.user_id),
        "user_contact": tempFormData.user_contact,
        "booking_purpose": tempFormData.booking_purpose,
        "is_repeat": tempFormData.isWeeklyMeeting && count > 1,
        "period_repeat": (count > 1) ? "Weekly" : "Once",
        "count_repeat": count,
        "attendees_amount" : parseInt(tempFormData.attendees)
      }

      const attendee = parseInt(tempFormData.attendees);
      let checkAttendeeData = await checkAttendee(attendee);

      if (checkAttendeeData.isMoreThanCapacity) {
        errorToast("The amount of attendees exceeds the room's limit!");
      } else if (checkAttendeeData.isLowerThanZero) {
        errorToast("The amount of attendees cannot be less than 1!");
      } else if (mainBookingData['user_contact'].length < 8) {
        errorToast("Invalid phone number");
      } else {
        await Axios({
          method: "POST",
          url: `/room/booking`,
          data: mainBookingData,
        }).then(async (res) => {
          let bookingData = res.data.data.data;
          let submitOccurrenceAndLog = await setOccurrenceData(tempFormData, count, bookingData, Difference_In_Days);
          let submitAulaRequestData = true;

          if (roomData.type === "Aula") {
            submitAulaRequestData = await setAulaDetailsData(tempFormData, bookingData);
          }

          if (!submitOccurrenceAndLog.occurrenceStatus || !submitAulaRequestData) {
            if (roomData.type === "Aula") {
              await Axios({
                method: "DELETE",
                url: `/room/booking-occurrence-log-aula/${bookingData.id}`,
              }).then(async () => {
                setIsLoading(false);
              });
            } else {
              await Axios({
                method: "DELETE",
                url: `/room/booking-occurrence-log/${bookingData.id}`,
              }).then(async () => {
                setIsLoading(false);
              });
            }
          } else {
            toast({
              title: 'Booking Selesai',
              description: "Reservasi telah berhasil dibuat",
              status: 'success',
              duration: 3500,
              isClosable: true,
              position: 'top',
              onCloseComplete: () => {
                onClose();
                setIsLoading(false);
                localStorage.removeItem("filterRoom");
                window.location.assign("../../../notification");
              }
            })
          }
        }).catch((err) => {
          setIsLoading(false);
          errorHandling(err)
        });
      }
      
    }).catch((err) => {
      setIsLoading(false);
      errorHandling(err)
    });
  }

  const errorHandling = (err) => {
    let errResponse;

    if (err.response) {
      errResponse = err.response.data.errorType || err.response.data.errors[0].message;
    } else if (err.request) {
      errResponse = err.request.statusText;
    } else if (err.statusText) {
      errResponse = err.statusText;
    } else {
      errResponse = `Unexpected Error`;
    }

    errorToast(errResponse)
  }

  const checkBookingData1 = async (tempStartUsage, tempFinishUsage) => {
    let checkData = true;

    tempStartUsage = moment(tempStartUsage).format("YYYY-MM-DD HH:mm");
    tempFinishUsage = moment(tempFinishUsage).format("YYYY-MM-DD HH:mm");

    await Axios({
      method: "GET",
      url: `/room/occurance?room_id=${parseInt(roomData.id)}&status=Booking Created&start_usage_lt=${tempFinishUsage}&end_usage_gt=${tempStartUsage}`
    }).then(async (result) => {
      if (result.data.data.data.length > 0) {
        checkData = !checkData
      }
    }).catch(() => {
      checkData = !checkData
    })

    return checkData;
  }

  const checkBookingData2 = async (tempStartUsage, tempFinishUsage) => {
    let checkData = true;

    tempStartUsage = moment(tempStartUsage).format("YYYY-MM-DD HH:mm");
    tempFinishUsage = moment(tempFinishUsage).format("YYYY-MM-DD HH:mm");

    await Axios({
      method: "GET",
      url: `/room/occurance?room_id=${parseInt(roomData.id)}&status=Booking Created&start_usage_gt=${tempStartUsage}&start_usage_lt=${tempFinishUsage}`
    }).then(async (result) => {
      if (result.data.data.data.length > 0) {
        checkData = !checkData
      }
    }).catch(() => {
      checkData = !checkData
    })

    return checkData;
  }

  const checkBookingData3 = async (tempStartUsage, tempFinishUsage) => {
    let checkData = true;

    tempStartUsage = moment(tempStartUsage).format("YYYY-MM-DD HH:mm");
    tempFinishUsage = moment(tempFinishUsage).format("YYYY-MM-DD HH:mm");

    await Axios({
      method: "GET",
      url: `/room/occurance?room_id=${parseInt(roomData.id)}&status=Booking Created&start_usage_lte=${tempStartUsage}&end_usage_gte=${tempFinishUsage}`
    }).then(async (result) => {
      if (result.data.data.data.length > 0) {
        checkData = !checkData
      }
    }).catch(() => {
      checkData = !checkData
    })

    return checkData;
  }

  const checkBookingData4 = async (tempStartUsage, tempFinishUsage) => {
    let checkData = true;

    tempStartUsage = moment(tempStartUsage).format("YYYY-MM-DD HH:mm");
    tempFinishUsage = moment(tempFinishUsage).format("YYYY-MM-DD HH:mm");

    await Axios({
      method: "GET",
      url: `/room/occurance?room_id=${parseInt(roomData.id)}&status=Booking Created&start_usage_lt=${tempStartUsage}&end_usage_gt=${tempFinishUsage}`
    }).then(async (result) => {
      if (result.data.data.data.length > 0) {
        checkData = !checkData
      }
    }).catch(() => {
      checkData = !checkData
    })

    return checkData;
  }

  const setAulaDetailsData = async (tempFormData, bookingData) => {

    let aulaDataStatus = true;

    let aulaBookingData = {
      "booking_id": parseInt(bookingData.id),
      "aula_layout": tempFormData.aulaChosenLayout,
      "seat_option": tempFormData.selectedSeatOption,
      "kursi_kampus_amount": parseInt(tempFormData.kursiKampusAmount),
      "kursi_standard_amount": parseInt(tempFormData.kursiStandardAmount),
      "table_amount": parseInt(tempFormData.tableAmount),
      "chair_per_table_amount": parseInt(tempFormData.chairPerTableAmount),
      "lesehan_area": tempFormData.lesehanArea,
      "detail_aula_layout": tempFormData.aulaLayoutDetail,
      "flipchart_amount": parseInt(tempFormData.flipchartAmount),
      "whiteboard_amount": parseInt(tempFormData.whiteboardAmount),
      "square_table_amount": parseInt(tempFormData.squareTableAmount),
      "cable_roll_amount": parseInt(tempFormData.cableRollAmount),
      "additional_info_aula": tempFormData.additionalInformationAula,
    }

    await Axios({
      method: "POST",
      url: `/room/aula-request`,
      data: aulaBookingData
    }).catch((err) => {
      aulaDataStatus = !aulaDataStatus;
      setIsLoading(false);
      errorHandling(err)
    });

    return aulaDataStatus;
  }

  const setOccurrenceData = async (tempFormData, count, bookingData, Difference_In_Days) => {

    let occurrenceStatus = true;
    let occurrenceIds = [];
    let tempStartUsage;
    let tempFinishUsage;
    let isSubmitEarly = false;

    try {
      for (let i = 0; i < count; i++) {
        let dateCalculate = (count - 1) * 7;
        if (i === 0) {
          tempStartUsage = new Date(tempFormData.booking_at + ' ' + tempFormData.start_usage);
          tempFinishUsage = new Date(tempFormData.booking_at + ' ' + tempFormData.finish_usage);
        }
        else if (i === (count - 1)) {
          if (Difference_In_Days >= dateCalculate) {
            let dateStart = new Date(tempStartUsage);
            tempStartUsage = new Date(dateStart.getTime() + 604800000);
            let dateEnd = new Date(tempFinishUsage);
            tempFinishUsage = new Date(dateEnd.getTime() + 604800000);
          } else {
            isSubmitEarly = true;
            break;
          }
        }
        else {
          let dateStart = new Date(tempStartUsage);
          tempStartUsage = new Date(dateStart.getTime() + 604800000);
          let dateEnd = new Date(tempFinishUsage);
          tempFinishUsage = new Date(dateEnd.getTime() + 604800000);
        }

        if (tempStartUsage < tempFinishUsage) {
          let tempOccurrenceData = {
            "booking_id": parseInt(bookingData.id),
            "status": "Booking Created",
            "start_usage": tempStartUsage,
            "finish_usage": tempFinishUsage,
            "is_cancel": false,
            "cancelled_by": "",
            "cancel_timestamp": null
          }
  
          let checkData1 = await checkBookingData1(tempStartUsage, tempFinishUsage);
          let checkData2 = await checkBookingData2(tempStartUsage, tempFinishUsage);
          let checkData3 = await checkBookingData3(tempStartUsage, tempFinishUsage);
          let checkData4 = await checkBookingData4(tempStartUsage, tempFinishUsage);
  
          if (checkData1 && checkData2 && checkData3 && checkData4) {
            // occurrenceStatus = !occurrenceStatus;
            // setIsLoading(false);
            // errorToast("jadwal is clear!")
            
            await Axios({
              method: "POST",
              url: `/room/occurance`,
              data: tempOccurrenceData
            }).then(async(result) => {
  
              let tempBookingLog = {
                "booking_id": parseInt(bookingData.id),
                "occurance_id": parseInt(result.data.data.data.id),
                "status": "Booking Created",
                "user_id": parseInt(tempFormData.user_id),
                "timestamp": new Date(),
                "note": "Booking Created"
              }

              occurrenceIds.push(result.data.data.data.id)
    
              await Axios({
                method: "POST",
                url: `/room/log`,
                data: tempBookingLog
              }).catch((err) => {
                setIsLoading(false);
                errorHandling(err);
              });
            }).catch((err) => {
              setIsLoading(false);
              errorHandling(err)
            });
          } else {
            occurrenceStatus = !occurrenceStatus;
            setIsLoading(false);
            errorToast("Some of the schedules have been booked with other bookings")
            break;
          }
        } else {
          occurrenceStatus = !occurrenceStatus;
          setIsLoading(false);
          errorToast("Start date cannot be greater than end date!")
          break;
        }
      }
    } catch (err) {
      occurrenceStatus = !occurrenceStatus;
      setIsLoading(false);
      errorHandling(err);
    }

    return {
      "occurrenceStatus": (occurrenceStatus || isSubmitEarly),
      "occurrenceIds": occurrenceIds
    };
  }

  return (
    <>
      {roomData?.type === "Aula" ?
        <HallRoomForm
          toast={toast}
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          roomData={roomData}
          formData={aulaFormData}
          setFormData={setAulaFormData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dateNow={dateNow}
          dateMax={allowedRoles.includes(userDetail?.role?.toLowerCase()) ? null : dateMax}
          minTime={minTime}
          setMinTime={setMinTime}
          params={params}
          timeNow={timeNow}
          isWeeklyMeeting={isWeeklyMeeting}
          setIsWeeklyMeeting={setIsWeeklyMeeting}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleSubmissionData={handleSubmissionData}
          checkAttendee={checkAttendee}
          checkBookingData1={checkBookingData1}
          checkBookingData2={checkBookingData2}
          checkBookingData3={checkBookingData3}
          checkBookingData4={checkBookingData4}
        />
      :
        <MeetingRoomForm
          toast={toast}
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          roomData={roomData}
          formData={roomFormData}
          setFormData={setRoomFormData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dateNow={dateNow}
          dateMax={allowedRoles.includes(userDetail?.role?.toLowerCase()) ? null : dateMax}
          minTime={minTime}
          setMinTime={setMinTime}
          params={params}
          timeNow={timeNow}
          isWeeklyMeeting={isWeeklyMeeting}
          setIsWeeklyMeeting={setIsWeeklyMeeting}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleSubmissionData={handleSubmissionData}
        />
      }
    </>
  )
}

export default FormBookingRoom;