import React from "react";
import moment from "moment/moment";
import {
  Flex,
  Box,
  Text,
  Button,
  Input,
  CheckboxGroup,
  Checkbox,
  Center,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import MainHeader from "../../../../../../components/BookingRoom/Header/MainHeader";
import styles from "./meetingroom.module.css";

function MeetingRoomForm({
  toast,
  onClose,
  isOpen,
  onOpen,
  roomData,
  formData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minTime,
  setMinTime,
  dateNow,
  dateMax,
  params,
  timeNow,
  setIsWeeklyMeeting,
  isWeeklyMeeting,
  setIsLoading,
  isLoading,
  setFormData,
  handleSubmissionData,
}) {
  const handleInput = (event) => {
    let tempFormData = formData;
    tempFormData[event.target.name] = event.target.value;

    if (event.target.name === "isWeeklyMeeting") {
      setIsWeeklyMeeting(!isWeeklyMeeting);
      tempFormData["isWeeklyMeeting"] = !isWeeklyMeeting;
    }

    if (event.target.name === "booking_at") {
      setStartDate("");
      setEndDate("");
    }

    if (event.target.name === "start_usage") {
      if (tempFormData[`booking_at`] === dateNow) {
        if (event.target.value < minTime) {
          tempFormData["start_usage"] = timeNow;
          event.target.value = timeNow;
          setMinTime(event.target.value);
          setStartDate(timeNow);
        } else {
          setStartDate(event.target.value);
        }
      } else {
        setStartDate(event.target.value);
      }
    }

    if (event.target.name === "finish_usage") {
      if (tempFormData[`booking_at`] === dateNow) {
        if (
          event.target.value < minTime &&
          event.target.value < tempFormData["start_usage"]
        ) {
          tempFormData["finish_usage"] = moment()
            .add(1, "minutes")
            .format("HH:mm");
          event.target.value = moment().add(1, "minutes").format("HH:mm");
          setEndDate(tempFormData["finish_usage"]);
        } else {
          setEndDate(event.target.value);
        }
      } else {
        setEndDate(event.target.value);
      }
    }

    if (event.target.name === "booking_at") {
      if (event.target.value === dateNow && minTime < timeNow) {
        setMinTime(timeNow);
      }
    }

    setFormData(tempFormData);
  };

  const handleSubmit = () => {
    let tempLoadingState = !isLoading;
    setIsLoading(tempLoadingState);

    let EmptyFields = [];

    for (const property in formData) {
      if (
        !formData[property] &&
        !(
          property === "isWeeklyMeeting" || property === "weeklyMeetingDeadline"
        )
      ) {
        EmptyFields.push(property);
      }
    }

    if (EmptyFields.length > 0) {
      toast({
        title: "Booking Failed.",
        description: `There are ${EmptyFields.length} empty fields, please fill them out`,
        status: "error",
        isClosable: true,
        position: "top",
        onCloseComplete: () => {
          setIsLoading(!tempLoadingState);
          onClose();
        },
      });
    } else {
      handleSubmissionData(formData);
    }
  };

  moment.locale();

  return (
    <>
      <Flex className={styles["header-container"]}>
        <MainHeader
          title="Form Booking Room"
          backLink={`/booking-room/room/${roomData?.id}`}
        />
      </Flex>
      <Flex className={styles["main-container"]}>
        <Box>
          <Text className={styles["text-title"]}>
            Booking Form:
            <br />
            {roomData?.name}
          </Text>
        </Box>
        <Box>
          <Flex marginBottom={"0.25em"}>
            <Box width={"100%"}>
              <Flex className={styles["room-detail-info"]}>
                <Text className={styles["text-variable"]}>Meeting Subject</Text>
              </Flex>
              <Flex>
                <Input
                  onChange={handleInput}
                  variant="filled"
                  name="booking_purpose"
                />
              </Flex>
              <Flex className={styles["room-detail-info"]}>
                <Text className={styles["text-variable"]}>Booked by</Text>
              </Flex>
              <Flex>
                <Input
                  onChange={handleInput}
                  variant="filled"
                  name="user_name"
                  defaultValue={localStorage.getItem("username")}
                />
              </Flex>
              <Flex className={styles["room-detail-info"]}>
                <Text className={styles["text-variable"]}>Contact Number</Text>
              </Flex>
              <Flex>
                <Input
                  type="tel"
                  onChange={handleInput}
                  variant="filled"
                  name="user_contact"
                  minLength="8"
                />
              </Flex>
              <Flex className={styles["room-detail-info"]}>
                <Text className={styles["text-variable"]}>
                  Amount of Attendee
                </Text>
              </Flex>
              <Flex>
                <Input
                  onChange={handleInput}
                  placeholder={`Max Capacity: ${roomData.capacity}`}
                  type="tel"
                  variant="filled"
                  name="attendees"
                  defaultValue={formData?.attendees}
                />
              </Flex>
              <Flex className={styles["room-detail-info"]}>
                <Text className={styles["text-variable"]}>Booking Date</Text>
              </Flex>
              <Flex>
                <Input
                  onChange={handleInput}
                  type="date"
                  variant="filled"
                  name="booking_at"
                  min={dateNow}
                  max={dateMax}
                  defaultValue={params?.bookingDate}
                />
              </Flex>
            </Box>
          </Flex>
          <Flex marginBottom={"1em"}>
            <Box width={"45%"}>
              <Flex className={styles["room-detail-info"]}>
                <Text className={styles["text-variable"]}>Start</Text>
              </Flex>
              <Flex>
                <Input
                  onChange={handleInput}
                  type="time"
                  variant="filled"
                  name="start_usage"
                  min={dateNow}
                  value={startDate}
                  defaultValue={startDate}
                />
              </Flex>
            </Box>
            <Box width={"10%"} />
            <Box width={"45%"}>
              <Flex className={styles["room-detail-info"]}>
                <Text className={styles["text-variable"]}>End</Text>
              </Flex>
              <Flex>
                <Input
                  onChange={handleInput}
                  type="time"
                  variant="filled"
                  name="finish_usage"
                  min={startDate}
                  value={endDate}
                  defaultValue={endDate}
                />
              </Flex>
            </Box>
          </Flex>
          <Flex marginBottom={"1.25em"}>
            <Center width="100%">
              <CheckboxGroup>
                <Checkbox name="isWeeklyMeeting" onChange={handleInput}>
                  Weekly Meeting?
                </Checkbox>
              </CheckboxGroup>
              <Flex className={styles["room-detail-info"]}>
                <Center>
                  {isWeeklyMeeting && (
                    <>
                      <Text
                        className={styles["text-variable"]}
                        paddingRight="10px"
                      >
                        Until:
                      </Text>
                      <Input
                        onChange={handleInput}
                        min={dateNow}
                        max={dateMax}
                        name="weeklyMeetingDeadline"
                        type="date"
                        size="sm"
                        variant="filled"
                      />
                    </>
                  )}
                </Center>
              </Flex>
            </Center>
          </Flex>
          <Button
            className={styles["book-room-button"]}
            bg="#006F95"
            onClick={onOpen}
          >
            Create Booking
          </Button>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size={"xs"}>
        <ModalOverlay />
        <ModalContent className={styles["lato-font"]} borderRadius="25px">
          <ModalHeader className={styles["text-title-card"]} textAlign="center">
            Booking Confirmation:
            <br />
            {roomData?.name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box marginBottom={"1em"}>
              <Flex mb="-0.5em">
                <Box width={"35%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>Subject:</Text>
                  </Flex>
                </Box>
                <Box width={"65%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-value"]}>
                      {formData?.booking_purpose}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex mb="-0.5em">
                <Box width={"35%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>Booked by:</Text>
                  </Flex>
                </Box>
                <Box width={"65%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-value"]}>
                      {formData?.user_name}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex mb="-0.5em">
                <Box width={"35%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>Contact:</Text>
                  </Flex>
                </Box>
                <Box width={"65%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-value"]}>
                      {formData?.user_contact}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex mb="-0.5em">
                <Box width={"35%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>Attendee:</Text>
                  </Flex>
                </Box>
                <Box width={"65%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-value"]}>
                      {formData?.attendees}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex mb="-0.5em">
                <Box width={"35%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>Date:</Text>
                  </Flex>
                </Box>
                <Box width={"65%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-value"]}>
                      {moment(formData?.booking_at).format("DD MMMM YYYY")}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex mb="-0.5em">
                <Box width={"35%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>Time:</Text>
                  </Flex>
                </Box>
                <Box width={"65%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-value"]}>
                      {`${formData?.start_usage} - ${formData?.finish_usage}`}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex mb="-0.5em">
                <Box width={"35%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>Weekly:</Text>
                  </Flex>
                </Box>
                <Box width={"65%"}>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-value"]}>
                      {formData?.isWeeklyMeeting ? "Yes" : "No"}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              {isWeeklyMeeting && (
                <Flex mb="-0.5em">
                  <Box width={"35%"}>
                    <Flex className={styles["room-detail-info"]}>
                      <Text className={styles["text-variable"]}>Until:</Text>
                    </Flex>
                  </Box>
                  <Box width={"65%"}>
                    <Flex className={styles["room-detail-info"]}>
                      <Text className={styles["text-value"]}>
                        {formData?.weeklyMeetingDeadline &&
                          moment(formData?.weeklyMeetingDeadline).format(
                            "DD MMMM YYYY"
                          )}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            {isLoading ? (
              <Button isLoading colorScheme="blue" w="100%">
                Confirm Booking
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                bgColor="#006F95"
                color="#fff"
                w="100%"
              >
                Confirm Booking
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MeetingRoomForm;
