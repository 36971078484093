import { CloseIcon, Search2Icon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

const SearchCar = ({ params, loading }) => {
  const [input, setInput] = useState(params.query || "");
  const [debounce, setDebounce] = useState(params.query || "");
  const { push } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    const debounceFn = () => {
      if (debounce === input) {
        return;
      }
      if (debounce === "") {
        delete params.query;
        push({
          pathname,
          search: "?" + new URLSearchParams(params),
        });
        setInput("");
        return;
      }
      setInput(debounce);
      push({
        pathname,
        search: "?" + new URLSearchParams({ ...params, query: debounce }),
      });
    };

    const toDebounce = setTimeout(() => {
      debounceFn();
    }, 600);

    return () => {
      clearTimeout(toDebounce);
    };
  }, [debounce, input, params, pathname, push, setInput]);
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Search2Icon color="black" />
      </InputLeftElement>
      <Input
        value={debounce}
        disabled={loading}
        onChange={(e) => setDebounce(e.target.value)}
        borderRadius="8px"
        placeholder="Cari merek atau nomor kendaraan"
        _placeholder={{ color: "#5e5e5eS", fontSize: "14px" }}
        backgroundColor="white"
      />
      <InputRightElement
        onClick={() => {
          if (loading) {
            return;
          }
          setDebounce("");
        }}
      >
        <CloseIcon color="gray.400" />
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchCar;
