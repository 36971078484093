import { Flex, Box, Image, Button, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import SorryMascot from "../../../assets/img/bookingCar/mascot-16-sorry.png";

const NotRegistered = ({ status }) => {
  const navigate = useHistory();
  return (
    <Box width="100%" borderBottom="1px solid #EFEFEF">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="16px"
        padding="48px 32px"
      >
        <Image src={SorryMascot} alt="Sorry" width="120px" height="120px" />
        <Flex direction="column" gap="4px">
          <Text
            fontSize="14px"
            fontWeight="500"
            color="#222222"
            textAlign="center"
          >
            {status === "rejected"
              ? "Pendaftaran ditolak"
              : "Booking Mobil belum dapat digunakan"}
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#5E5E5E"
            textAlign="center"
          >
            {status === "rejected"
              ? "Mohon perbaiki data pendaftaran kamu"
              : "Daftarkan diri kamu untuk menggunakan Booking Mobil"}
          </Text>
        </Flex>
        <Button
          bg="#184973"
          color="white"
          width="fit-content"
          height="40px"
          borderRadius="100px"
          fontSize="14px"
          fontWeight="500"
          onClick={() => navigate.push("/booking-car/form-driver-request")}
        >
          Daftar Booking Mobil
        </Button>
      </Flex>
    </Box>
  );
};

export default NotRegistered;
