import React, {useState, useEffect} from "react";
import moment from "moment/moment";
import "moment/locale/id";
import Axios from "../../../../../utils/axiosService";
import styles from "./styles.module.css";
import {
  useToast,
  Text,
  Box
} from "@chakra-ui/react";

function CancelSeriesModal({ 
  setShowCancelSeries,
  bookingData,
  bookingOccurrenceData
}) {
  moment.locale("en");

  const [userName, setUserName] = useState('');
  const [cancelledData, setCancelledData] = useState({
    "is_cancel": null,
    "status": "",
    "cancel_timestamp": "",
    "cancelled_by": "",
    "cancel_reason": ""
  })

  const bookingId = bookingData?.id;
  const bookingRoomData = bookingData?.booking_room_master_datum;
  const bookingRoomAulaData = bookingData?.booking_room_booking_aula;

  const toast = useToast();

  useEffect(() => {
    Axios({
      method: "GET",
      url: `/user/${bookingData?.user_id}`,
    }).then(async (res) => {
      let name = res.data.data.data.user_name;
      setUserName(name)
    })
  }, [bookingData])

  const validateCancelData = (finalCancelledData) => {
    let statusValidation = {
      status: "success",
      message: "Booking data has been updated & cancelled",
    };

    if (finalCancelledData.cancel_reason) {
      if (finalCancelledData['cancel_reason'].length >= 255) {
        statusValidation.status = "error";
        statusValidation.message = "Cancellation reason too long";
      } else if (finalCancelledData['cancel_reason'].length < 10) {
        statusValidation.status = "error";
        statusValidation.message = "Cancellation reason too short";
      }
    } else {
      statusValidation.status = "error";
      statusValidation.message = "Please write the cancellation reason first";
    }

    return statusValidation;
  }

  const handleClickCancel = (e) => {
    e.preventDefault();
    
    let finalCancelledData = cancelledData;

    finalCancelledData.is_cancel = true
    finalCancelledData.status = "Booking Cancelled";
    finalCancelledData.cancel_timestamp = new Date();
    finalCancelledData.cancelled_by = localStorage.getItem('username');

    let cancelDataStatus = validateCancelData(finalCancelledData);

    if (cancelDataStatus.status === "success") {
      Axios({
        method: "PATCH",
        url: `/room/booking/${bookingId}/cancel-series`,
        data: finalCancelledData
      }).then(() => {
        toast({
          title: 'Booking Cancelled.',
          description: cancelDataStatus.message,
          status: cancelDataStatus.status,
          duration: 2000,
          isClosable: true,
          position: 'top',
          onCloseComplete: () => {
            setShowCancelSeries(false);
            window.location.reload();
          }
        })
      }).catch((error) => {
        toast({
          title: 'Error',
          description: `An error has occurred: ${error}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        })
      });
    } else {
      toast({
        title: 'Warning',
        description: cancelDataStatus.message,
        status: cancelDataStatus.status,
        duration: 3000,
        isClosable: true,
        position: 'top',
      })
    }
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    setShowCancelSeries(false);
  };

  const handleInput = (e) => {
    e.preventDefault();
    let tempCancelledData = cancelledData;
    tempCancelledData.cancel_reason = e.target.value;
    setCancelledData(tempCancelledData);
  };

  return (
    <div className={styles["container-overlay"]}>
      <div className={styles["container-app"]}>
        <img
          src="/warning.png"
          alt="warning"
          style={{ width: "50px", height: "50px" }}
        />
        <h2 className={styles["heading-main"]}>
          Cancel Booking {bookingRoomData?.type}:
          <br />
          {bookingRoomData?.name}
        </h2>
        <div className={styles["container-cancellation-data"]}>
          <p>Subject</p>
          <p>: {bookingData?.booking_purpose}</p>
          <p>Booked by</p>
          <p>: {userName}</p>
          <p>Contact</p>
          <p>: {bookingData?.user_contact}</p>
          <p>Attendee</p>
          <p>: {bookingData?.attendees_amount > 0 ? bookingData?.attendees_amount : 0}</p>
          <p>{bookingOccurrenceData?.length > 1 ? "Dates" : "Date" }</p>
          <p>{bookingOccurrenceData?.length > 1 ?
             <ul>
              {bookingOccurrenceData?.map((data, index) => {
                let date = moment.utc(data['start_usage'])
                return (
                  <li key={`nomor ${index}`}>: {date.format("dddd, DD MMMM YYYY")}</li>
                )
              })}
            </ul>
          : bookingOccurrenceData[0]?.start_usage}</p>
          <p>Hour</p>
          <p>: {moment(new Date(bookingOccurrenceData[0]?.start_usage)).format("HH:mm")} - {moment(new Date(bookingOccurrenceData[0]?.finish_usage)).format("HH:mm")}</p>
          {bookingData?.is_repeat ?
            <>
              <p>Weekly</p>
              <p>: Yes</p>
              <p>Until</p>
              <p>: {moment(new Date(bookingOccurrenceData[bookingOccurrenceData.length - 1]?.start_usage)).format("dddd, DD MMMM YYYY")}</p>
            </>
            :
            <>
              <p>Weekly</p>
              <p>: No</p>
            </>
          }
        </div>
        {(bookingData?.type === "Aula" && bookingRoomAulaData) && (
          <Box className={styles["body-aula-data"]}>
            <Text marginBottom="6px">Aula details</Text>
            <Box className={styles["container-aula-data"]}>
              <Text fontWeight={"bold"}>Layout</Text>
              <Text fontWeight={"bold"}>
                : {
                    bookingRoomAulaData.aula_layout === "uShape" ? "U Shape"
                    : bookingRoomAulaData.aula_layout === "classroom" ? "Classroom"
                    : bookingRoomAulaData.aula_layout === "roundTable" ? "Round Table"
                    : bookingRoomAulaData.aula_layout === "lesehan" ? "Lesehan"
                    : bookingRoomAulaData.aula_layout === "others" ? "Others"
                    : null
                  }
              </Text>
              {bookingRoomAulaData?.aula_layout === "uShape" || bookingRoomAulaData?.aula_layout === "classroom" ?
              <>
                <Text>Chair type</Text>
                <Text>: {bookingRoomAulaData?.seat_option}</Text>
                <Text>Chair amount</Text>
                <Text>: {bookingRoomAulaData?.kursi_kampus_amount > 0 
                      ? bookingRoomAulaData?.kursi_kampus_amount 
                      : bookingRoomAulaData?.kursi_standard_amount
                      }
                </Text>
              </>
              : bookingRoomAulaData?.aula_layout === "roundTable" ?
              <>
                <p>Table amount</p>
                <p>: {bookingRoomAulaData?.table_amount ? `${bookingRoomAulaData?.table_amount} pc(s)` : 0}</p>
                <p>Chair amount per table</p>
                <p>: {bookingRoomAulaData?.chair_per_table_amount ? `${bookingRoomAulaData?.chair_per_table_amount} pc(s)` : 0}</p>
              </>
              : bookingRoomAulaData?.aula_layout === "lesehan" ?
              <>
                <p>Lesehan area</p>
                <p>: {bookingRoomAulaData?.lesehan_area}</p>
              </>
              : bookingRoomAulaData?.aula_layout === "others" ?
              <>
                <p>Detailed layout</p>
                <p>: {bookingRoomAulaData?.detail_aula_layout}</p>
              </>
              : null}
              <Text>Flipcharts</Text>
              <Text>
                : {bookingRoomAulaData?.flipchart_amount ? `${bookingRoomAulaData?.flipchart_amount} pc(s)` : 0}
              </Text>
              <Text>Whiteboards</Text>
              <Text>
                : {bookingRoomAulaData?.whiteboard_amount ? `${bookingRoomAulaData?.whiteboard_amount} pc(s)` : 0}
              </Text>
              <Text>Square Tables</Text>
              <Text>
                : {bookingRoomAulaData?.square_table_amount ? `${bookingRoomAulaData?.square_table_amount} pc(s)` : 0}
              </Text>
              <Text>Extension Cables (Roll)</Text>
              <Text>
                : {bookingRoomAulaData?.cable_roll_amount ? `${bookingRoomAulaData?.cable_roll_amount} pc(s)` : 0}
              </Text>
            </Box>
          </Box>
        )}
        <Box className={styles["body-cancellation-reason"]}>
          <Text marginBottom="6px">Cancellation Reason<span style={{color: "red", fontSize: "14px"}}>*</span></Text>
          <textarea
            maxLength="255"
            minLength="10"
            placeholder="Tell the reason in 10-255 characters"
            name="cancel_reason"
            onChange={handleInput}
            className={styles["heading-cancellation-reason"]}
          />
        </Box>
        <p className={styles["body-cancellation-prompt"]}>
          Are you sure want to cancel this booking and its series ?
        </p>
        <div className={styles["container-cancel-buttons"]}
        >
          <buttons
            className={styles["button-submit-cancel"]}
            onClick={handleClickCancel}
          >
            Cancel
          </buttons>
          <buttons className={styles["button-abort-cancel"]} onClick={handleClickBack}>
            Back
          </buttons>
        </div>
      </div>
    </div>
  );
}

export default CancelSeriesModal;
