import React from "react";
import { Flex, Link, Text } from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";

function MenuItem({href, title, desc, icon, onClick}) {
  return (
    <RouteLink to={href ?? "#"} style={{ width: "100%" }}>
      <Link
        w="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth="2px"
        borderBottomColor="rgba(217, 217, 217, 1)"
        paddingY="12px"
        onClick={()=>{
          if(onClick) onClick();
        }}
      >
        <Flex gap="14px" color='rgba(0, 0, 0, 0.7)'>
          {icon}
          <Text fontSize="15px" fontWeight="700">
            {title}
          </Text>
        </Flex>
        <Text fontSize="11px" fontWeight="400">
          {desc}
        </Text>
      </Link>
    </RouteLink>
  );
}

export default MenuItem;
