import React from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../../components/SecondaryHeader";
import SummaryByDateSection from "../../../../components/BookingCar/MainCar/Summary/SummaryByDate";

function SummaryByDateView() {
  const location = useLocation();
  const { backLink } = location.state || { backLink: "/" };

  return (
    <>
      <Header type="car" title="Detail Mobil" backLink={backLink} />
      <SummaryByDateSection />
    </>
  );
}

export default SummaryByDateView;
