import { format } from "date-fns";
import Axios from "../utils/axiosService";

export async function FetchCarList({ params } = {}) {
  try {
    let url = `/car/list`;

    const result = [];

    Object.keys(params).forEach((key) => {
      if (params[key] !== "") {
        switch (key) {
          case "start_usage":
            result.push("time_start=" + decodeURIComponent(params[key]));
            break;
          case "finish_usage":
            result.push("time_end=" + decodeURIComponent(params[key]));
            break;
          case "purpose":
            break;
          case "booking_date":
            const formatDate = format(
              new Date(decodeURIComponent(params[key])),
              "yyy-MM-dd"
            );
            result.push(`booking_date=${formatDate}`);

            break;
          default:
            if (params[key] !== "null") {
              result.push(`${key}=` + decodeURIComponent(params[key]));
            }
            break;
        }
      }
    });

    if (result.length) {
      url = url + "?" + result.join("&");
    }

    const res = await Axios.get(url);

    return {
      carList: res.data.data.data,
      loading: false,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching car list:", err);
    return {
      carList: [],
      loading: false,
      error: err,
    };
  }
}
