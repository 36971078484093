import { Box, Flex, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import styles from "./style.module.css";
import LoginImg from "../../assets/img/splash.svg";
import { Line } from "./line";

const SplashLogin = () => {
  const [isShow, setIsShow] = useState(true);
  const [isImageShow, setIsImageShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsImageShow(true);
      setTimeout(() => {
        setIsShow(false);
      }, 2000);
    }, 2000);
  }, []);
  return (
    <Flex
      className={`${styles["splash-container"]} ${
        isShow ? styles["splash-show"] : styles["splash-hide"]
      }`}
    >
      <Box width="222px" position="relative">
        <Image
          width="100%"
          paddingBottom="12px"
          alt="Login"
          src={LoginImg}
          className={`${styles["image-container"]} ${
            isImageShow ? styles["splash-show"] : styles["splash-hide"]
          }`}
        />
        {isImageShow && (
          <Box width="fit-content" position="absolute" bottom="0" right="16px">
            <Line />
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default SplashLogin;
