import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import {
  MdFormatPaint,
  MdHomeRepairService,
  MdLocalGasStation,
  MdOutlineMiscellaneousServices,
  MdPerson,
} from "react-icons/md";

const CarAttributes = ({
  brand,
  name,
  car_status,
  license_plate,
  capacity,
  transmission,
  fuel,
  color,
  last_service,
  image,
  showCarStatus = false,
  showCarTransmisionColor = true,
}) => {
  return (
    <Flex gap="1rem" w="100%">
      <Flex gap="8px" direction="column">
        <Flex gap="8px" alignItems="center">
          <Text fontSize="14px" fontWeight="500">
            {brand} {name}
          </Text>
          {showCarStatus && car_status !== "available" && (
            <Flex
              color="white"
              h="fit-content"
              padding="2px 6px 2px 6px"
              borderRadius="10px"
              width="fit-content"
              fontSize="10px"
              bgColor={car_status === "booked" ? "#633B48" : "#B3261E"}
              fontWeight="400"
              textTransform="capitalize"
            >
              {car_status}
            </Flex>
          )}
        </Flex>
        <Flex
          color="#222222"
          fontSize="13px"
          fontWeight="400"
          direction="row"
          alignItems="center"
          gap="0.5rem"
        >
          <Flex
            fontSize="9px"
            bg="#222222"
            color="white"
            p="2px 4px"
            borderRadius="2px"
          >
            PLAT
          </Flex>
          <Flex>{license_plate}</Flex>
        </Flex>
        <Flex color="#5E5E5E" gap="24px" direction="row">
          <Flex gap="8px" direction="column">
            <Flex alignItems="center" gap="4px">
              <MdPerson size={16} />
              <Text fontSize="12px" fontWeight="400">
                {capacity} Orang
              </Text>
            </Flex>
            {showCarTransmisionColor && (
              <Flex alignItems="center" gap="4px">
                <MdOutlineMiscellaneousServices size={16} />
                <Text fontSize="12px" fontWeight="400">
                  {transmission || "-"}
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex gap="8px" direction="column">
            <Flex alignItems="center" gap="4px">
              <MdLocalGasStation size={16} />
              <Text fontSize="12px" fontWeight="400">
                {fuel || "-"}
              </Text>
            </Flex>
            {showCarTransmisionColor && (
              <Flex alignItems="center" gap="4px">
                <MdFormatPaint size={16} />
                <Text fontSize="12px" fontWeight="400">
                  {color || "-"}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center" color="#5E5E5E" gap="4px">
          <MdHomeRepairService size={16} />
          <Text fontSize="12px" fontWeight="400">
            Service terakhir: {last_service || "-"}
          </Text>
        </Flex>
      </Flex>
      {image ? (
        <Image
          src={image}
          alt="car"
          w="100px"
          h="fit-content"
          ml="auto"
          my="auto"
          maxH="100px"
          objectFit="cover"
          borderRadius="4px"
        />
      ) : (
        <Flex
          w="100px"
          h="100px"
          bg="#F8F8F8"
          borderRadius="4px"
          ml="auto"
          my="auto"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text
            textAlign="center"
            fontSize="10px"
            fontWeight="400"
            color="#5E5E5E"
          >
            Gambar mobil
          </Text>
          <Text
            textAlign="center"
            fontSize="10px"
            fontWeight="400"
            color="#5E5E5E"
          >
            tidak tersedia
          </Text>

        </Flex>
      )}
    </Flex>
  );
};

export default CarAttributes;
