import axios from "axios";

const baseURL = process.env.REACT_APP_PROD_URL ?? process.env.REACT_APP_DEV_URL;
const token = localStorage.getItem("paraspaceToken") || "";

const Axios = axios.create({
  baseURL: baseURL,
  headers: token
    ? {
        Authorization: "Bearer " + token,
      }
    : {},
});

export default Axios;
