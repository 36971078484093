import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import ChangeDate from "./ChangeDate";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import id from "date-fns/locale/id";
const HeaderAvailableCar = ({ date, time }) => {
  return (
    <Flex
      padding="16px"
      alignItems="center"
      gap={4}
      bg="white"
      borderBottom="0.5px"
      borderColor="dbdbdb"
    >
      <Link style={{ cursor: "pointer" }} to="/booking-car">
        <BsChevronLeft />
      </Link>
      <Box>
        <Text fontWeight={500} fontSize={14}>
          Cari Mobil
        </Text>
        <Flex gap={2} fontSize="12px">
          <Text>{date ? format(new Date(date), "dd MMMM yyyy ", { locale: id }) : "-"}</Text>
          <Text>|</Text>
          <Text>{time || "-"}</Text>
        </Flex>
      </Box>
      <Box marginLeft="auto">
        <ChangeDate />
      </Box>
    </Flex>
  );
};

export default HeaderAvailableCar;
