import { Box, Heading, IconButton } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import ButtonBack from "./ButtonBack";

function Header({ title, backLink }) {
  const navigate = useHistory();

  return (
    <Box
      className="is-flex is-full-widescreen"
      style={{
        background: "#FFF",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        color: "rgba(0, 0, 0, 0.7)",
        fontSize: "20px",
        boxShadow: "0px 1px 21px -1px rgba(0, 0, 0, 0.25)",
        padding: "24px 0",
      }}
    >
      <Box position="absolute" left="6">
        {backLink && (
          <IconButton
            icon={<ButtonBack />}
            onClick={() => navigate.push(backLink)}
            colorScheme="white"
          />
        )}
      </Box>
      <Heading fontSize={"22px"} fontWeight="bold">
        {title}
      </Heading>
    </Box>
  );
}

export default Header;
