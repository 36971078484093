import Axios from "../utils/axiosService";

export async function FetchCarDetail({ id, booking_date }) {
  try {
    let res;
    if (booking_date) {
      res = await Axios.get(`/car/${id}?booking_date=${booking_date}`);
    } else {
      res = await Axios.get(`/car/${id}`);
    }

    return {
      carDetail: res.data.data.data,
      loading: false,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching car detail:", err);
    return {
      carDetail: {},
      loading: false,
      error: err,
    };
  }
}
