import React from "react";

import Header from "../../../components/SecondaryHeader";
import Upcoming from "../../../components/UpcomingHistory/AllList/Upcoming";
import BottomNavbar from "../../../components/Navbars/BottomNavbar";

function UpcomingView() {
  return (
    <>
      <Header type="all" title="Mendatang" backLink="/" />
      <Upcoming />
      <BottomNavbar />
    </>
  );
}

export default UpcomingView;
