import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import MascottSeeYou from "../../../assets/img/bookingCar/mascot-06-seeyou.png";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function DepartureFormCompleted({ isOpen, onClose }) {
  const navigate = useHistory();
  const [countdown, setCountdown] = useState(7);

  useEffect(() => {
    let timer = null;
    if (isOpen) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isOpen]);

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = "/history";
    }
  }, [countdown, navigate]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%" padding="24px">
        <ModalBody padding="0">
          <Flex
            direction="column"
            gap="24px"
            width="100%"
            padding="16px"
            bg="#FFFFFF"
            borderRadius="12px"
          >
            <Flex direction="column" gap="10px" alignItems="center">
              <Image src={MascottSeeYou} alt="" w="86px" h="86px" />
              <Flex direction="column" gap="4px" alignItems="center" textAlign="center">
                <Text fontSize="14px" fontWeight="500">
                  Selamat Jalan~
                </Text>
                <Text fontSize="14px" fontWeight="400">
                  Patuhi rambu lalu lintas dan utamakan keselamatan ya! Kami menunggu kepulanganmu
                </Text>
              </Flex>
              <Flex
                minWidth="max-content"
                flexDirection={["column", "row"]}
                alignItems="center"
                gap={[2, 10]}
              >
                <Button
                  padding="6px 16px 6px 12px"
                  borderColor="#184973"
                  variant="outline"
                  borderRadius="100px"
                  gap="8px"
                  direction="row"
                  width="100%"
                  height="fit-content"
                  alignItems="center"
                  onClick={() => (window.location.href = "/history")}
                >
                  <Text fontSize="12px" fontWeight="400" color="#184973">
                    Tutup
                  </Text>
                  <Text fontSize="12px" fontWeight="400" color="#184973">
                    {countdown}
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DepartureFormCompleted;
