import React from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";

class IndexFooter extends React.Component {
  render() {
    return (
      <>
        <footer class="footer" style={{"flexShrink": 0}}>
          <div class="content has-text-centered">
            <p>
              <strong>
                <a href="/parastay/terms-conditions">Syarat dan Ketentuan</a>
              </strong>
            </p>
            <p>
              <strong>Parastay</strong>, brought to you by{" "}
              <a href="https://www.paragon-innovation.com/">
                Paragon Technology and Innovation
              </a>
              .
            </p>
          </div>
        </footer>
      </>
    );
  }
}

export default IndexFooter;
