import React from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Box, Spacer, Tag, Text } from "@chakra-ui/react";

class IndexHeader extends React.Component {
  render() {
    let ActivitiesPath = "/parastay/track-requests";
    return (
      <>
        <Box padding="1.5em" marginBottom="1.5em">
          {this.props.UserRoom === null || this.props.UserRoom === undefined ? (
            <div class="container">
              {this.props.UserGender === "PEREMPUAN" ? (
                <div class="container">
                  <div class="box px-5 mb-5 has-background-gomess-pink">
                    <article class="media">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img
                            src={require("../../assets/img/avatars/Female-avatar-1.png")}
                            alt="Female Paragonian"
                          />
                        </figure>
                      </div>
                      <div class="media-content">
                        <Text color="#fff">Halo,</Text>
                        <Text
                          fontSize="xl"
                          color="#000"
                          fontWeight="medium"
                          mb="0.25em"
                        >
                          {this.props.UserName}
                        </Text>
                        <Tag
                          background="#fff"
                          fontSize="12px"
                          variant="solid"
                          color="#000"
                          fontWeight="light"
                        >
                          Paragonian
                        </Tag>
                      </div>
                    </article>
                  </div>
                </div>
              ) : (
                <div class="container">
                  <div class="box px-5 mb-5 has-background-primary">
                    <article class="media">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img
                            src={require("../../assets/img/avatars/Male-avatar-2.png")}
                            alt="Male Paragonian"
                          />
                        </figure>
                      </div>
                      <div class="media-content">
                        <Text color="#fff">Halo,</Text>
                        <Text
                          fontSize="xl"
                          color="#000"
                          fontWeight="medium"
                          mb="0.25em"
                        >
                          {this.props.UserName}
                        </Text>
                        <Tag
                          background="#fff"
                          fontSize="12px"
                          variant="solid"
                          color="#000"
                          fontWeight="light"
                        >
                          Paragonian
                        </Tag>
                      </div>
                    </article>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div class="container">
              {this.props.UserGender === "PEREMPUAN" ? (
                <div class="container">
                  <div class="box px-5 mb-5 has-background-gomess-pink">
                    <article class="media">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img
                            src={require("../../assets/img/avatars/Female-avatar-1.png")}
                            alt="Female Paragonian 1"
                          />
                        </figure>
                      </div>
                      <div class="media-content">
                        <Text color="#fff">Halo,</Text>
                        <Text
                          fontSize="xl"
                          color="#000"
                          fontWeight="medium"
                          mb="0.25em"
                        >
                          {this.props.UserName}
                        </Text>
                        <Tag
                          background="#fff"
                          fontSize="12px"
                          variant="solid"
                          color="#000"
                          fontWeight="light"
                        >
                          {this.props.UserRoom}
                        </Tag>
                      </div>
                    </article>
                  </div>
                </div>
              ) : (
                <div class="container">
                  <div class="box px-5 mb-5 has-background-primary">
                    <article class="media">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img
                            src={require("../../assets/img/avatars/Male-avatar-2.png")}
                            alt="Male Paragonian 1"
                          />
                        </figure>
                      </div>
                      <div class="media-content">
                        <Text color="#fff">Halo,</Text>
                        <Text
                          fontSize="xl"
                          color="#000"
                          fontWeight="medium"
                          mb="0.25em"
                        >
                          {this.props.UserName}
                        </Text>
                        <Tag
                          background="#fff"
                          fontSize="12px"
                          variant="solid"
                          color="#000"
                          fontWeight="light"
                        >
                          {this.props.UserRoom}
                        </Tag>
                      </div>
                    </article>
                  </div>
                </div>
              )}
            </div>
          )}
          <div class="container">
            <a
              class="button is-gomess-babyblue is-fullwidth"
              href={ActivitiesPath}
              onClick={this.handleClick}
            >
              <Box color="#30C1FF">
                <FontAwesomeIcon icon={faFileAlt} size="md" />
              </Box>
              <Spacer />
              <Box>
                <Text fontWeight="600">Lacak request & komplain</Text>
              </Box>
              <Spacer />
              <Box color="#30C1FF">
                <FontAwesomeIcon icon={faAngleRight} size="md" />
              </Box>
            </a>
          </div>
        </Box>
      </>
    );
  }
}

export default IndexHeader;
