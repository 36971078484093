import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FetchCarDetail } from "../../../../api/fetchCarDetail";
import Axios from "../../../../utils/axiosService";

import {
  Flex,
  Button,
  Text,
  CircularProgress,
  useToast,
} from "@chakra-ui/react";

import useLocalStorage from "../../../../hooks/useLocalStorage";
import { Session_Availalbe_Car } from "../../../../utils/constant";

import DetailCarSection from "./Section/DetailCar";
import UsersSection from "./Section/BookedCarUsers";
import RulesSection from "./Section/Rules";

const SummaryByDate = () => {
  const { id } = useParams();
  const navigate = useHistory();
  const toast = useToast();
  const [localStorageVal] = useLocalStorage(Session_Availalbe_Car, {});

  const [car, setCar] = useState({});
  const [isLoadingCar, setIsLoadingCar] = useState(true);
  const [isLoadingSubmitBooking, setIsLoadingSubmitBooking] = useState(false);

  useEffect(() => {
    const fetchCarDetail = async () => {
      const { carDetail, loading } = await FetchCarDetail({
        id: id,
        booking_date: localStorageVal.booking_date,
      });
      setCar(carDetail);
      setIsLoadingCar(loading);
    };

    fetchCarDetail();
  }, [id, localStorageVal.booking_date]);

  const handleBookCar = () => {
    Axios.post("/car/booking", {
      car_id: parseInt(id),
      booking_status: "Booking Created",
      car_user_id: parseInt(localStorage.getItem("id_user")),
      purpose: localStorageVal.purpose,
      start_usage:
        localStorageVal.booking_date + " " + localStorageVal.start_usage,
      finish_usage:
        localStorageVal.booking_date + " " + localStorageVal.finish_usage,
    })
      .then((res) => {
        toast({
          title: "Berhasil booking mobil",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
          containerStyle: {
            color: "white",
          },
        });
        localStorage.removeItem(Session_Availalbe_Car);
        const bookingId = res.data.data.data.id;
        navigate.push("/booking-car/booking-details/" + bookingId);
      })
      .catch((err) => {
        let errorMessage = "An error has occurred";
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length > 0
        ) {
          errorMessage = `An error has occurred: ${err.response.data.errors[0].message}`;
        } else if (err.message) {
          errorMessage = `An error has occurred: ${err.message}`;
        }
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
          containerStyle: {
            color: "white",
          },
        });
      })
      .finally(() => {
        setIsLoadingSubmitBooking(false);
      });
  };

  return (
    <Flex
      bg="#F8FBFF"
      padding="24px 0px"
      flexDirection="column"
      rowGap="24px"
      minHeight="calc(100vh - 72px)"
    >
      <Flex rowGap="12px" direction="column" padding="0 16px">
        <Text fontSize="14px" fontWeight="500">
          Spesifikasi Mobil
        </Text>
        {isLoadingCar ? (
          <Flex
            width="100%"
            height="300px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress isIndeterminate color="#3182CE" />
          </Flex>
        ) : (
          <Flex width="100%" direction="column" rowGap="16px">
            <DetailCarSection {...car} book_details={localStorageVal} />
          </Flex>
        )}
      </Flex>

      {car.car_status === "available" ? (
        <>
          <RulesSection />

          <Flex margin="0 16px">
            <Button
              isLoading={isLoadingSubmitBooking}
              bg="#184973"
              color="white"
              width="100%"
              height="40px"
              borderRadius="100px"
              fontSize="14px"
              fontWeight="500"
              onClick={() => handleBookCar()}
            >
              Book Mobil
            </Button>
          </Flex>
        </>
      ) : (
        <UsersSection id={id} />
      )}
    </Flex>
  );
};

export default SummaryByDate;
