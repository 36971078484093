import React from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Flex,
  Text,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

import Mascot from "../../../../../assets/img/mascot-20.png";
import { ACTIVE_BUTTON_COLOR } from "../../../../../utils/constant";

function FillFormModal({ isOpen, onClose }) {
  const { bookingId } = useParams();
  const navigate = useHistory();

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%" padding="24px">
        <ModalBody padding="0">
          <Flex direction="column" gap="24px" width="100%" bg="#FFFFFF" borderRadius="12px">
            <Flex direction="column" gap="16px" alignItems="center">
              <Image src={Mascot} alt="" w="86px" h="86px" />
              <Flex direction="column" alignItems="center" textAlign="center">
                <Text fontSize="14px" fontWeight="500">
                  Ingin menggunakan mobil?
                </Text>
                <Text fontSize="12px" fontWeight="400">
                  Pastikan kamu sudah di parkiran untuk melakukan pengecekan kendaraan bersama
                  dengan security
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex mt="24px" flexDirection="row" justifyContent="center" gap="16px">
            <Button
              fontSize="14px"
              fontWeight="400"
              padding="6px 12px"
              color={ACTIVE_BUTTON_COLOR}
              variant="outline"
              borderRadius="100px"
              direction="row"
              onClick={onClose}
              width="114px"
              height="32px"
              border="1px"
              borderColor={ACTIVE_BUTTON_COLOR}
              letterSpacing="0.1px"
            >
              Batalkan
            </Button>
            <Button
              fontSize="14px"
              fontWeight="400"
              padding="6px 12px"
              color="white"
              bgColor={ACTIVE_BUTTON_COLOR}
              border="1px solid {ACTIVE_BUTTON_COLOR}"
              borderRadius="100px"
              width="114px"
              height="32px"
              onClick={() => {
                navigate.push(`/booking-car/departure/${bookingId}`);
                onClose();
              }}
            >
              Ya, Lanjutkan
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default FillFormModal;
