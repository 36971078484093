import React from "react";

function TermConditionIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4167 0H2.58333C1.89833 0.000462335 1.24152 0.272782 0.757151 0.757151C0.272782 1.24152 0.000462335 1.89833 0 2.58333V28.4167C0.000462335 29.1017 0.272782 29.7585 0.757151 30.2428C1.24152 30.7272 1.89833 30.9995 2.58333 31H20.1318C20.4712 31.0006 20.8073 30.9341 21.1207 30.8042C21.4342 30.6743 21.7189 30.4836 21.9583 30.2432L30.2432 21.9583C30.4836 21.7189 30.6743 21.4342 30.8042 21.1207C30.9341 20.8073 31.0006 20.4712 31 20.1318V2.58333C30.9995 1.89833 30.7272 1.24152 30.2428 0.757151C29.7585 0.272782 29.1017 0.000462335 28.4167 0V0ZM10.3333 9.04167H20.6667C21.0092 9.04167 21.3378 9.17775 21.58 9.41999C21.8222 9.66222 21.9583 9.99076 21.9583 10.3333C21.9583 10.6759 21.8222 11.0044 21.58 11.2467C21.3378 11.4889 21.0092 11.625 20.6667 11.625H10.3333C9.99076 11.625 9.66222 11.4889 9.41999 11.2467C9.17775 11.0044 9.04167 10.6759 9.04167 10.3333C9.04167 9.99076 9.17775 9.66222 9.41999 9.41999C9.66222 9.17775 9.99076 9.04167 10.3333 9.04167V9.04167ZM15.5 21.9583H10.3333C9.99076 21.9583 9.66222 21.8222 9.41999 21.58C9.17775 21.3378 9.04167 21.0092 9.04167 20.6667C9.04167 20.3241 9.17775 19.9956 9.41999 19.7533C9.66222 19.5111 9.99076 19.375 10.3333 19.375H15.5C15.8426 19.375 16.1711 19.5111 16.4133 19.7533C16.6556 19.9956 16.7917 20.3241 16.7917 20.6667C16.7917 21.0092 16.6556 21.3378 16.4133 21.58C16.1711 21.8222 15.8426 21.9583 15.5 21.9583ZM10.3333 16.7917C9.99076 16.7917 9.66222 16.6556 9.41999 16.4133C9.17775 16.1711 9.04167 15.8426 9.04167 15.5C9.04167 15.1574 9.17775 14.8289 9.41999 14.5867C9.66222 14.3444 9.99076 14.2083 10.3333 14.2083H20.6667C21.0092 14.2083 21.3378 14.3444 21.58 14.5867C21.8222 14.8289 21.9583 15.1574 21.9583 15.5C21.9583 15.8426 21.8222 16.1711 21.58 16.4133C21.3378 16.6556 21.0092 16.7917 20.6667 16.7917H10.3333ZM20.6667 27.8818V20.6641H27.8844L20.6667 27.8818Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default TermConditionIcon;
