import React, { useState, useEffect } from "react";
import Axios from "../../../utils/axiosService";
import { useHistory } from "react-router-dom";
import { FetchDriverRequest } from "../../../api/fetchDriverRequest";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Flex,
  Button,
  Checkbox,
  Text,
  Image,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Icon,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";

import {
  MdOutlineUploadFile,
  MdOutlineDateRange,
  MdCancel,
} from "react-icons/md";

const DriverRequestForm = () => {
  const navigate = useHistory();
  const toast = useToast();

  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseExpired, setLicenseExpired] = useState("");
  const [licenseImage, setLicenseImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [driverRequestId, setDriverRequestId] = useState(null);

  const [isLoadingSubmitRequest, setIsLoadingSubmitRequest] = useState(false);
  const [isLoadingFetchRequest, setIsLoadingFetchRequest] = useState(true);

  useEffect(() => {
    const fetchDriverRequestData = async () => {
      const { driverRequest } = await FetchDriverRequest();
      if (
        driverRequest.verification_status === "requested" ||
        driverRequest.verification_status === "rejected"
      ) {
        setLicenseNumber(driverRequest.license_number);
        const [day, month, year] = driverRequest.license_expired.split("-");
        setLicenseExpired(new Date(year, month - 1, day));
        setImagePreview(driverRequest.license_image);
        setDriverRequestId(driverRequest.id);
      }
      setIsLoadingFetchRequest(false);
    };

    fetchDriverRequestData();
  }, []);

  const isLicenseNumberValid =
    licenseNumber.length === 14 && /^\d+$/.test(licenseNumber);
  const isImageValid = licenseImage || imagePreview;
  const isFormValid =
    isLicenseNumberValid && licenseExpired && isImageValid && termsAccepted;

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5242880) {
      setLicenseImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast({
        title: "Error",
        description: "Mohon upload gambar berukuran kurang dari 5MB",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
        containerStyle: {
          color: "white",
        },
      });
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setLicenseImage(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoadingSubmitRequest(true);

    const user_id = localStorage.getItem("id_user");
    const user_data = JSON.parse(localStorage.getItem("user_data"));

    const [day, month, year] = new Date(licenseExpired)
      .toLocaleDateString("en-GB")
      .split("/");
    const formattedLicenseExpired = `${day}-${month}-${year}`;

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("username", user_data.user_email);

    if (driverRequestId) {
      formData.append("license_number", licenseNumber);
      formData.append("license_expired", formattedLicenseExpired);
      formData.append("status", "requested");
      if (licenseImage) {
        formData.append("license_image", licenseImage);
      }

      Axios({
        method: "PATCH",
        url: `/driver/request/${driverRequestId}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          toast({
            title: "Success",
            description: "Data pendaftaran berhasil diupdate",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
            containerStyle: {
              color: "white",
            },
          });
          setLicenseNumber("");
          setLicenseExpired("");
          setLicenseImage(null);
          setImagePreview("");
          setTermsAccepted(false);
          navigate.push("/booking-car");
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: `An error has occurred: ${error}`,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
            containerStyle: {
              color: "white",
            },
          });
        })
        .finally(() => {
          setIsLoadingSubmitRequest(false);
        });
    } else {
      formData.append("role", user_data.user_role);
      formData.append("contact", user_data.user_phone);
      formData.append("license_number", licenseNumber);
      formData.append("license_expired", formattedLicenseExpired);
      formData.append("license_image", licenseImage);
      formData.append("active", "true");

      Axios({
        method: "POST",
        url: "/driver/request",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          toast({
            title: "Success",
            description: "Data pendaftaran berhasil dikirimkan",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
            containerStyle: {
              color: "white",
            },
          });
          setLicenseNumber("");
          setLicenseExpired("");
          setLicenseImage(null);
          setImagePreview("");
          setTermsAccepted(false);
          navigate.push("/booking-car");
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: `An error has occurred: ${error}`,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
            containerStyle: {
              color: "white",
            },
          });
        })
        .finally(() => {
          setIsLoadingSubmitRequest(false);
        });
    }
  };

  return (
    <Flex
      bg="#F8FBFF"
      padding="24px"
      flexDirection="column"
      gap="12px"
      height="calc(100vh - 72px)"
    >
      {isLoadingFetchRequest ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress isIndeterminate color="#3182CE" />
        </Flex>
      ) : (
        <>
          <Text fontSize="14px" fontWeight="500">
            Formulir Pendaftaran
          </Text>
          <Box
            bg="#FFFFFF"
            padding="24px 16px"
            borderRadius="16px"
            boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
          >
            <form onSubmit={handleSubmit}>
              <Flex flexDirection="column" gap="24px">
                <FormControl>
                  <FormLabel
                    htmlFor="license-number"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    Nomor SIM
                    <Text as="span" color="#B3261E" marginLeft="4px">
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    id="license-number"
                    placeholder="Masukkan 14 digit angka"
                    maxLength={14}
                    value={licenseNumber}
                    onChange={(e) => setLicenseNumber(e.target.value)}
                    boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                    border="none"
                    sx={{
                      "::placeholder": {
                        fontStyle: "italic",
                      },
                    }}
                  />
                </FormControl>

                <FormControl zIndex={9}>
                  <FormLabel
                    htmlFor="license-expiry"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    Masa berlaku SIM
                    <Text as="span" color="#B3261E" marginLeft="4px">
                      *
                    </Text>
                  </FormLabel>
                  <InputGroup
                    sx={{
                      ".react-datepicker-wrapper": {
                        width: "100%",
                      },
                      ".react-datepicker__tab-loop": {
                        zIndex: 9,
                      },
                    }}
                  >
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <Icon as={MdOutlineDateRange} color="#222222" />
                      }
                    />
                    <DatePicker
                      id="license-expiry"
                      selected={licenseExpired}
                      onChange={(date) => setLicenseExpired(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD / MM / YYYY"
                      customInput={
                        <Input
                          boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                          border="none"
                          paddingLeft="32px"
                          sx={{
                            "::placeholder": {
                              fontStyle: "italic",
                            },
                          }}
                        />
                      }
                      zIndex={9}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <FormLabel
                    htmlFor="license-image"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    Gambar SIM
                    <Text as="span" color="#B3261E" marginLeft="4px">
                      *
                    </Text>
                  </FormLabel>
                  <Flex gap="8px">
                    {imagePreview ? (
                      <Flex
                        position="relative"
                        direction="column"
                        width="100%"
                        bg="#F0F0F0"
                        border="1px dashed #73777F"
                        padding="2"
                        borderRadius="8px"
                        fontSize="12px"
                        fontWeight="400"
                        alignItems="center"
                        justifyContent="center"
                        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                      >
                        <Icon
                          as={MdCancel}
                          position="absolute"
                          top="10px"
                          right="10px"
                          cursor="pointer"
                          onClick={handleRemoveImage}
                          color="#222222"
                          w="16px"
                          h="16px"
                        />
                        <Image
                          src={imagePreview}
                          alt="SIM Preview"
                          maxH="200px"
                        />
                        {licenseImage && (
                          <>
                            <Text color="#222222">{licenseImage.name}</Text>
                            <Text color="#5E5E5E">
                              {(licenseImage.size / 1024).toFixed(2)} KB
                            </Text>
                          </>
                        )}
                      </Flex>
                    ) : (
                      <FormLabel
                        htmlFor="license-image"
                        cursor="pointer"
                        width="100%"
                      >
                        <Box
                          border="1px dashed #73777F"
                          p="16px 8px"
                          borderRadius="8px"
                          textAlign="center"
                          fontSize="12px"
                          fontWeight="400"
                          boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                        >
                          <Icon
                            as={MdOutlineUploadFile}
                            w="20px"
                            h="20px"
                            color="#5E5E5E"
                          />
                          <Text color="#222222">
                            Upload gambar bagian depan SIM kamu
                          </Text>
                          <Text color="#5E5E5E">Maksimal 5MB</Text>
                        </Box>
                      </FormLabel>
                    )}
                    <Input
                      id="license-image"
                      type="file"
                      accept=".jpg, .jpeg, .png, .heic, .heif"
                      onChange={handleImageChange}
                      visibility="hidden"
                      position="absolute"
                      pointerEvents="none"
                      width="1px"
                      height="1px"
                    />
                  </Flex>
                </FormControl>

                <Checkbox
                  isChecked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  alignItems="flex-start"
                >
                  <Text fontSize="14px" fontWeight="400" color="#222222">
                    Dengan ini saya telah membaca dan menyetujui
                    <span style={{ fontWeight: "500", color: "#184973" }}>
                      {" "}
                      Terms and Condition{" "}
                    </span>
                    yang berlaku pada Booking Mobil.
                  </Text>
                </Checkbox>

                <Button
                  isLoading={isLoadingSubmitRequest}
                  colorScheme="blue"
                  bg="#184973"
                  borderRadius="100px"
                  type="submit"
                  isDisabled={!isFormValid}
                >
                  Kirim
                </Button>
              </Flex>
            </form>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default DriverRequestForm;
