import React from "react";

function LogOutIcon() {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 12L19.3333 5.33333V9.33333H8.05556V14.6667H19.3333V18.6667L29 12ZM3.22222 2.66667H16.1111V0H3.22222C1.45 0 0 1.2 0 2.66667V21.3333C0 22.8 1.45 24 3.22222 24H16.1111V21.3333H3.22222V2.66667Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default LogOutIcon;
