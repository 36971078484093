import React from "react";

function ArrowLeftIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.25 11.9167V11.4167H22.75H8.15562L13.0362 6.53614L13.3897 6.18259L13.0362 5.82904L11.5043 4.2972L11.1508 3.94365L10.7972 4.2972L2.44796 12.6465L2.09441 13L2.44796 13.3536L10.7972 21.7028L11.1508 22.0564L11.5043 21.7028L13.0362 20.171L13.3897 19.8174L13.0362 19.4639L8.15562 14.5833H22.75H23.25V14.0833V11.9167Z"
        fill="black"
        stroke="black"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
