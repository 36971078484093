import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faLocationArrow,
  faFileAlt,
  faAngleRight,
  faHome,
  faStickyNote,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import { Form, Icon } from "react-bulma-components";
import Swal from "sweetalert2";
import AxiosParastay from "../../../../../../utils/parastayAxiosService";
import { Button, Flex, Text } from "@chakra-ui/react";
import styles from "./styles.module.css";
import MainHeader from "../../../../../../components/Parastay/Header/MainHeader";

const Modal = ({ children, closeModal, modalState, title, HandleSubmit }) => {
  if (!modalState) {
    return null;
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-card" style={{ width: "90%" }}>
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">
            <strong>{title}</strong>
          </p>
          <button className="delete" onClick={closeModal} />
        </header>
        <section className="modal-card-body">
          <div className="content">{children}</div>
        </section>
        <footer className="modal-card-foot">
          <a href={() => false} className="button" onClick={HandleSubmit}>
            Sudah, Submit
          </a>
          <a href={() => false} className="button" onClick={closeModal}>
            Belum
          </a>
        </footer>
      </div>
    </div>
  );
};

class FormComplaint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location_id_fk: "",
      requestor_id: "",
      category_id_fk: "",
      sub_location: "",
      complain_desc: "",
      complain_request_date: "",
      complain_status_id: "",
      work_location_id: null,
      photo: [],
      complain_photo_data: [],
      UserData: {
        data: [
          {
            user_id: null,
            user_name: null,
            user_gender: null,
            work_location_id_fk: null,
            room_name: null,
            location_name: null,
          },
        ],
      },
      complaintCategory: {
        data: [
          {
            complain_category_id: null,
            complain_category_name: null,
            complain_category_type: null,
          },
        ],
      },
      modalState: false,
    };
    this.ChangeHandler = this.ChangeHandler.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  componentDidMount() {
    const idUser = localStorage.getItem("id_user_parastay");

    AxiosParastay({
      method: "GET",
      url: `/CategoryComplain`,
    })
    .then((result) => {
      this.setState({
        complaintCategory: result.data,
      });
    });

    AxiosParastay({
      method: "GET",
      url: `/UserHomePage?id_user=${idUser}`,
    })
    .then((result) => {
      this.setState({
        UserData: result.data.data[0],
      });
    });
  }

  validateSubmit = (data) => {
    var isLocationValid = data.location_id_fk2 !== null;
    var isRequestorValid = data.requestor_id !== null;
    var isCategoryValid = data.category_id_fk !== null;
    var isSubLocationValid = data.sub_location !== null;
    var isComplainDescValid =
      data.complain_desc !== null && data.complain_desc !== "";
    var isPhotoValid = data.photo !== null;

    return (
      isLocationValid &&
      isRequestorValid &&
      isCategoryValid &&
      isSubLocationValid &&
      isComplainDescValid &&
      isPhotoValid
    );
  };

  handleSubmit() {
    Swal.fire({
      title: "Creating Complaint...",
      html: "Please wait.",
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    Swal.showLoading();

    var currentDate = new Date();
    var finalCurrentDate = [
      currentDate.getDate(),
      currentDate.getMonth() + 1,
      currentDate.getFullYear(),
    ].join("-");
    var fileDateStamp = [
      currentDate.getDate(),
      currentDate.getMonth() + 1,
      currentDate.getFullYear(),
    ].join("");

    const id_user = localStorage.getItem("id_user_parastay");
    var location = this.state.UserData.location_id;

    var ComplaintData = new FormData();
    ComplaintData.append("location_id_fk2", parseInt(location));
    ComplaintData.append("requestor_id", parseInt(id_user));
    ComplaintData.append(
      "category_id_fk",
      parseInt(this.state.SelectedCategory)
    );
    ComplaintData.append("sub_location", this.state.sub_location);
    ComplaintData.append("complain_desc", this.state.complain_desc);
    ComplaintData.append("complain_request_date", finalCurrentDate);
    ComplaintData.append("complain_start_date", finalCurrentDate);
    ComplaintData.append("id_work_area", parseInt(this.state.work_location_id));

    var finalPhotoName = [fileDateStamp, this.state.photo.name].join("_");

    if (this.state.photo.name) {
      ComplaintData.append("photo[]", this.state.photo, finalPhotoName);
    } else {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Mohon Lampirkan Foto/Video terlebih dahulu",
        confirmButtonColor: "blue",
        confirmButtonText: `OK`,
      });
    }

    ComplaintData.append("complain_status_id", 1);
    ComplaintData.append("log", "Complaint Submitted");

    if (this.validateSubmit(ComplaintData)) {
      AxiosParastay({
        method: "POST",
        url: `/CreateComplaint`,
        data: ComplaintData,
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then((result) => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Complaint Created",
            confirmButtonColor: "blue",
            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.assign("./");
            }
          });
        })
        .catch(function (error) {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Server Error: Failed to create Complain",
            text: error,
            confirmButtonColor: "blue",
            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        });
    } else {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Pastikan semua data sudah terisi",
        confirmButtonColor: "blue",
        confirmButtonText: `OK`,
      });
    }
  }

  toggleModal() {
    this.setState((prev, props) => {
      const newState = !prev.modalState;

      return { modalState: newState };
    });
  }

  handleFile = (event) => {
    let tempFileSpecs = event.target.files[0];
    if (tempFileSpecs.type.includes("image")) {
      if (tempFileSpecs.size > 5242880) {
        Swal.fire({
          icon: "error",
          title: "Error: Overlimit",
          text: "Ukuran file gambar melebihi 5 MB, silahkan pilih file dengan ukuran lebih kecil",
          confirmButtonColor: "blue",
          confirmButtonText: `OK`,
        }).then((result) => {
          event.target.value = null;
        });
      } else {
        this.setState({ [event.target.name]: event.target.files[0] });
      }
    } else if (tempFileSpecs.type.includes("video")) {
      if (tempFileSpecs.size > 20971520) {
        Swal.fire({
          icon: "error",
          title: "Error: Overlimit",
          text: "Ukuran file video melebihi 20 MB, silahkan pilih file dengan ukuran lebih kecil",
          confirmButtonColor: "blue",
          confirmButtonText: `OK`,
        }).then((result) => {
          event.target.value = null;
        });
      } else {
        this.setState({ [event.target.name]: event.target.files[0] });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error: File Unsupported",
        text: "Anda tidak dapat melampirkan file ini",
        confirmButtonColor: "blue",
        confirmButtonText: `OK`,
      }).then((result) => {
        event.target.value = null;
      });
    }
  };

  ChangeHandler(event) {
    this.setState({
      [event.target.name]:
        event.target.type === "number"
          ? parseInt(event.target.value)
          : event.target.value,
    });
  }

  render() {
    let complain_category = this.state.complaintCategory.data;
    return (
      <>
        <div className="App">
          <Flex className={styles["header-container"]}>
            <MainHeader title="Request Complaint" backLink={`/parastay?id=1`} />
          </Flex>
          <Flex className={styles["main-container"]}>
            <Flex className={styles["subheader-container"]}>
              <Text fontSize="24px" fontWeight="700" mb="0.5em">
                Komplain Mess
              </Text>
              <a
                class="button is-gomess-babyblue is-fullwidth"
                href="./terms-conditions"
                onClick={this.handleClick}
              >
                <div class="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faFileAlt} size="md" />
                </div>
                <span>
                  <h3>
                    <strong>Tatacara Komplain</strong>
                  </h3>
                </span>
                <div class="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faAngleRight} size="md" />
                </div>
              </a>
            </Flex>
            <form onSubmit={this.handleSubmit}>
              <p class="subtitle is-6 has-text-left">
                Isian yang ditandai dengan (<b>*</b>) wajib diisi
              </p>
              <div class="field mb-5">
                <label for="" class="label">
                  Lokasi Kerja*
                </label>
                <div class="control is-expanded has-icons-left">
                  <div class="select is-fullwidth">
                    <select
                      id="work_location_id"
                      name="work_location_id"
                      value={this.state.work_location_id}
                      onChange={this.ChangeHandler}
                    >
                      <option value={null} selected>
                        (Pilih Lokasi Kerja)
                      </option>
                      <option value={2}>Head Office</option>
                      <option value={3}>Plant</option>
                      <option value={1}>DC</option>
                    </select>
                  </div>
                  <div class="icon is-small is-left has-text-primary">
                    <FontAwesomeIcon icon={faGlobe} size="sm" />
                  </div>
                </div>
              </div>
              <div class="field mb-5">
                <label for="" class="label">
                  Kategori*
                </label>
                <div class="control is-expanded has-icons-left">
                  <div class="select is-fullwidth">
                    <select
                      id="SelectedCategory"
                      name="SelectedCategory"
                      value={this.state.SelectedCategory}
                      onChange={this.ChangeHandler}
                    >
                      <option value={null} selected>
                        (Pilih Kategori)
                      </option>
                      {complain_category.map((char) => (
                        <option value={char.complain_category_id}>
                          {char.complain_category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="icon is-small is-left has-text-primary">
                    <FontAwesomeIcon icon={faGlobe} size="sm" />
                  </div>
                </div>
              </div>
              <div class="field mb-5">
                <label for="" class="label">
                  Sub Lokasi*
                </label>
                <div class="control has-icons-left">
                  <input
                    id="sub_location"
                    name="sub_location"
                    value={this.state.sub_location}
                    class="input"
                    placeholder="mis. Kamar No. 1, Tangga"
                    required
                    onChange={this.ChangeHandler}
                  />
                  <span class="icon is-left has-text-primary">
                    <FontAwesomeIcon icon={faLocationArrow} size="sm" />
                  </span>
                </div>
              </div>
              <div class="field mb-5">
                <label for="" class="label">
                  Deskripsi Komplain*
                </label>
                <textarea
                  id="complain_desc"
                  name="complain_desc"
                  value={this.state.complain_desc}
                  class="textarea"
                  placeholder="Deskripsikan apa yang terjadi"
                  rows="7"
                  onChange={this.ChangeHandler}
                ></textarea>
              </div>
              {/* <label for="" class="label">Bukti Foto</label> */}
              <Form.Field>
                <Form.Label>Unggah Foto*</Form.Label>
                <Form.Control>
                  <Form.InputFile
                    class="file has-name is-link is-fullwidth mb-3"
                    icon={<Icon icon="upload" />}
                    boxed
                    multiple="multiple"
                    placeholder="Textarea"
                    onChange={this.handleFile}
                    id="photo"
                    name="photo"
                    type="file"
                    accept=".png, .jpg, .jpeg .mp4"
                  />
                </Form.Control>
              </Form.Field>
              <p class="subtitle is-6 has-text-left">
                Ukuran foto max. <b>5 MB</b> dan video max. <b>20 MB</b>{" "}
                menerima gambar dengan format <b>PNG, JPG dan JPEG</b> serta
                video dengan format <b>MP4</b>
              </p>
              <Button href={() => false} onClick={this.toggleModal} width="100%" bgColor="#30C1FF">
                <Text fontSize="20px" fontWeight="600" color="#fff">
                  Selanjutnya
                </Text>
              </Button>
              <Modal
                modalState={this.state.modalState}
                closeModal={this.toggleModal}
                HandleSubmit={this.handleSubmit}
                title="Konfirmasi Komplain"
              >
                <p class="has-text-centered is-spaced">
                  <strong>
                    Apa anda yakin data yang dimasukkan sudah sesuai?
                  </strong>
                </p>
                <p />
                {this.state.work_location_id ? (
                  <div class="media">
                    <div class="media-left">
                      <div class="icon is-small is-left has-text-primary">
                        <FontAwesomeIcon icon={faLocationArrow} size="lg" />
                      </div>
                    </div>
                    <div class="media-content">
                      <h2 class="is-size-6">Lokasi kerja</h2>
                      <p class="is-size-6">
                        {this.state.work_location_id.includes(1)
                          ? "Distribution Center"
                          : this.state.work_location_id.includes(2)
                          ? "Head Office"
                          : this.state.work_location_id.includes(3)
                          ? "Plant"
                          : null}
                      </p>
                    </div>
                  </div>
                ) : null}
                <p />
                <div class="media">
                  <div class="media-left">
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faLocationArrow} size="lg" />
                    </div>
                  </div>
                  <div class="media-content">
                    <h2 class="is-size-6">ID Lokasi Mess</h2>
                    <p class="is-size-6">{this.state.UserData.location_id}</p>
                  </div>
                </div>
                <p />
                <div class="media">
                  <div class="media-left">
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faCogs} size="lg" />
                    </div>
                  </div>
                  <div class="media-content">
                    <h2 class="is-size-6">ID Kategori Komplain</h2>
                    <p class="is-size-6">{this.state.SelectedCategory}</p>
                  </div>
                </div>
                <p />
                <div class="media">
                  <div class="media-left">
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faHome} size="lg" />
                    </div>
                  </div>
                  <div class="media-content">
                    <h2 class="is-size-6">Sub Lokasi</h2>
                    <p class="is-size-6">{this.state.sub_location}</p>
                  </div>
                </div>
                <p />
                <div class="media">
                  <div class="media-left">
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faStickyNote} size="lg" />
                    </div>
                  </div>
                  <div class="media-content">
                    <h2 class="is-size-6">Deskripsi komplain</h2>
                    <p class="is-size-6">{this.state.complain_desc}</p>
                  </div>
                </div>
              </Modal>
            </form>
          </Flex>
        </div>
      </>
    );
  }
}
export default FormComplaint;
