import Axios from "../utils/axiosService";
import moment from "moment/moment";

export async function FetchBookedCarUsers({ id }) {
  moment.locale("en");
  let dateNext30Days = moment.utc().add(30, "days").format("YYYY-MM-DD");
  let dateToday = moment.utc().add().format("YYYY-MM-DD");

  try {
    const res = await Axios.get(
      `/car/booking?car_id=${id}&status=Booking Created, In Progress&start_usage_gte=${dateToday}&start_usage_lte=${dateNext30Days}&order_column=start_usage&order_sequence=ASC`
    );

    let logBookedCarUsersData = res.data.data.data;

    return {
      bookedCarUsersData: logBookedCarUsersData,
      loading: false,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching history data:", err);
    return {
      bookedCarUsersData: [],
      loading: false,
      error: err,
    };
  }
}
