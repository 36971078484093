import React from "react";
import Notification from "./Notification";
import { Box, Flex, Text } from "@chakra-ui/react";

function UserInformation({ userName }) {
  return (
    <Flex
      h="77px"
      width="100%"
      padding="8px 16px"
      position="relative"
      backgroundImage={`linear-gradient(to bottom, #EBF3FF 50%, #FFFFFF 50%)`}
    >
      <Box
        position="relative"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="100%"
      >
        <Box
          width="100%"
          padding="8px 12px"
          bg="#FFFFFF"
          style={{
            boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
            borderRadius: "12px",
          }}
        >
          <Flex justify="space-between" align="center">
            <Flex direction="column">
              <Text fontSize="14px">Hi, </Text>
              <Text fontSize="16px" fontWeight="600">
                {userName}
              </Text>
            </Flex>
            <Flex
              height="32px"
              width="32px"
              borderRadius="100%"
              border="1px solid #E9E9E9"
              align="center"
              justify="center"
            >
              <Notification />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default UserInformation;
