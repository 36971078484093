import React from "react";

function HistoryIcon({fill}) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0001 0.5C19.9039 0.5 25.5001 6.09625 25.5001 13C25.5001 19.9037 19.9039 25.5 13.0001 25.5C6.09637 25.5 0.500122 19.9037 0.500122 13H3.00012C3.00012 18.5225 7.47762 23 13.0001 23C18.5226 23 23.0001 18.5225 23.0001 13C23.0001 7.4775 18.5226 3 13.0001 3C9.92012 3 7.16512 4.3925 5.33137 6.58125L8.00012 9.25H0.500122V1.75L3.55887 4.8075C5.85012 2.17 9.23012 0.5 13.0001 0.5ZM14.2501 6.75V12.4812L18.3039 16.535L16.5351 18.3037L11.7501 13.5162V6.75H14.2501Z"
        fill={fill ?? '#4D4D4D'}
      />
    </svg>
  );
}

export default HistoryIcon;
