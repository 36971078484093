import { format, parseISO } from "date-fns";
import { id } from "date-fns/locale";

export const formatISOtoDayAndHHmm = (isoString) => {
  const date = parseISO(isoString);
  const formattedDate = format(date, "EEEE, d MMMM yyyy", { locale: id });
  const formattedTime = format(date, "HH:mm", { locale: id });

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export const formatISOtoDDMMYYYYAndHHmm = (isoString) => {
  const date = parseISO(isoString);
  const formattedDate = format(date, "dd/MM/yyyy", { locale: id });
  const formattedTime = format(date, "HH:mm", { locale: id });

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export const formatYYYYMMDDToDay = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("id-ID", options).format(date);
  return formattedDate;
};

export const hasDatePassed = (targetDate) => {
  const formattedTargetDate = new Date(targetDate).toISOString().split("T")[0];
  const currentDate = new Date().toISOString().split("T")[0];

  return new Date(formattedTargetDate) < new Date(currentDate);
};
