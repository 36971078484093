import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'Lato', 'sans-serif'`,
    body: `'Lato', 'sans-serif'`,
  },
  colors: {
    gomessLight: "#F1F8FA",
    gomessDanger: "#F5365C",
    gomessWarning: "#fb6340",
    gomessDark: "#08232D",
    gomessBlue: "#30C1FF",
    gomessPink: "#FF8ECB",
    gomessBlack: "#08232E",
    gomessWhite: "#FFFFFF",
    gomessLightPink: "#FFE2F2",
    gomessBabyBlue: "#EAF9FF",
    gomessGrey: "#B1B1B1",
    gomessPositiveState: "#1AAA0D",
    gomessYellowMustard: "#EE930A",
    gomessNeonBlue: "#1660FF",
    gomessPinkRose: "#FF1593",
  },
});

export default theme;
