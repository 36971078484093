import { format } from "date-fns";
import moment from "moment/moment";
import Axios from "../utils/axiosService";

export const getListBookedCarDate = async ({ id }) => {
  try {
    if (!id) {
      return [];
    }
    const formatedDate = format(new Date(), "yyyy-MM-dd");

    const { data } = await Axios.get(
      `/car/booking?status=Booking Created, In Progress&start_usage_gte=${formatedDate}&car_id=${id}&order_sequence=ASC&order_column=start_usage`
    );
    const dateBooked = data?.data?.data?.map((booked) => ({
      date: new Date(
        moment.utc(booked.start_usage).format("YYYY-MM-DD HH:mm:ss")
      ),
      message: "Date already booked",
    }));

    return dateBooked;
  } catch (error) {
    return [];
  }
};
