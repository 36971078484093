import React from "react";
import ButtonBack from "../ButtonBack";
import { Box, Flex, Text } from "@chakra-ui/react";

function Header() {
  return (
    <Flex
      w="100%"
      position="relative"
      bg="#FFF"
      justifyContent="center"
      alignItems="center"
      gap="8px"
      color="rgba(0, 0, 0, 0.7)"
      fontSize="20px"
      boxShadow="0px 1px 21px -1px rgba(0, 0, 0, 0.25)"
      paddingY="24px"
    >
      <Box position="absolute" left="6">
        <ButtonBack />
      </Box>
      <Text className=" has-text-weight-bold">Notification</Text>
    </Flex>
  );
}

export default Header;
