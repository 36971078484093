import { Flex, Box, Image, Button, Text } from "@chakra-ui/react";
import SorryMascot from "../../../assets/img/bookingCar/mascot-16-sorry.png";
import { MdWhatsapp } from "react-icons/md";

const NotRegistered = () => {
  const contactNumber = "+6287709255800";
  return (
    <Box width="100%" borderBottom="1px solid #EFEFEF">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="16px"
        padding="48px 32px"
        height="calc(100vh - 168px)"
      >
        <Image src={SorryMascot} alt="Sorry" width="120px" height="120px" />
        <Flex direction="column" gap="4px">
          <Text
            fontSize="14px"
            fontWeight="500"
            color="#222222"
            textAlign="center"
          >
            Booking Driver belum dapat digunakan
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#5E5E5E"
            textAlign="center"
          >
            Silahkan hubungi admin booking car untuk mengetahu prosedur Booking
            Driver
          </Text>
        </Flex>
        <Button
          height="32px"
          padding="6px 16px 6px 12px"
          color="#184973"
          borderRadius="100px"
          border="1px solid #184973"
          gap="8px"
          direction="row"
          width="fit-content"
          bg="#FFFFFF"
          alignItems="center"
          onClick={() => window.open(`https://wa.me/${contactNumber}`)}
        >
          <MdWhatsapp />
          <Text fontSize="12px" fontWeight="400">
            Hubungi Admin
          </Text>
        </Button>
      </Flex>
    </Box>
  );
};

export default NotRegistered;
