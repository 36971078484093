import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import BookingForm from "../../../../components/BookingCar/MainCar/BookingForm";
import HeaderBookingForm from "../../../../components/Car/HeaderBookingForm";
import { useGetQueryParams } from "../../../../hooks/useGetQueryParams";

const BookingFormView = () => {
  const { params } = useGetQueryParams();
  return (
    <Flex flexDir="column" bg="#F8FBFF" minH="100vh">
      <HeaderBookingForm />
      <Flex mt="20px" direction="column" padding="0 16px">
        <Text fontSize="14px" fontWeight="500">
          Formulir booking mobil
        </Text>
      </Flex>
      <BookingForm isBookingCar={true} idCar={params.id || ""} />
    </Flex>
  );
};

export default BookingFormView;
