import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FetchCarBookingDetails } from "../../../../api/fetchCarBookingDetails";

import Header from "../../../SecondaryHeader";
import LeavingReturningTime from "../../Component/LeavingReturningTime";
import CancelModal from "./Section/CancelModal";
import FillFormModal from "./Section/FillFormModal";

import { formatISOtoDayAndHHmm } from "../../../../utils/formatDate";

import { Box, Button, CircularProgress, Flex, Text } from "@chakra-ui/react";
import CarAttributes from "../../Component/CarAttributes";
import CarLocation from "../../Component/CarLocation";

const BookingCarDetails = () => {
  const { bookingId } = useParams();
  const location = useLocation();
  const { backLink } = location.state || { backLink: "/booking-car" };

  const [headerTitle, setHeaderTitle] = useState("Mendatang");

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isOpenFillFormModal, setIsOpenFillFormModal] = useState(false);

  const [bookingDetails, setBookingDetails] = useState({});
  const [isLoadingBookingDetails, setIsLoadingBookingDetails] = useState(true);

  const [isStartUsagePassed, setIsStartUsagePassed] = useState(false);

  useEffect(() => {
    const fetchCarBookingDetails = async () => {
      const { bookingDetails, loading } = await FetchCarBookingDetails({
        id: bookingId,
      });

      const endOfDay = new Date(bookingDetails.start_usage).setHours(23,59,59)

      setBookingDetails(bookingDetails);
      setIsLoadingBookingDetails(loading);
      setIsStartUsagePassed(endOfDay <= new Date());

      if (
        ((bookingDetails.booking_status === "Booking Created" ||
          bookingDetails.booking_status === "In Progress" ||
          bookingDetails.booking_status === "Done") &&
          endOfDay <= new Date()) ||
        bookingDetails.booking_status === "Booking Cancelled"
      ) {
        setHeaderTitle("Riwayat");
      } else if (
        (bookingDetails.booking_status === "Booking Created" ||
          bookingDetails.booking_status === "In Progress" ||
          bookingDetails.booking_status === "Done") &&
        endOfDay >= new Date()
      ) {
        setHeaderTitle("Mendatang");
      }
    };

    fetchCarBookingDetails();
  }, [bookingId]);

  const Divider = () => (
    <Flex my="8px">
      <Box borderBottom="2px dashed" borderColor="gray.200" width="100%" />
    </Flex>
  );

  return (
    <>
      <Header type="car" title={headerTitle} backLink={backLink} />
      <Flex
        bg="#F8FBFF"
        padding="24px 0px 0px 0px"
        flexDirection="column"
        rowGap="24px"
        minHeight="calc(100vh - 72px)"
      >
        <Flex rowGap="12px" direction="column" padding="0 16px">
          {isLoadingBookingDetails ? (
            <Flex
              width="100%"
              height="calc(100vh - 72px)"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress isIndeterminate color="#3182CE" />
            </Flex>
          ) : (
            <Flex width="100%" direction="column" rowGap="16px">
              <Flex
                borderRadius="12px"
                pt="12px"
                bg={"white"}
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                direction="column"
              >
                <Box px="12px">
                  <CarAttributes
                    brand={bookingDetails.car.brand}
                    name={bookingDetails.car.name}
                    car_status={bookingDetails.car.car_status}
                    license_plate={bookingDetails.car.license_plate}
                    capacity={bookingDetails.car.capacity}
                    transmission={bookingDetails.car.transmission}
                    fuel={bookingDetails.car.fuel}
                    color={bookingDetails.car.color}
                    image={bookingDetails.car.image}
                    last_service={bookingDetails.car.last_service}
                  />
                  <Divider />
                  <CarLocation
                    current_location={bookingDetails.car.current_location}
                    location_url={bookingDetails.car.location_url}
                  />
                  <Divider />

                  <LeavingReturningTime
                    leavingDate={
                      formatISOtoDayAndHHmm(bookingDetails.start_usage).date
                    }
                    leavingTime={
                      formatISOtoDayAndHHmm(bookingDetails.start_usage).time
                    }
                    returningDate={
                      formatISOtoDayAndHHmm(bookingDetails.finish_usage).date
                    }
                    returningTime={
                      formatISOtoDayAndHHmm(bookingDetails.finish_usage).time
                    }
                  />
                  <Divider />
                  <Flex direction="column" pb="12px" gap="4px">
                    <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
                      Keperluan Booking
                    </Text>
                    <Box
                      width="100%"
                      height="fit-content"
                      padding="8px 12px"
                      gap="10px"
                      borderRadius="8px"
                      bg="#F5F5F5"
                    >
                      <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
                        {bookingDetails.purpose}
                      </Text>
                    </Box>
                  </Flex>
                </Box>

                {bookingDetails.booking_status === "Booking Created" &&
                  !isStartUsagePassed && (
                    <Flex
                      columnGap="6px"
                      justifyContent="center"
                      alignItems="center"
                      paddingBottom="12px"
                    >
                      <Button
                        width="121px"
                        color="#184973"
                        fontSize="12px"
                        fontWeight="400"
                        background="white"
                        borderRadius="100px"
                        border="1px solid #184973"
                        onClick={() => setIsOpenCancelModal(true)}
                      >
                        Cancel Booking
                      </Button>
                      <Button
                        width="121px"
                        color="white"
                        fontSize="12px"
                        fontWeight="400"
                        borderRadius="100px"
                        background="#184973"
                        border="1px solid #184973"
                        onClick={() => setIsOpenFillFormModal(true)}
                      >
                        Gunakan Mobil
                      </Button>
                    </Flex>
                  )}
                {bookingDetails.booking_status === "In Progress" &&
                  !isStartUsagePassed && (
                    <Flex
                      borderRadius="0px 0px 12px 12px"
                      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                      bg="#73777F"
                      color="white"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        textAlign="center"
                        p="8px"
                        width="100%"
                      >
                        Mobil sedang digunakan
                      </Text>
                    </Flex>
                  )}
              </Flex>
            </Flex>
          )}
        </Flex>
        <CancelModal
          isOpen={isOpenCancelModal}
          onClose={() => setIsOpenCancelModal(false)}
        />
        <FillFormModal
          isOpen={isOpenFillFormModal}
          onClose={() => setIsOpenFillFormModal(false)}
        />
      </Flex>
    </>
  );
};

export default BookingCarDetails;
