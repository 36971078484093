import { Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { handleSubmitDeparture } from "../../../api/submitDeparture";
import DepartureFormCompleted from "../Component/DepartureFormCompleted";
import QuestionCard from "./Card/ListQuestionCard";
import questionList from "./QuestionLists.json";

const getDefaultValues = () => {
  const defaultValue = { damage_image: "", damage_desc: "" };
  questionList.forEach((d) => {
    defaultValue[d.name] = "";
  });
  return defaultValue;
};

const isInvalidForm = ({ obj }) => {
  let countNullInput = 0;
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "" && key !== "damage_image" && key !== "damage_desc") {
      countNullInput += 1;
    }
  });
  if (obj["kerusakan"] === 1) {
    if (!obj["damage_desc"]) {
      countNullInput += 1;
    }
  }
  return !!countNullInput;
};

const FormCarDeparture = ({ id }) => {
  const [form, setForm] = useState(() => getDefaultValues());
  const toast = useToast();
  const [isOpenModal, setOpenModal] = useState(false);
  const handleChangeForm = (value, name) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "kerusakan" &&
        value === 0 && { damage_desc: "", damage_image: "" }),
    }));
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = async () => {
    setIsLoading(true);

    try {
      const data = await handleSubmitDeparture({ id, payload: form });
      if (data?.error) {
        toast({
          status: "error",
          description: "Failed Submit",
          containerStyle: {
            color: "white",
          },
        });
      } else {
        toast({
          status: "success",
          description: "Success Submit",
          containerStyle: {
            color: "white",
          },
        });

        setForm(getDefaultValues());

        setOpenModal(true);

        setTimeout(() => {
          setOpenModal(false);

          window.location.href = "/history";
        }, 7000);
      }
      setIsLoading(false);
    } catch {
      toast({ status: "error", description: "Failed Submit" });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      width="100%"
      direction="column"
      rowGap="12px"
      margin="24px 0"
      padding="0 24px"
    >
      <Text fontSize="14px" fontWeight="500">
        Lengkapi form berikut ini sebelum menggunakan mobil
      </Text>
      <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
        Isi form sesuai dengan keadaan mobil sesungguhnya sebagai bentuk
        kepedulian kita terhadap keselamatan
      </Text>

      <Flex width="100%" direction="column" rowGap="16px">
        {questionList.map((question, index) => (
          <QuestionCard
            key={question.name}
            index={index}
            form={form}
            handleChangeForm={handleChangeForm}
            {...question}
          />
        ))}
      </Flex>

      <Flex margin="10 16px">
        <Button
          bg="#184973"
          isDisabled={isInvalidForm({ obj: form }) || isLoading}
          color="white"
          onClick={handleSubmitForm}
          width="100%"
          height="40px"
          borderRadius="100px"
          fontSize="14px"
          fontWeight="500"
        >
          Gunakan Mobil {isLoading && <Spinner size="sm" ml={2} />}
        </Button>
      </Flex>

      <DepartureFormCompleted
        isOpen={isOpenModal}
        onClose={() => {
          setOpenModal(false);
          window.location.href = "/history";
        }}
      />
    </Flex>
  );
};

export default FormCarDeparture;
