import React from "react";
import { Text, VStack } from "@chakra-ui/react";
import MenuItem from "./MenuItem";
import TermConditionIcon from "../Icons/TermConditionICon";
import ContactIcon from "../Icons/ContactIcon";

function GeneralMenu() {
  const menus = [
    {
      icon: <ContactIcon />,
      title: "Contact Us",
      desc: "Need help?",
      href: "/contact-us",
    },
    {
      icon: <TermConditionIcon />,
      title: "Terms & Conditions",
      desc: "Paraspace rule",
      href: "/terms-and-conditions",
    }
  ];
  return (
    <VStack alignItems='self-start' mt="12px">
      <Text fontSize="14px" fontWeight={500}>
        General
      </Text>
      <VStack alignItems="self-start" w="100%">
        {menus.map(({ icon, title, desc, href }) => (
          <MenuItem icon={icon} title={title} desc={desc} href={href} />
        ))}
      </VStack>
    </VStack>
  );
}

export default GeneralMenu;
