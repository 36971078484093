import React from "react";

function ContactIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2999 25.6666C15.5368 25.6604 10.9705 23.7655 7.60239 20.3975C4.23432 17.0294 2.33943 12.4631 2.33325 7.69993C2.33325 6.2766 2.89867 4.91157 3.90511 3.90512C4.91156 2.89868 6.27659 2.33326 7.69992 2.33326C8.00132 2.33097 8.30221 2.35832 8.59825 2.41493C8.88445 2.45728 9.1658 2.52762 9.43825 2.62493C9.62988 2.69216 9.80063 2.80825 9.93363 2.96171C10.0666 3.11518 10.1573 3.3007 10.1966 3.49993L11.7949 10.4999C11.838 10.6899 11.8328 10.8877 11.7798 11.0752C11.7268 11.2627 11.6277 11.4339 11.4916 11.5733C11.3399 11.7366 11.3283 11.7483 9.89325 12.4949C11.0424 15.0159 13.0587 17.0404 15.5749 18.1999C16.3333 16.7533 16.3449 16.7416 16.5083 16.5899C16.6476 16.4538 16.8188 16.3547 17.0063 16.3017C17.1938 16.2487 17.3916 16.2435 17.5816 16.2866L24.5816 17.8849C24.7744 17.9297 24.9527 18.0228 25.0997 18.1554C25.2466 18.2881 25.3574 18.456 25.4216 18.6433C25.5201 18.9202 25.5942 19.2051 25.6433 19.4949C25.6902 19.7881 25.7136 20.0846 25.7133 20.3816C25.6917 21.7988 25.1104 23.15 24.0962 24.1402C23.082 25.1304 21.7173 25.6791 20.2999 25.6666V25.6666Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default ContactIcon;
