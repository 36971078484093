import { Flex, Text } from "@chakra-ui/react";


const NoCarsAvailablePlaceholder = (
  <Flex
      borderRadius="12px"
      p="12px"
      bg="#EFEFEF"
      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
      justifyContent="center"
      alignItems="center"
    >
      <Text
        textAlign="center"
        fontWeight="400"
        color="#5E5E5E"
      >
        Tidak ada mobil tersedia
      </Text>
  </Flex>
)

export default NoCarsAvailablePlaceholder