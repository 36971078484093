import { useHistory, useLocation } from "react-router-dom";

import { Text, Flex, Box, Skeleton } from "@chakra-ui/react";
import {
  MdCircle,
  MdMeetingRoom,
  MdDirectionsCar,
  MdHouse,
} from "react-icons/md";

import { formatISOtoDDMMYYYYAndHHmm } from "../../../utils/formatDate";

const mapBookingDetails = (booking_type, id) => {
  let icon, title, detailLink;
  switch (booking_type) {
    case "car":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Booking Car";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    case "room":
      icon = <MdMeetingRoom size="24px" color="#222222" />;
      title = "Booking Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
    case "mess":
      icon = <MdHouse size="24px" color="#222222" />;
      title = "Booking Mess";
      detailLink = `/booking-mess/booking-detail/${id}`;
      break;
    default:
      icon = <MdCircle size="24px" color="#222222" />;
      title = "Booking Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
  }
  return { icon, title, detailLink };
};

export function Card({ type, data, isSingle = false }) {
  const navigate = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  let status;
  if (type === "history") {
    status =
      data.booking_status === "Booking Cancelled" ? "Dibatalkan" : "Selesai";
  }

  const { icon, title, detailLink } = mapBookingDetails(
    data.booking_type,
      (data.booking_type === "room")?data.booking_id:data.id
  );

  return (
    <Flex
      direction="column"
      padding="8px"
      spacing="0"
      bg="#FFFFFF"
      style={{
        boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
        borderRadius: "12px",
      }}
      rowGap="12px"
      width={isSingle ? "56%" : ""}
      maxWidth={isSingle ? "260px" : ""}
      cursor={"pointer"}
      onClick={() =>
        navigate.push({
          pathname: detailLink,
          state: { backLink: currentPath },
        })
      }
    >
      <Flex
        direction="row"
        justifyContent="space-between"
        borderBottom="1px solid #EFEFEF"
        paddingBottom="8px"
        alignItems="center"
      >
        <Flex direction="row" alignItems="center" columnGap="4px">
          {icon}
          <Text fontSize="12px" fontWeight="400">
            {title}
          </Text>
        </Flex>
        {status && (
          <Box
            bg={status === "Selesai" ? "#38A169" : "#E53E3E"}
            borderRadius="4px"
            padding="2px 5px"
          >
            <Text fontSize="9px" fontWeight="400" color="#FFFFFF">
              {status}
            </Text>
          </Box>
        )}
      </Flex>
      <Flex direction="row" justifyContent="space-between">
        <Flex w="50%" alignItems="flex-start" direction="column">
          <Text fontSize="11px" fontWeight="500">
            Tanggal
          </Text>
          <Text fontSize="11px" fontWeight="400" color="#5E5E5E">
            {formatISOtoDDMMYYYYAndHHmm(data.start_usage).date}
          </Text>
        </Flex>
        <Flex w="50%" alignItems="flex-start" direction="column">
          <Text fontSize="11px" fontWeight="500">
            Jam
          </Text>
          <Text fontSize="11px" fontWeight="400" color="#5E5E5E">
            {formatISOtoDDMMYYYYAndHHmm(data.start_usage).time} -{" "}
            {formatISOtoDDMMYYYYAndHHmm(data.finish_usage).time}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export function CardSkeleton({ isFirstChild, isLastChild }) {
  return (
    <Flex
      w="200px"
      direction="column"
      marginLeft={isFirstChild ? "16px" : "0"}
      marginRight={isLastChild ? "16px" : "0"}
      padding="8px 0"
      spacing="0"
      bg="#FFFFFF"
      style={{
        boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
        borderRadius: "12px",
      }}
      rowGap="12px"
    >
      <Flex
        direction="row"
        w="200px"
        justifyContent="center"
        borderBottom="1px solid #EFEFEF"
        paddingBottom="8px"
        alignItems="center"
      >
        <Skeleton height="30px" width="180px" />
      </Flex>
      <Flex direction="row" w="200px" justifyContent="center">
        <Skeleton height="40px" width="180px" />
      </Flex>
    </Flex>
  );
}
