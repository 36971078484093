import { Button, Flex, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { MdUploadFile } from "react-icons/md";

const QuestionCard = ({
  title,
  subtitle,
  subtitle2,
  additional,
  option,
  index,
  name,
  form,
  handleChangeForm,
}) => {
  const refInput = useRef(null);
  const [resulPhoto, setResultPhoto] = useState(() => form["damage_image"]);
  useEffect(() => {
    if (form["damage_image"]) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setResultPhoto(event.target.result);
      };

      reader.readAsDataURL(form["damage_image"]);
    } else {
      setResultPhoto("");
    }
  }, [form]);
  return (
    <Flex
      borderRadius="12px"
      p="12px"
      bg="white"
      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
      flexDirection="column"
    >
      <Flex gap="2px" flexDir="column">
        <Flex ml="12px">
          <Text fontSize="12px" fontWeight="400">
            {index + 1}.
          </Text>
          <Text fontSize="12px" fontWeight="400" ml="10px">
            {title}
          </Text>
        </Flex>
        <Flex ml="32px" flexDir="column" gap="2px">
          <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
            {subtitle}
          </Text>
          <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
            {subtitle2}
          </Text>
          <Text fontSize="12px" fontWeight="400" color="#5E5E5E" fontStyle="italic">
            {additional}
          </Text>

          <RadioGroup
            name={name}
            onChange={(e) => {
              handleChangeForm(Number(e), name);
            }}
            defaultChecked={form[name]}
            value={form[name]}
          >
            <Stack direction="row">
              {option.map((opt) => (
                <Radio value={opt.value} key={opt.value}>
                  <Text fontSize="12px">{opt.name}</Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Flex>
      </Flex>

      {name === "kerusakan" && form[name] === 1 && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt="10px"
          pl="32px"
          pr="12px"
          width="100%"
        >
          <Text w="full" textAlign="start" fontSize="12px">
            Jelaskan kerusakan yang terjadi <span style={{ color: "red" }}>*</span>
          </Text>
          <Textarea
            fontSize="12px"
            p="8px"
            value={form["damage_desc"]}
            onChange={(e) => {
              handleChangeForm(e.target.value, "damage_desc");
            }}
          />
          <Text mt="8px" w="full" textAlign="start" fontSize="12px">
            Masukkan foto bukti (opsional)
          </Text>
          <input
            ref={refInput}
            readOnly
            style={{ display: "none" }}
            onChange={(e) => {
              const files = e.target?.files;
              handleChangeForm(files[0], "damage_image");
            }}
            type="file"
            accept="image/*"
          />
          {resulPhoto ? (
            <Flex width="100%" flexDirection="column" alignItems="center" gap="15px" mt="15px">
              <img
                src={resulPhoto}
                width={200}
                height={200}
                alt=" description gambaran kerusakan"
              />
              <Button
                colorScheme="red"
                variant="outline"
                width="fit-content"
                onClick={() => {
                  setResultPhoto("");
                  handleChangeForm("", "damage_image");
                }}
                fontSize="14px"
                p="0 16px"
                fontWeight="400"
              >
                Hapus Foto
              </Button>
            </Flex>
          ) : (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              w="full"
              p="30px 10px"
              borderWidth="1px"
              borderRadius="lg"
              cursor="pointer"
              borderStyle="dashed"
              borderColor="#73777F"
              gap="5px"
              onClick={() => refInput.current.click()}
            >
              <MdUploadFile size={"20px"} color="#5E5E5E" />
              <Text align="center" fontSize="10px">
                Upload satu gambar kerusakan yang terjadi
              </Text>
              <Text fontSize="10px" color="#5E5E5E">
                Maksimal 5MB
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default QuestionCard;
