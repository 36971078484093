import Axios from "../utils/axiosService";

export async function FetchDriverRequest() {
  const id_user = localStorage.getItem("id_user");

  try {
    const res = await Axios.get(`/driver/request/${id_user}`);

    return {
      driverRequest: res.data.data.data,
      loading: false,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching driver request data:", err);
    return {
      driverRequest: {},
      loading: false,
      error: err,
    };
  }
}
