import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export const useGetQueryParams = () => {
  const { search } = useLocation();

  const queryData = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const params = {};

    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return {
      params,
      size: searchParams.size,
      search,
    };
  }, [search]);

  return queryData;
};
