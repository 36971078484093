import React from "react";
import { Flex } from "@chakra-ui/react";
import Header from "../../../components/Header";
import ProfileSummary from "../../../components/Profile/ProfileSummary";
import AccountMenu from "../../../components/Profile/AccountMenu";
import GeneralMenu from "../../../components/Profile/GeneralMenu";
import BottomNavbar from "../../../components/Navbars/BottomNavbar";

function ProfileView() {
  return (
    <>
      <Header title="Profile" />
      <Flex flexDirection="column" gap="10px" padding="40px">
        <ProfileSummary />
        <AccountMenu />
        <GeneralMenu />
      </Flex>
      <BottomNavbar />
    </>
  );
}

export default ProfileView;
