import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";

import { MdLocationOn } from "react-icons/md";

const CarLocation = ({ current_location, location_url }) => {
  return (
    <Flex gap="4px" direction="column">
      <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
        Lokasi Pengambilan
      </Text>
      <Flex gap="4px">
        <MdLocationOn size={16} color="#B3261E" />
        <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
          {" "}
          {current_location}
        </Text>
        <Button
          color="#3182CE"
          fontSize="12px"
          fontWeight="400"
          background="transparent"
          variant="link"
          width="fit-content"
          onClick={() => window.open(location_url, "_blank")}
        >
          Lihat lokasi
        </Button>
      </Flex>
    </Flex>
  );
};

export default CarLocation;
