import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Flex, Button, Text } from "@chakra-ui/react";
import {
  MdHomeFilled,
  MdOutlineBookmarkAdded,
  MdOutlineHistory,
  MdPersonOutline,
} from "react-icons/md";

function BottomNavbar() {
  const navigation = [
    {
      name: "Beranda",
      icon: <MdHomeFilled size="24px" />,
      link: "/",
    },
    {
      name: "Mendatang",
      icon: <MdOutlineBookmarkAdded size="24px" />,
      link: "/upcoming",
    },
    {
      name: "Riwayat",
      icon: <MdOutlineHistory size="24px" />,
      link: "/history",
    },
    {
      name: "Profil",
      icon: <MdPersonOutline size="24px" />,
      link: "/profile",
    },
  ];

  const navigate = useHistory();
  const location = useLocation();
  const [currentNav, setCurrentNav] = useState();
  const isCurrent = (nav) => {
    return nav.link === "/" + currentNav;
  };

  useEffect(() => {
    if (location.pathname) {
      const locArray = location.pathname.split("/");
      const currLoc = locArray[1];
      setCurrentNav(currLoc);
    }
  }, [location]);

  return (
    <Flex
      position="fixed"
      flexDirection="row"
      bottom="0"
      width="100%"
      maxWidth="500px"
      padding="0 16px"
      gap="4px"
      justifyContent="space-between"
      alignItems="center"
      zIndex="10"
      background="#FFFFFF"
      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
    >
      {navigation.map((nav, index) => (
        <Button
          key={index}
          h="100%"
          w="100%"
          padding="8px 4px"
          bg="#FFFFFF"
          onClick={() => navigate.push(nav.link)}
        >
          <Flex
            direction="column"
            width="100%"
            maxWidth="80px"
            gap="6px"
            alignItems="center"
          >
            <Flex
              color={isCurrent(nav) ? "#FFFFFF" : "#5E5E5E"}
              bg={isCurrent(nav) ? "#184973" : "transparent"}
              padding="4px"
              borderRadius="100px"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              {nav.icon}
            </Flex>
            <Text
              fontSize="12px"
              fontWeight="400"
              color={isCurrent(nav) ? "#184973" : "#5E5E5E"}
            >
              {nav.name}
            </Text>
          </Flex>
        </Button>
      ))}
    </Flex>
  );
}

export default BottomNavbar;
