import Axios from "../utils/axiosService";

export async function FetchCarBookingDetails({ id }) {
  try {
    const res = await Axios.get(`/car/booking/${id}`);

    return {
      bookingDetails: res.data.data.data,
      loading: false,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching car detail:", err);
    return {
      bookingDetails: {},
      loading: false,
      error: err,
    };
  }
}
