import Axios from "./axiosService"

export const SignIn = async (email, password) => {
    return Axios({
        method: 'POST',
        url: "/auth/login",
        data: {
            email, password
        }
    })
}