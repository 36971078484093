import React, { useEffect, useState } from "react";
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import Axios from "../../utils/axiosService";
import moment from "moment/moment";
import "moment/locale/id";

const hexToRgb = hex =>
  hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
             ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))


const textStyleNormal = {
  fontSize: "13px",
  fontWeight: "500"
}

const textStyleBold = {
  fontSize: "13px",
  fontWeight: "700"
}

function NotificationItem({
  cardColor,
  locationData,
  bookingStatus,
  bookingLogTimestamp
}) {
  moment.locale("en");
  const [rgb, setRgb] = useState("#B6B6B640")
  const [roomData, setRoomData] = useState([]);
  const [occurrenceData, setOccurrenceData] = useState([]);

  useEffect(() => {
    if (bookingStatus === 'Booking Cancelled') {
      setRgb(hexToRgb('#B6B6B640'))
    } else {
      setRgb(hexToRgb(cardColor))
    }
  }, [bookingStatus, cardColor])

  useEffect(() => {
    Axios({
      method: "GET",
      url: `/room/${locationData.booking_room_booking_list.room_id}`,
    }).then((res) => {
      setRoomData(res.data.data.data);
    });
  }, [locationData])

  useEffect(() => {
    Axios({
      method: "GET",
      url: `/room/occurance/${locationData.occurance_id}`,
    }).then((res) => {
      setOccurrenceData(res.data.data.data);
    });
  }, [locationData])

  return (
    <Flex gap="16px" w='100%'>
      <Avatar
        color="#FFF"
        fontSize="20px"
        bg={`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`}
        w="50px"
        h="50px"
        icon={<i className="fa-solid fa-home"></i>}
      >
      </Avatar>
      <Box w='100%'>
        <Box
          bg={`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.25)`}
          borderRadius="5px"
          p="7px"
          boxShadow="2px 2px 9px 1px rgba(0, 0, 0, 0.25)"
          w='100%'
        >
          {bookingStatus === 'Booking Created' ?
            <>
              <Text 
                style={textStyleNormal}
              >
                  {bookingStatus === 'Booking Created' ? "Booking Successfully Submitted" : "This is Booking Status"}
              </Text>
              <Text>
                <span style={textStyleNormal}>Room</span>{' '}<span style={textStyleBold}>{roomData.name}</span>{' '}<span style={textStyleNormal}>- {roomData.location_detail}</span>
              </Text>
            </>
          :
            <>
            <Text>
              <span style={textStyleNormal}>Booking Room Cancelled by</span>{' '}<span style={textStyleBold}>{occurrenceData.cancelled_by}</span>
            </Text>
            <Text>
              <span style={textStyleNormal}>Room</span>{' '}<span style={textStyleBold}>{roomData.name}</span>{' '}<span style={textStyleNormal}>- {roomData.location_detail}</span>
            </Text>
            </>
          }
          
        </Box>
        {bookingLogTimestamp ?
          <Text mt='10px' fontSize='12px' fontWeight='500'>
            {moment(bookingLogTimestamp).format("dddd, DD MMMM YYYY HH:mm")}
          </Text>
        :
          <Text mt='6px' fontSize='10px' fontWeight='500'>
            No Date
          </Text>
        }
      </Box>
    </Flex>
  );
}

export default NotificationItem;
