import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import { useIsDesktop } from "../../../hooks/useIsDesktop";
import FormDate from "./FormDate";

function ChangeDate() {
  const btnRef = React.useRef();

  const isDesktop = useIsDesktop();

  const {
    isOpen: isOpenChangeDate,
    onOpen: onOpenChangeDate,
    onClose: onCloseChangeDate,
  } = useDisclosure();

  return (
    <>
      <Button ref={btnRef} colorScheme="blue" variant="ghost" onClick={onOpenChangeDate}>
        Ubah
      </Button>
      {isDesktop ? (
        <Modal isOpen={isOpenChangeDate} onClose={onCloseChangeDate} isCentered>
          <ModalOverlay />
          <ModalContent color="black" zIndex={99}>
            <ModalHeader>Ubah Jadwal</ModalHeader>
            <ModalCloseButton />
            <ModalBody mb="11px">
              <FormDate onClose={onCloseChangeDate} />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          isOpen={isOpenChangeDate}
          placement="bottom"
          onClose={onCloseChangeDate}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent bg="white" borderTopRadius="28px" padding="32px 16px 24px 16px">
            <DrawerCloseButton />
            <DrawerHeader
              py="12px"
              fontSize="14px"
              fontWeight="500"
              lineHeight="20px"
              px="0px"
              borderBottom="1px"
              borderColor="#5E5E5E"
            >
              Ubah Jadwal
            </DrawerHeader>

            <DrawerBody padding="0px" mt="16px">
              <FormDate onClose={onCloseChangeDate} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default ChangeDate;
