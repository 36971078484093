import React from "react";
import "bulma/css/bulma.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import MainHeader from "../../../../../components/Parastay/Header/MainHeader";
import bulmaCollapsible from "@creativebulma/bulma-collapsible";
import { Flex, Box, Text, Heading } from "@chakra-ui/react";
import styles from "./styles.module.css";
import AxiosParastay from "../../../../../utils/parastayAxiosService";

class TermsConditionsParastay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      region_user: localStorage.getItem("region_user"),
      terms_conditions_booking: [
        {
          id: null,
          type: "",
          content: "",
        },
      ],
      terms_conditions_moveout: [
        {
          id: null,
          type: "",
          content: "",
        },
      ],
      terms_conditions_complaint: [
        {
          id: null,
          type: "",
          content: "",
        },
      ],
    };
  }

  componentDidMount() {
    this.collapsibles = bulmaCollapsible.attach(".is-collapsible", {
      container: this.refs.collapsibles,
    });

    AxiosParastay({
      method: "GET",
      url: `/TermsandConditions?type=booking&work_location_id_fk=${this.state.region_user}`,
    })
      .then((response) => {
        this.setState({
          ...this.state,
          terms_conditions_booking: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    AxiosParastay({
      method: "GET",
      url: `/TermsandConditions?type=moving&work_location_id_fk=${this.state.region_user}`,
    })
      .then((response) => {
        this.setState({
          ...this.state,
          terms_conditions_moveout: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    AxiosParastay({
      method: "GET",
      url: `/TermsandConditions?type=complaint&work_location_id_fk=${this.state.region_user}`,
    })
      .then((response) => {
        this.setState({
          ...this.state,
          terms_conditions_complaint: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <>
        <Flex className={styles["header-container"]}>
          <MainHeader title="Syarat & Ketentuan" backLink={`/parastay?id=1`} />
        </Flex>
        <Flex className={styles["main-container"]}>
          <div class="hero-body">
            <div class="container">
              <Flex>
                <Box mb="1em">
                  <Heading size="md">Syarat & Ketentuan</Heading>
                  <Text>Mess Paragon</Text>
                </Box>
              </Flex>
              <div ref="collapsibles" id="accordion_first">
                <article class="message">
                  <div
                    class="message-header has-background-gomess-babyblue"
                    href="#sk-reservasi-mess"
                    data-action="collapse"
                  >
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faFileAlt} size="lg" />
                    </div>
                    <span>
                      <Text fontSize="18px" fontWeight={600} color="#000">
                        Reservasi Mess
                      </Text>
                    </span>
                    <a
                      href={() => false}
                      class="icon is-small is-left has-text-primary"
                    >
                      <FontAwesomeIcon icon={faAngleRight} size="lg" />
                    </a>
                  </div>
                  <div
                    id="sk-reservasi-mess"
                    class="message-body is-collapsible has-background-white"
                    data-parent="accordion_first"
                  >
                    <pre
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Lato, sans-serif",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {this.state.terms_conditions_booking[0]?.content}
                    </pre>
                  </div>
                </article>
                <article class="message">
                  <div
                    class="message-header has-background-gomess-babyblue"
                    href="#sk-pindah-mess"
                    data-action="collapse"
                  >
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faFileAlt} size="lg" />
                    </div>
                    <span>
                      <Text fontSize="18px" fontWeight={600} color="#000">
                        Pindah Mess
                      </Text>
                    </span>
                    <a
                      href={() => false}
                      class="icon is-small is-left has-text-primary"
                    >
                      <FontAwesomeIcon icon={faAngleRight} size="lg" />
                    </a>
                  </div>
                  <div
                    id="sk-pindah-mess"
                    class="message-body is-collapsible has-background-white"
                    data-parent="accordion_first"
                  >
                    <pre
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Lato, sans-serif",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {this.state.terms_conditions_moveout[0]?.content}
                    </pre>
                  </div>
                </article>
                <article class="message">
                  <div
                    class="message-header has-background-gomess-babyblue"
                    href="#tatacara-komplain"
                    data-action="collapse"
                  >
                    <div class="icon is-small is-left has-text-primary">
                      <FontAwesomeIcon icon={faFileAlt} size="lg" />
                    </div>
                    <span>
                      <Text fontSize="18px" fontWeight={600} color="#000">
                        Tata cara Komplain
                      </Text>
                    </span>
                    <a
                      href={() => false}
                      class="icon is-small is-left has-text-primary"
                    >
                      <FontAwesomeIcon icon={faAngleRight} size="lg" />
                    </a>
                  </div>
                  <div
                    id="tatacara-komplain"
                    class="message-body is-collapsible has-background-white"
                    data-parent="accordion_first"
                  >
                    <pre
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Lato, sans-serif",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {this.state.terms_conditions_complaint[0]?.content}
                    </pre>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </Flex>
      </>
    );
  }
}

export default TermsConditionsParastay;
