import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FetchCarDetail } from "../../../../api/fetchCarDetail";

import { Flex, Button, Text, CircularProgress } from "@chakra-ui/react";

import DetailCarSection from "./Section/DetailCar";
import UsersSection from "./Section/BookedCarUsers";
import RulesSection from "./Section/Rules";

const SummaryByCar = () => {
  const { id } = useParams();
  const navigate = useHistory();

  const [car, setCar] = useState({});
  const [isLoadingCar, setIsLoadingCar] = useState(true);

  useEffect(() => {
    const fetchCarDetail = async () => {
      const { carDetail, loading } = await FetchCarDetail({ id: id });
      setCar(carDetail);
      setIsLoadingCar(loading);
    };

    localStorage.setItem("car_id", id);
    fetchCarDetail();
  }, [id]);

  return (
    <Flex
      bg="#F8FBFF"
      padding="24px 0px"
      flexDirection="column"
      rowGap="24px"
      minHeight="calc(100vh - 72px)"
    >
      <Flex rowGap="12px" direction="column" padding="0 16px">
        <Text fontSize="14px" fontWeight="500">
          Spesifikasi Mobil
        </Text>
        {isLoadingCar ? (
          <Flex
            width="100%"
            height="300px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress isIndeterminate color="#3182CE" />
          </Flex>
        ) : (
          <Flex width="100%" direction="column" rowGap="16px">
            <DetailCarSection {...car} />
          </Flex>
        )}
      </Flex>

      <UsersSection id={id} />

      {car.car_status === "available" && (
        <>
          <RulesSection />

          <Flex margin="0 16px">
            <Button
              bg="#184973"
              color="white"
              width="100%"
              height="40px"
              borderRadius="100px"
              fontSize="14px"
              fontWeight="500"
              onClick={() =>
                navigate.push(`/booking-car/booking-form?id=${id}`)
              }
            >
              Isi Formulir Booking
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default SummaryByCar;
