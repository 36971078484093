import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Box, Text, IconButton } from "@chakra-ui/react";
import { MdOutlineChevronLeft, MdDirectionsCar } from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";

import BookingCarHeader from "../../../assets/img/bookingCar/booking-car-header.png";

const Header = ({ backLink, activeTab, setActiveTab }) => {
  const navigate = useHistory();
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 110) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box width="100%" position="relative" marginBottom="24px">
      {/* main header */}
      <Flex
        height="136px"
        backgroundImage={`url(${BookingCarHeader})`}
        bgPosition="center bottom"
        bgSize="cover"
        direction="column"
        padding="24px 24px 40px 24px"
        gap="16px"
      >
        <Flex
          borderRadius="100%"
          bg="white"
          height="28px"
          width="28px"
          alignItems="center"
          justifyContent="center"
        >
          {backLink && (
            <IconButton
              icon={<MdOutlineChevronLeft size={20} color="#222222" />}
              onClick={() => navigate.push(backLink)}
              colorScheme="white"
            />
          )}
        </Flex>
        <Text color="white" fontSize="24px" fontWeight="700">
          Booking Car
        </Text>
      </Flex>

      {/* tab header */}
      <Flex
        position={isSticky ? "fixed" : "absolute"}
        top={isSticky ? "0" : "unset"}
        bottom={isSticky ? "unset" : "-24px"}
        maxWidth="500px"
        width="100%"
        bg="white"
        height="48px"
        alignItems="center"
        gap="16px"
        padding="0px 16px"
        borderTopRadius={isSticky ? "0" : "16px"}
        justifyContent="flex-start"
        borderBottom="1px solid #EFEFEF"
        zIndex="1000"
        transition="top 0.3s"
      >
        <Flex
          gap="8px"
          alignItems="center"
          justifyContent="center"
          padding="12px 16px"
          borderBottom={activeTab === "car" ? "2px solid #184973" : "none"}
          cursor="pointer"
          onClick={() => setActiveTab("car")}
        >
          <MdDirectionsCar
            size={24}
            color={activeTab === "car" ? "#184973" : "#5E5E5E"}
          />
          <Text
            fontSize="14px"
            fontWeight="500"
            color={activeTab === "car" ? "#184973" : "#5E5E5E"}
          >
            Car
          </Text>
        </Flex>
        <Flex
          gap="8px"
          alignItems="center"
          justifyContent="center"
          padding="12px 16px"
          borderBottom={activeTab === "driver" ? "2px solid #184973" : "none"}
          cursor="pointer"
          onClick={() => setActiveTab("driver")}
        >
          <GiSteeringWheel
            size={24}
            color={activeTab === "driver" ? "#184973" : "#5E5E5E"}
          />
          <Text
            fontSize="14px"
            fontWeight="500"
            color={activeTab === "driver" ? "#184973" : "#5E5E5E"}
          >
            Driver
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
