import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import 'moment-timezone';
import "moment/locale/id";
import { 
  Flex,
  Box,
  Text,
  Button,
  Image,
  Center,
  Skeleton,
  Spacer,
} from "@chakra-ui/react";
import MainHeader from '../../../../components/BookingRoom/Header/MainHeader';
import styles from "./bookingdetail.module.css";
import { Link, useParams } from 'react-router-dom';
import Axios from '../../../../utils/axiosService';
import CancelOneModal from './CancelOne';
import CancelSeriesModal from './CancelSeries';

function BookingDetail() {

  moment.locale("en");

  const params = useParams();
  const [lastDate, setLastDate] = useState(null);
  const [bookingId] = useState(params.bookingId);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [bookingRoomData, setBookingRoomData] = useState([]);
  const [bookingOccurrenceData, setBookingOccurrenceData] = useState([]);
  const [singleOccurrenceData, setSingleOccurrenceData] = useState([]);

  const [showCancel, setShowCancel] = useState(false);
  const [showCancelSeries, setShowCancelSeries] = useState(false);

  const handleCancel = (event, value) => {
    event.preventDefault();
    setSingleOccurrenceData(value);
    setShowCancel(true);
  };

  const handleCancelSeries = (e) => {
    e.preventDefault();
    setShowCancelSeries(true);
  };

  useEffect(() => {
    setIsLoading(true)
    Axios({
      method: "GET",
      url: `/room/booking/${bookingId}`,
    }).then(async(result) => {
      if (result.data.data.status === 'OK') {
        let bookingData = result.data.data.data;

        let checkBookingStatus = bookingData.booking_room_booking_occurances.map((data) => {
          let status = true;
          if ((new Date(data.finish_usage) > new Date()) && (data.status !== "Booking Cancelled")) {
            status = !status
          }
          return status;
        })

        let isFinished = checkBookingStatus.every(Boolean);
        let lastDate = bookingData.booking_room_booking_occurances[bookingData.booking_room_booking_occurances.length - 1].finish_usage;
        
        const bookingOccurrence = bookingData.booking_room_booking_occurances
        let sortedBookingOccurrence = bookingOccurrence.sort( function ( a, b ) { return a.id - b.id; } )

        setBookingRoomData(bookingData);
        setBookingOccurrenceData(sortedBookingOccurrence)
        setLastDate(lastDate)
        setIsFinished(isFinished)
        setIsLoading(false)
      }
    })
  // eslint-disable-next-line
  }, [bookingId]);

  return (
    <>
      <Flex className={styles["header-container"]}>
        <MainHeader title="Booking Detail" backLink="/history" />
      </Flex>
      {showCancel && 
        <CancelOneModal 
          setShowCancel={setShowCancel}
          bookingData={bookingRoomData}
          bookingOccurrenceData={singleOccurrenceData}
          allBookingOccurrenceData={bookingOccurrenceData}
        />
      }
      {showCancelSeries &&
        <CancelSeriesModal 
          setShowCancelSeries={setShowCancelSeries}
          bookingData={bookingRoomData}
          bookingOccurrenceData={bookingOccurrenceData}
        />
      }
      <Flex className={styles["main-container"]}>
        <Box>
          <Flex>
            <Box className={styles["schedule-card"]}>
              <Center className={styles["schedule-sub-section-status"]}>
                {isFinished && bookingRoomData?.status !== "Booking Cancelled"? 
                  <Box>
                    <Text className={styles["text-status-title"]}>
                      Booking Completed
                    </Text>
                    <Text className={styles["text-status-subtitle"]}>
                      Thank you for using Booking Room!
                    </Text>
                  </Box>
                :
                  <Box>
                    <Text className={styles["text-status-title"]}>{bookingRoomData.status}</Text>
                    <Text className={styles["text-status-subtitle"]}>
                    {bookingRoomData?.status === "Booking Created" ?
                      "Booking has been successfully created"
                    : bookingRoomData?.status === "Booking Cancelled" ?
                      "Your booking has been cancelled"
                    : null}
                    </Text>
                  </Box>
                }
              </Center>              
            </Box>
          </Flex>
          <Flex>
            <Text className={styles["section-title"]}>Room Data</Text>
          </Flex>
          <Flex>
            <Box className={styles["schedule-card"]}>
              <Flex className={styles["schedule-sub-section"]}>
                <Box width={'45%'} className={styles["room-detail-image"]}>
                  <Skeleton isLoaded={bookingRoomData?.booking_room_master_datum} height='100%'>
                    <Image 
                    src={
                      bookingRoomData?.booking_room_master_datum?.image
                      ? bookingRoomData?.booking_room_master_datum?.image
                      : "https://img.freepik.com/premium-photo/front-view-blank-white-poster-black-frame-light-wall-modern-meeting-room-with-empty-wooden-table-surrounded-by-black-white-chairs-city-view-from-big-window-3d-rendering-mock-up_688884-70.jpg"
                    }
                    alt="meeting-room"
                    height='50%'/>
                  </Skeleton>
                </Box>
                <Box width={'55%'} className={styles["room-detail-data"]}>
                  <Box className={styles["room-detail-info-card"]}>
                    <Text className={styles["text-room-name"]}>
                      {bookingRoomData?.booking_room_master_datum?.name}
                    </Text>
                  </Box>
                  <Box className={styles["room-detail-info-card"]}>
                    <Text className={styles["text-room-location"]}>
                      {bookingRoomData?.booking_room_master_datum?.office_area.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text className={styles["text-room-variable"]}>
                      Capacity : {bookingRoomData?.booking_room_master_datum?.capacity} person
                    </Text>
                    <Text className={styles["text-room-variable"]}>
                      Type : {bookingRoomData?.booking_room_master_datum?.type}
                    </Text>
                  </Box>
                </Box>
              </Flex>
              <Flex className={styles["schedule-sub-section"]}>
                <Box className={styles["room-detail-info-card"]}>
                  <Text className={styles["text-room-variable"]} mb="0.5em">
                    Description:
                  </Text>
                  <Text className={styles["text-room-data"]}>
                  {bookingRoomData?.booking_room_master_datum?.location_detail}
                  </Text>
                </Box>
              </Flex>             
            </Box>
          </Flex>
          <Flex>
            <Text className={styles["section-title"]}>
              {bookingRoomData?.booking_room_master_datum?.type === "Aula" ?
                "Booking & Aula Request Data"
              :
                "Booking Request Data"
              }
            </Text>
          </Flex>
          <Flex>
            <Box className={styles["schedule-card"]}>
            <Flex>
                <Box width={'35%'}>
                  <Flex className={styles["room-detail-info-card"]}>
                    <Text className={styles["text-variable"]}>Purpose</Text>
                  </Flex>
                </Box>
                <Box width={'65%'}>
                  <Flex className={styles["room-detail-info-card"]}>
                    <Text className={styles["text-value"]}>
                      : {bookingRoomData?.booking_purpose}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex>
                <Box width={'35%'}>
                  <Flex className={styles["room-detail-info-card"]}>
                    <Text className={styles["text-variable"]}>Attendees</Text>
                  </Flex>
                  <Flex className={styles["room-detail-info-card"]}>
                    <Text className={styles["text-variable"]}>Period</Text>
                  </Flex>
                  { bookingRoomData?.is_repeat && (
                    <Flex className={styles["room-detail-info-card"]}>
                      <Text className={styles["text-variable"]}>Period Repeat</Text>
                    </Flex>
                  )}
                </Box>
                <Box width={'65%'}>
                  <Flex className={styles["room-detail-info-card"]}>
                    <Text className={styles["text-value"]}>
                      : {bookingRoomData?.attendees_amount} person
                    </Text>
                  </Flex>
                  <Flex className={styles["room-detail-info-card"]}>
                    <Text className={styles["text-value"]}>: {bookingRoomData?.period_repeat}</Text>
                  </Flex>
                  { bookingRoomData?.is_repeat && (
                    <Flex className={styles["room-detail-info-card"]}>
                      <Text className={styles["text-value"]}>: {bookingRoomData?.count_repeat}x</Text>
                    </Flex>
                  )}
                </Box>
              </Flex>
              {bookingRoomData?.booking_room_master_datum?.type === "Aula" && (
                <>
                  <Flex className={styles["sub-section-container"]}>
                    <Box>
                      <Text className={styles["sub-section-title"]}>Layout Information</Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={'40%'}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>Layout</Text>
                      </Flex>
                      {bookingRoomData?.booking_room_booking_aula?.aula_layout === "uShape" || bookingRoomData?.booking_room_booking_aula?.aula_layout === "classroom"
                        ?
                          (
                            <>
                              <Flex className={styles["room-detail-info-card"]}>
                                <Text className={styles["text-variable"]}>Seat Option</Text>
                              </Flex>
                              {
                                bookingRoomData?.booking_room_booking_aula?.seat_option === "kursiKampus" ?
                                (
                                  <Flex className={styles["room-detail-info-card"]}>
                                    <Text className={styles["text-variable"]}>Kursi Kampus</Text>
                                  </Flex>
                                )
                                : bookingRoomData?.booking_room_booking_aula?.seat_option === "kursiStandard" ?
                                (
                                  <Flex className={styles["room-detail-info-card"]}>
                                    <Text className={styles["text-variable"]}>Standard Chair</Text>
                                  </Flex>
                                )
                                : null
                              }
                            </>
                          )
                        : bookingRoomData?.booking_room_booking_aula?.aula_layout === "roundTable"
                        ?
                          (
                            <>
                              <Flex className={styles["room-detail-info-card"]}>
                                <Text className={styles["text-variable"]}>Table(s)</Text>
                              </Flex>
                              <Flex className={styles["room-detail-info-card"]}>
                                <Text className={styles["text-variable"]}>Chair(s) per Table</Text>
                              </Flex>
                            </>
                          )
                        : bookingRoomData?.booking_room_booking_aula?.aula_layout === "lesehan"
                        ?
                          (
                            <Flex className={styles["room-detail-info-card"]}>
                              <Text className={styles["text-variable"]}>Lesehan Area</Text>
                            </Flex>
                          )
                        : bookingRoomData?.booking_room_booking_aula?.aula_layout === "others"
                        ?
                          (
                            <Flex className={styles["room-detail-info-card"]}>
                              <Text className={styles["text-variable"]}>Layout Detail</Text>
                            </Flex>
                          )
                        : null
                      }
                    </Box>
                    <Box width={'60%'}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          : {
                              bookingRoomData?.booking_room_booking_aula?.aula_layout === "lesehan" ? "Lesehan"
                              : bookingRoomData?.booking_room_booking_aula?.aula_layout === "uShape" ? "U Shape"
                              : bookingRoomData?.booking_room_booking_aula?.aula_layout === "classroom" ? "Classroom"
                              : bookingRoomData?.booking_room_booking_aula?.aula_layout === "roundTable" ? "Round Table"
                              : bookingRoomData?.booking_room_booking_aula?.aula_layout === "others" ? "Others"
                              : null
                            }
                        </Text>
                      </Flex>
                      {bookingRoomData?.booking_room_booking_aula?.aula_layout === "uShape" || bookingRoomData?.booking_room_booking_aula?.aula_layout === "classroom"
                        ?
                          (
                            <>
                              <Flex className={styles["room-detail-info-card"]}>
                                <Text className={styles["text-value"]}>
                                  : {
                                      bookingRoomData?.booking_room_booking_aula?.seat_option === "kursiKampus" ? "Kursi Kampus"
                                      : bookingRoomData?.booking_room_booking_aula?.seat_option === "kursiStandard" ? "Kursi Standard"
                                      : null
                                    }
                                </Text>
                              </Flex>
                              {
                                bookingRoomData?.booking_room_booking_aula?.seat_option === "kursiKampus" ?
                                (
                                  <Flex className={styles["room-detail-info-card"]}>
                                    <Text className={styles["text-value"]}>
                                      : {bookingRoomData?.booking_room_booking_aula?.kursi_kampus_amount} pcs
                                    </Text>
                                  </Flex>
                                )
                                : bookingRoomData?.booking_room_booking_aula?.seat_option === "kursiStandard" ?
                                (
                                  <Flex className={styles["room-detail-info-card"]}>
                                    <Text className={styles["text-value"]}>
                                      : {bookingRoomData?.booking_room_booking_aula?.kursi_standard_amount} pcs
                                    </Text>
                                  </Flex>
                                )
                                : null
                              }
                            </>
                          )
                        : bookingRoomData?.booking_room_booking_aula?.aula_layout === "roundTable"
                        ?
                          (
                            <>
                              <Flex className={styles["room-detail-info-card"]}>
                                <Text className={styles["text-value"]}>
                                  : {bookingRoomData?.booking_room_booking_aula?.table_amount} pcs
                                </Text>
                              </Flex>
                              <Flex className={styles["room-detail-info-card"]}>
                                <Text className={styles["text-value"]}>
                                  : {bookingRoomData?.booking_room_booking_aula?.chair_per_table_amount} pcs
                                </Text>
                              </Flex>
                            </>
                          )
                        : bookingRoomData?.booking_room_booking_aula?.aula_layout === "lesehan"
                        ?
                          (
                            <Flex className={styles["room-detail-info-card"]}>
                              <Text className={styles["text-value"]}>
                                : {bookingRoomData?.booking_room_booking_aula?.lesehan_area}
                              </Text>
                            </Flex>
                          )
                        : bookingRoomData?.booking_room_booking_aula?.aula_layout === "others"
                        ?
                          (
                            <Flex className={styles["room-detail-info-card"]}>
                              <Text className={styles["text-value"]}>
                                : {bookingRoomData?.booking_room_booking_aula?.detail_aula_layout}
                              </Text>
                            </Flex>
                          )
                        : null
                      }
                    </Box>
                  </Flex>
                  <Flex className={styles["sub-section-container"]}>
                    <Box>
                      <Text className={styles["sub-section-title"]}>Supporting Facilities</Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={'40%'}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>Flipchart</Text>
                      </Flex>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>Whiteboard</Text>
                      </Flex>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>Square Table</Text>
                      </Flex>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable"]}>Cable Roll</Text>
                      </Flex>
                    </Box>
                    <Box width={'60%'}>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          : {bookingRoomData?.booking_room_booking_aula?.flipchart_amount} pcs
                        </Text>
                      </Flex>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          : {bookingRoomData?.booking_room_booking_aula?.whiteboard_amount} pcs
                        </Text>
                      </Flex>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          : {bookingRoomData?.booking_room_booking_aula?.square_table_amount} pcs
                        </Text>
                      </Flex>
                      <Flex className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value"]}>
                          : {bookingRoomData?.booking_room_booking_aula?.cable_roll_amount} pcs
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </>
              )}
            </Box>
          </Flex>
          <Flex>
            <Text className={styles["section-title"]}>{bookingRoomData?.is_repeat ? "Schedules" : "Schedule"}</Text>
          </Flex>
          {(!isLoading && bookingRoomData) && bookingOccurrenceData.map((occurrence, index) => {
            return (
              <Flex>
                <Box className={styles["schedule-card"]} key={`nomer ${index}`}>
                  <Flex className={styles["schedule-sub-section"]}>
                    <Box width="120px">
                      {
                      ((new Date(occurrence?.finish_usage) > new Date()) || (occurrence?.status === "Booking Cancelled")) ? 
                        <Box
                          className = {
                            occurrence?.status === "Booking Created" 
                              ? styles["schedule-booking-status-created"]
                              : styles["schedule-booking-status-cancelled"]
                          }
                          alignItems="right"
                          textAlign="right"
                        >
                          <Text className={styles["text-value-status"]}>
                            {occurrence?.status === "Booking Created" 
                              ? "Active"
                              : "Cancelled"}
                          </Text>
                        </Box>
                      :
                        <Box className={styles["schedule-booking-status-completed"]} alignItems="right" textAlign="right">
                          <Text className={styles["text-value-status"]}>
                            Completed
                          </Text>
                        </Box>
                      }
                    </Box>
                    <Spacer/>
                    <Box width="120px">
                      <Box className={styles["schedule-numbering"]} alignContent="right" textAlign="right">
                        <Text alignItems="right" className={styles["text-value-number"]}>#{index + 1}</Text>
                      </Box>
                    </Box>
                  </Flex>
                  <Flex className={styles["schedule-sub-section"]}>
                    <Box width={'35%'}>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable-schedule"]}>Date</Text>
                      </Box>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable-schedule"]}>Time</Text>
                      </Box>
                    </Box>
                    <Box width={'65%'}>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value-schedule"]}>
                          : {moment.utc(occurrence?.start_usage).tz('Asia/Jakarta').format('dddd, DD MMMM YYYY')}
                        </Text>
                      </Box>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value-schedule"]}>
                          : {moment.utc(occurrence?.start_usage).tz('Asia/Jakarta').format('HH:mm')} - {moment.utc(occurrence?.finish_usage).tz('Asia/Jakarta').format('HH:mm')}
                        </Text>
                      </Box>
                    </Box>
                  </Flex>
                  {occurrence.status === "Booking Cancelled" && (
                    <Flex className={styles["schedule-sub-section-cancel"]}>
                      <Box width={'35%'}>
                        <Box className={styles["room-detail-info-card"]}>
                          <Text className={styles["text-variable-schedule"]}>Cancelled at</Text>
                        </Box>
                        <Box className={styles["room-detail-info-card"]}>
                          <Text className={styles["text-variable-schedule"]}>Cancelled by</Text>
                        </Box>
                        <Box className={styles["room-detail-info-card"]}>
                          <Text className={styles["text-variable-schedule"]}>Reason</Text>
                        </Box>
                      </Box>
                      <Box width={'65%'}>
                        <Box className={styles["room-detail-info-card"]}>
                          <Text className={styles["text-value-schedule"]}>
                            : {moment.utc(occurrence?.cancel_timestamp).tz('Asia/Jakarta').format('DD MMMM YYYY, HH:mm')}
                          </Text>
                        </Box>
                        <Box className={styles["room-detail-info-card"]}>
                          <Text className={styles["text-value-schedule"]}>
                            : {occurrence?.cancelled_by}
                          </Text>
                        </Box>
                        <Box className={styles["room-detail-info-card"]}>
                          <Text className={styles["text-value-schedule"]}>
                            : {occurrence?.cancel_reason}
                          </Text>
                        </Box>
                      </Box>
                    </Flex>
                  )}
                  {((new Date(occurrence?.finish_usage) > new Date()) && occurrence?.status !== "Booking Cancelled") &&
                    (
                      <Flex mb="0.5em" mt="0.1em">
                        <Spacer/>
                        <Link>
                          <Button
                            bg="#fb6340"
                            size="xs"
                            className={styles["book-room-button"]}
                            onClick={(event) => handleCancel(event, occurrence)}
                          >
                            Cancel Booking
                          </Button>
                        </Link>
                      </Flex>
                    )
                  }
                </Box>
              </Flex>
            )
          })}
          {(new Date(lastDate) > new Date() && bookingRoomData?.is_repeat && bookingRoomData?.status !== "Booking Cancelled") && (
            <Link>
              <Button
                bg="#f5365c"
                className={styles["book-room-button"]}
                marginTop="0.75em"
                onClick={(event) => handleCancelSeries(event)}
              >
                Cancel Series
              </Button>
            </Link>
          )}
        </Box>
      </Flex>
    </>
  )
}

export default BookingDetail;
