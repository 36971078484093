import React from "react";
import { Text, VStack } from "@chakra-ui/react";
import MenuItem from "./MenuItem";
// import CarIcon from "../Icons/CarIcon";
import HistoryIcon from "../Icons/HistoryIcon";
import NotificationSolidIcon from "../Icons/NotificationSolidIcon";
import LogOutIcon from "../Icons/LogoutIcon";
import Axios from "../../utils/axiosService";

function AccountMenu() {
  const menus = [
    {
      icon: <HistoryIcon fill="#4D4D4D" />,
      title: "My Booking",
      desc: "See all your history",
      href: "/history",
    },
    {
      icon: <NotificationSolidIcon />,
      title: "Notification",
      desc: "See notification",
      href: "/notification",
    },
    // {
    //   icon: <CarIcon />,
    //   title: "Driving License",
    //   desc: "SIM A details",
    //   href: null,
    // },
    {
      icon: <LogOutIcon />,
      title: "Logout",
      desc: "Logout your account",
      href: null,
      onClick: ()=> {
        localStorage.removeItem("login");
        localStorage.removeItem("region_user")
        localStorage.removeItem("role_user")
        localStorage.removeItem("username")
        localStorage.removeItem("id_user")
        localStorage.removeItem("id_user_parastay")
        localStorage.removeItem("user_data")
        localStorage.removeItem("paraspaceToken")
        Axios({
          method: "POST",
          url: "/auth/logout"
        })
        .then(r=>window.location.assign("/"))
      }
    },
  ];
  return (
    <VStack alignItems="self-start" mt="12px">
      <Text fontSize="14px" fontWeight={500}>
        Account
      </Text>
      <VStack alignItems="self-start" w="100%">
        {menus.map(({ icon, title, desc, href, onClick }) => (
          <MenuItem icon={icon} title={title} desc={desc} href={href} onClick={onClick} />
        ))}
      </VStack>
    </VStack>
  );
}

export default AccountMenu;
