import React, { useEffect } from "react";
import { Avatar, Center, Flex, Text } from "@chakra-ui/react";
import AxiosParastay from "../../utils/parastayAxiosService";

function ProfileSummary() {
  const [messData, setMessData] = React.useState()
  const userData = JSON.parse(localStorage.getItem("user_data"))
  const userId = userData.user_id

  useEffect(() => {
    AxiosParastay({
      method: "GET",
      url: `/UserBookingList?user_id_fk=${userId}`,
    }).then(result =>  {
      let data = result.data.data
      for (let i = 0; i < data.length; i++) {
        if(data[i].booking_status_id_fk === 4){
          setMessData(data[i])
        }
      };
    });
  }, [userId]);

  return (
    <Flex gap="20px" w="100%">
      <Avatar name={userData?.user_name ?? "-"} size="lg" />
      <Center alignItems="flex-start" flexDirection={'column'}>
        <Text fontWeight={700} fontSize="18px" opacity="0.7" noOfLines={1}>
          {userData?.user_name ?? "-"}
        </Text>
        <Text fontWeight={400} fontSize="12px" opacity="0.7" noOfLines={1}>
          {userData?.user_email ?? "-"}
        </Text>
        <Text fontWeight={400} fontSize="10px" opacity="0.7" noOfLines={2}>
          {userData?.directorate_id_fk ?? "-"}
        </Text>
        <Text
          fontWeight={400}
          fontSize="12px"
          padding="4px 12px"
          marginTop="8px"
          bg="rgba(224, 135, 135, 1)"
          color="#FFF"
          borderRadius="20px"
        >
          {messData?.room_name ?? "Tidak di mess"}
        </Text>
      </Center>
    </Flex>
  );
}

export default ProfileSummary;
