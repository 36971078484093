import React from 'react';

// react library for routing
import { Route, Switch } from "react-router-dom";
import IndexFooter from "../components/Footers/IndexFooter.jsx";
import routes from "../routes.js";

class Auth extends React.Component {
    getRoutes = routes => {
        return routes.map((prop, key) => {
        if (prop.collapse) {
            return this.getRoutes(prop.views);
        }
        if (prop.layout === "/auth") {
            return (
            <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
            />
            );
        } else {
            return null;
        }
        });
    };
    render() {
        return (
            <>
                <div className="main-content" ref="mainContent">
                    <Switch>{this.getRoutes(routes)}</Switch>
                </div>
                <IndexFooter />
            </>
        );
    }
}

export default Auth;
